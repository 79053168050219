import * as React from "react";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import { useState, useEffect } from "react";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { GetCustomerOrderlist } from "../Services/UserApi";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { grey } from "@mui/material/colors";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";

import { CurrentUrl } from "../Services/UrlApi";
import { saveAs } from "file-saver";
import { useLocation } from "react-router";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
      {/* <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<FileDownloadOutlinedIcon />}
      >
        Export
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Download CSV</MenuItem>
        <MenuItem onClick={handleClose}>Download PDF</MenuItem>
      </Menu>
      </div> */}
    </GridToolbarContainer>
  );
}

// function imagedownhandle() {
//   window.html2canvas = html2canvas;
//   var doc = new jsPDF("p", "pt", "a4");

//   var content = document.getElementById("img-content");
//   document.getElementById("img-content").style.display = "block";
//   doc.html(content, {
//     callback: function (doc) {
//       doc.save();
//       document.getElementById("img-content").style.display = "none";
//     },
//   });
// }

export default function MyOrder() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");
  const [orderdata, setOrderdata] = useState([]);

  const columns = [
    // {
    //   field: "",
    //   headerName: "S.No.",
    //   width: 100,
    //   renderCell: (params) => {

    //     return <div>{params.rowIndex + 1}</div>;
    //   },
    //   renderCell: ({rowIndex}) => {

    //     return <div>{rowIndex + 1}</div>;
    //   },
    // },

    // { field: "sno", headerName: "S.No", width: 100},
    { field: "order_id", headerName: "Order No.", width: 100 },
    { field: "orderDate", headerName: "Inserted Date & Time", width: 200 },
    { field: "frompincode", headerName: "From Pin", width: 100 },
    { field: "topincode", headerName: "To Pin", width: 100 },
    { field: "taxableAmount", headerName: "Taxable Amount", width: 150 },
    { field: "gst_Amount", headerName: "GST Amount", width: 150 },
    { field: "total_Amount", headerName: "Total Amount", width: 120 },
    { field: "order_Status", headerName: "Order Status", width: 150 },
    { field: "orderRemarks", headerName: "Order Remarks", width: 300 },
    { field: "pickup_Address", headerName: "Pickup Address", width: 150 },
    { field: "pickup_name", headerName: "Pickup Name", width: 150 },
    { field: "pickup_mobile", headerName: "Pickup Contact No.", width: 150 },
    { field: "cust_Deliveryaddress", headerName: "Delivery Address", width: 150 },
    { field: "delivery_name", headerName: "Delivery Person name", width: 150 },
    { field: "delivery_mobile", headerName: "Delivery Contact", width: 150 },
    // {
    //   field: "download",
    //   headerName: "Download",
    //   flex: 1,
    //   renderCell: (params) => {
    //     return (
    //       <IconButton
    //         onClick={() => {
    //           pdfhandle(params);
    //         }}
    //         aria-label="delete"
    //       >
    //         {" "}
    //         <SimCardDownloadIcon />
    //       </IconButton>
    //     );
    //   },
    // },

    { field: "itemInvoice", headerName: " Customer Invoice No.", width: 200 },
    {
      field: "invoicePath",
      headerName: " Customer Item Invoice",
      width: 150,
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <InvoiceItems invoicePath={params.row.invoicePath} />
          </Box>
        );
      },
    },
  ];

  // function pdfhandle(params) {

  //   setOrderdata(params.row);
  //   window.html2canvas = html2canvas;
  //   var doc = new jsPDF("p", "px", [790, 850]);

  //   var content = document.getElementById("pdf-content");
  //   document.getElementById("pdf-content").style.display = "block";
  //   doc.html(content, {
  //     callback: function (doc) {
  //       doc.save();
  //       document.getElementById("pdf-content").style.display = "none";
  //     },
  //   });
  // }

  const InvoiceItems = (props) => {
    const [invoiceopen, setInvoiceOpen] = React.useState(false);
    const handleInvoiceOpen = () => {
      setInvoiceOpen(true);
    };

    const handleInvoiceClose = () => {
      setInvoiceOpen(false);
    };

    const imagedown = (params) => {
      let url = `${CurrentUrl}${props.invoicePath}`;
      saveAs(url, "Order invoice");
    };

    return (
      <Box>
        <IconButton onClick={handleInvoiceOpen}>
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog
          className="fullScreen-box"
          fullScreen
          open={invoiceopen}
          onClose={handleInvoiceClose}
        >
          <DialogContent style={{ textAlign: "center" }}>
            <iframe
              className="iframe-data"
              width={"100%"}
              height={"100%"}
              src={`${CurrentUrl}${props.invoicePath}`}
            >
              {" "}
            </iframe>

            {/* <iframe width={'100%'} height={'800px'} 
            src="http://mvikasapi.adequateshop.com//UploadImage/c436e7ac-7bc8-49c2-9827-4601a1aef6c5_Hubtel%20Direct%20Receive%20Money%20(v1.5).pdf"></iframe> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={imagedown}>Download</Button>
            <Button onClick={handleInvoiceClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const myParam = useLocation().search;
  const order_status = new URLSearchParams(myParam).get("order_status");

  useEffect(() => {
    loadCustomerOrders();
  }, []);

  const loadCustomerOrders = () => {
    setData([]);

    var params = "";

    if (order_status) {
      setStatus(order_status);
    }

    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }

    if (
      order_status != null &&
      order_status != undefined &&
      order_status != ""
    ) {
      params += `status=${order_status}&`;
    }

    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }

    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    GetCustomerOrderlist(localStorage.getItem("user_email"), params)
      .then((result) => {
        result.data.sort(
          (a, b) => new Date(b.orderDate) - new Date(a.orderDate)
        );
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">My Orders</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">My Orders</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
              <div className="col">
                <TextField
                  // required
                  fullWidth
                  id="outlined-required"
                  label="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    id="Status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    labelId="Status"
                    id="Status"
                    fullWidth
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>All</em>
                    </MenuItem>
                    <MenuItem value={"Booked"}>Booked</MenuItem>
                    <MenuItem value={"Intransit"}>In-Transit</MenuItem>
                    <MenuItem value={"RTO"}>RTO</MenuItem>
                    <MenuItem value={"Delivered"}>Delievered</MenuItem>
                    <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={fromDate}
                    onChange={(e) => {
                      setfromDate(e.toString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.toString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="">
                <IconButton
                  onClick={() => {
                    loadCustomerOrders();
                  }}
                  size="small"
                  className="search-icon"
                >
                  <ManageSearchIcon />
                </IconButton>
              </div>
            </Card>

            <Card className="custum-shadow mt-3">
              <DataGrid
                autoHeight
                sx={{
                  p: "1rem",
                  "@media print": {
                    ".table-ui": { border: "none" },
                  },
                }}
                className="table-ui"
                disableColumnMenu
                getRowId={(row) => row.id ?? row.order_id}
                disableDensitySelector
                rows={data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </Card>
          </div>
        </section>

        <div id="img-content" style={{ display: "none" }}>
          {/* <img src= {`http://mvikasapi.adequateshop.com${props.invoicePath}`}
      /> */}
        </div>
      </div>
    </>
  );
}

//<div id="pdf-content" style={{ display: "none" }}>
// <div
//   className="page"
//   style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
// >
//   <table
//     style={{
//       width: "100%",
//       borderCollapse: "separate",
//       borderSpacing: "0px 20px",
//     }}
//   >
//     <tr>
//       <td style={{ width: "10%" }}>
//         <img
//           src="https://i.ibb.co/MVHz4d9/pdf-logo.png"
//           style={{ width: "140px" }}
//         />
//       </td>
//       <td
//         style={{
//           fontSize: "30px",
//           fontWeight: "600",
//           color: "#F00",
//           textAlign: "center",
//         }}
//       >
//         ORDER RECEIPT
//       </td>
//     </tr>
//   </table>
//   <table style={{ width: "100%", fontSize: "14px" }}>
//     <tr>
//       <td
//         colspan="3"
//         style={{ fontSize: "18px", fontWeight: "600", color: "#F00" }}
//       >
//         MVIKAS SUPER LOGISTICS PRIVATE LIMITED
//       </td>
//     </tr>
//     <tr style={{ verticalAlign: "top" }}>
//       <td>
//         <p style={{ width: "200px", margin: 0 }}>
//           BLOCK-A, HOUSE NO. 39, COSY HOME APTTS, SECTOR- 9, ROHINI,
//           Delhi 110085
//         </p>
//       </td>
//       <td>
//         <table style={{ width: "100%" }}>
//           <tr>
//             <td style={{ fontWeight: "600" }}>GSTIN:</td>
//             <td>07AAOCM4603Q1ZW</td>
//           </tr>
//           <tr>
//             <td style={{ fontWeight: "600" }}>State:</td>
//             <td>07-Delhi</td>
//           </tr>
//           <tr>
//             <td style={{ fontWeight: "600" }}>PAN:</td>
//             <td>AAOCM46030</td>
//           </tr>
//           <tr>
//             <td style={{ fontWeight: "600" }}>CIN:</td>
//             <td>U63030DL2021PTC379114</td>
//           </tr>
//         </table>
//       </td>
//       <td>
//         <table style={{ width: "100%" }}>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Order Date:</td>
//             <td>{orderdata.orderDate}</td>
//           </tr>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Order No.:</td>
//             <td>{orderdata.order_id}</td>
//           </tr>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Reference No.:</td>
//             <td></td>
//           </tr>
//         </table>
//       </td>
//     </tr>
//     <tr>
//       <td colspan="3"></td>
//     </tr>
//     <tr>
//       <td
//         colspan="3"
//         style={{ background: "#f00", padding: "0", height: "1px" }}
//       ></td>
//     </tr>
//     <tr>
//       <td colspan="3"></td>
//     </tr>
//     <tr style={{ verticalAlign: "top" }}>
//       <td>
//         <table style={{ width: "100%" }}>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Vendor Name</td>
//           </tr>
//           <tr>
//             <td>{orderdata.vendor_Name}</td>
//           </tr>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Customer GSTIN:</td>
//           </tr>
//           <tr>
//             <td>-</td>
//           </tr>
//         </table>
//       </td>
//       <td>
//         <table style={{ width: "100%" }}>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Billing Address</td>
//           </tr>
//           <tr>
//             <td>
//               {orderdata.cust_fulladdress}
//               <br />
//               {orderdata.cust_State}
//             </td>
//           </tr>
//         </table>
//       </td>
//       <td>
//         <table style={{ width: "100%" }}>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Shipping Address</td>
//           </tr>
//           <tr>
//             <td>
//               {orderdata.cust_Deliveryaddress}
//               <br />
//               {orderdata.cust_State}
//             </td>
//           </tr>
//         </table>
//       </td>
//     </tr>
//     <tr>
//       <td
//         colspan="3"
//         style={{ background: "#f00", padding: "0", height: "1px" }}
//       ></td>
//     </tr>
//     <tr>
//       <td>
//         <table style={{ width: "100%" }}>
//           <tr>
//             <td style={{ fontWeight: "600" }}>Delievery Date:</td>
//             <td> {orderdata.expected_Date} </td>
//           </tr>
//         </table>
//       </td>
//     </tr>
//     <tr>
//       <td
//         colspan="3"
//         style={{ background: "#f00", padding: "0", height: "1px" }}
//       ></td>
//     </tr>
//   </table>

//   <table
//     className="item-table"
//     style={{
//       width: "100%",
//       fontSize: "14px",
//       textAlign: "left",
//       border: "1px solid #c9c6a5",
//       borderCollapse: "separate",
//       borderSpacing: "0px",
//     }}
//   >
//     <thead style={{ background: "#fffac3" }}>
//       <tr>
//         <th></th>
//         <th>Item</th>
//         <th>HSN/SAC</th>
//         <th>Quantity</th>
//         <th>Rate/Item(₹)</th>
//         <th>Discount(₹)</th>
//         <th>Taxable Value(₹)</th>
//         <th style={{ borderLeft: "1px solid #c9c6a5" }}>CGST(₹)</th>
//         <th>SGST/UTGST(₹)</th>
//         <th style={{ borderRight: "1px solid #c9c6a5" }}>CESS(₹)</th>
//         <th>Total(₹)</th>
//       </tr>
//     </thead>
//     <tbody>
//       <tr style={{ verticalAlign: "top" }}>
//         <td>1.</td>
//         <td>
//           <p
//             style={{ margin: "0px", width: "100px", fontWeight: "500" }}
//           >
//             {orderdata.itemname}
//           </p>
//           <span style={{ fontSize: "12px", display: "block" }}>
//             - As per Annexure
//           </span>
//         </td>
//         <td>996531</td>
//         <td>1.00 OTH</td>
//         <td>{orderdata.rate}</td>
//         <td>0.00</td>
//         <td>{orderdata.taxableAmount}</td>
//         <td style={{ borderLeft: "1px solid #c9c6a5" }}>
//           {orderdata.gst_Amount}
//           <span style={{ fontSize: "12px", display: "block" }}>
//             @9%
//           </span>
//         </td>
//         <td>
//           {orderdata.gst_Amount}
//           <span style={{ fontSize: "12px", display: "block" }}>
//             @9%
//           </span>
//         </td>
//         <td style={{ borderRight: "1px solid #c9c6a5" }}>0.00</td>
//         <td>{orderdata.total_Amount}</td>
//       </tr>
//     </tbody>
//     <tfoot
//       style={{
//         background: "#fffac3",
//         fontWeight: "600",
//         fontSize: "16px",
//       }}
//     >
//       <tr>
//         <td colspan="6" align="center">
//           Total
//         </td>
//         <td>{orderdata.taxableAmount}</td>
//         <td style={{ borderLeft: "1px solid #c9c6a5" }}>
//           {" "}
//           {orderdata.gst_Amount}
//         </td>
//         <td>{orderdata.gst_Amount}</td>
//         <td style={{ borderRight: "1px solid #c9c6a5" }}>0.00</td>
//         <td>{orderdata.total_Amount}</td>
//       </tr>
//     </tfoot>
//   </table>

//   <table
//     className="table-3"
//     style={{
//       width: "100%",
//       fontSize: "14px",
//       textAlign: "right",
//       borderCollapse: "separate",
//       borderSpacing: "0px 10px",
//       fontSize: "15px",
//     }}
//   >
//     <tr>
//       <td>
//         Taxable Amount:
//         {orderdata.taxableAmount}
//       </td>
//     </tr>
//     <tr>
//       <td>
//         Total Tax:
//         {orderdata.taxableAmount}
//       </td>
//     </tr>
//     <tr>
//       <td style={{ fontSize: "20px", fontWeight: "800" }}>
//         Total Value:
//         {orderdata.total_Amount}
//       </td>
//     </tr>
//     {/* <tr>
//       <td>Total amount (in words):
//       {orderdata.total_Amount}
//        </td>
//     </tr> */}
//   </table>

//   <table
//     style={{
//       width: "100%",
//       fontSize: "13px",
//       textAlign: "left",
//       borderCollapse: "separate",
//       borderSpacing: "0px 5px",
//       marginTop: "50px",
//     }}
//   >
//     <tr>
//       <td style={{ width: "50%" }}>
//         <table
//           style={{
//             width: "100%",
//             border: "2px solid #ffc9c9",
//             fontSize: "18px",
//             color: "#000",
//             borderSpacing: "15px",
//           }}
//         >
//           <tr>
//             <td style={{ fontWeight: "600" }}>Bank Details:</td>
//           </tr>
//           <tr>
//             <td>
//               <table style={{ width: "100%" }}>
//                 <tr>
//                   <td
//                     style={{ fontWeight: "600", padding: "10px 0 0" }}
//                   >
//                     Account Number:
//                   </td>
//                   <td>00000000</td>
//                 </tr>
//                 <tr>
//                   <td
//                     style={{ fontWeight: "600", padding: "10px 0 0" }}
//                   >
//                     Bank Name:
//                   </td>
//                   <td>ABC</td>
//                 </tr>
//               </table>
//             </td>
//             <td>
//               <table style={{ width: "100%" }}>
//                 <tr>
//                   <td
//                     style={{ fontWeight: "600", padding: "10px 0 0" }}
//                   >
//                     IFSC:
//                   </td>
//                   <td>KKBK4605</td>
//                 </tr>
//                 <tr>
//                   <td
//                     style={{ fontWeight: "600", padding: "10px 0 0" }}
//                   >
//                     Branch Name:
//                   </td>
//                   <td>DELHI</td>
//                 </tr>
//               </table>
//             </td>
//           </tr>
//         </table>
//       </td>
//       <td style={{ verticalAlign: "top", width: "50%" }}>
//         <table
//           style={{
//             width: "100%",
//             textAlign: "center",
//             fontSize: "18px",
//             fontWeight: "600",
//           }}
//         >
//           <tr>
//             <td style={{ textAlign: "center" }}>
//               For MVIKAS SUPER LOGISTICS PRIVATE LIMITED
//             </td>
//           </tr>
//           <tr>
//             <td
//               style={{
//                 borderBottom: "2px solid #000",
//                 height: "100px",
//                 textAlign: "center",
//               }}
//             ></td>
//           </tr>
//           <tr>
//             <td>Authorised Signatory</td>
//           </tr>
//         </table>
//       </td>
//     </tr>
//   </table>
// </div>
//</div>
