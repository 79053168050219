import React, { useState } from "react";
import {
  Box,
  TextField,
  Grid,
  Autocomplete,
  Button,
  Card,
} from "@mui/material";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import { autocompletepins } from "../Services/ZoneApi";
import { getratepincodewise } from "../Services/RateApi";
import Loading from "./Loading";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import InputAdornment from "@mui/material/InputAdornment";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ScaleOutlinedIcon from "@mui/icons-material/ScaleOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";

const DownloadRateSheet = () => {
  const [rowdata, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [destinationpin, setDestinationpin] = useState([]);

  const columnname = [
    { field: "fromPinCode", headerName: "Source", width: 200 },
    { field: "toPinCode", headerName: "Destination", width: 200 },
    { field: "serviceName", headerName: "Service Name", width: 200 },
    { field: "taxableAmount", headerName: "Taxable Amt.", width: 200 },
    { field: "gstAmount", headerName: "Tax Amt.", width: 200 },
    { field: "totalAmount", headerName: "Total Amt.", width: 200 },
  ];

  const [data, setData] = useState({
    fromPinCode: "",
    toPincode: "",
    shipmentWt: "",
    shipmentValue: "",
  });
  
  const [destinationauto, setDestinationauto] = useState([]);
  const InputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitData = () => {
    let count = 0;
    getratepincodewise(data).then((res) => {
      if (res.status !== 200) {
        setRowData([]);
      } else {
        const list = [];
        res.data.reverse().map((val) => {
          list.push({
            id: count,
            order_id: val.order_id,
            lpname: val.lpname,
            fromPinCode: val.fromPinCode,
            serviceName: val.serviceName,
            toPinCode: val.toPinCode,
            chargable_weight: val.chargable_weight,
            taxableAmount: val.taxableAmount,
            gstAmount: val.gstAmount,
            totalAmount: val.totalAmount,

            action: (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  onClick={() => {
                    //  modalView(0, val.order_id);
                  }}
                >
                  <RemoveRedEyeIcon />
                </button>
                {val.rating === 0 || val.rating === null ? (
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                    style={{ marginLeft: "5px" }}
                    onClick={() => {
                      //modalView(1, val.order_id);
                    }}
                  >
                    Rating
                  </button>
                ) : (
                  <></>
                )}
              </div>
            ),

            cft: val.cft,
            chargable_weight: val.chargable_weight,
            codAmount: val.codAmount,
            docketcharge: val.docketCharge,
            fovPercentage: val.fovPercentage,
            fov_amount: val.fov_amount,
            fsc_amount: val.fsc_amount,
            fsc_percent: val.fsc_percent,
            greentax: val.greentax,
            greentax_amount: val.greentax_amount,
            gst: val.gst,
            gst_Amount: val.gst_Amount,
            handlingCharge: val.handlingCharge,
            minFOV: val.minFOV,
            minFreight: val.minFreight,
            min_Chargable_weight: val.min_Chargable_weight,
            oda_amount: val.oda_amount,
            rate: val.rate,
            shipment_value: val.shipment_value,
            shipment_weight: val.shipment_weight,
            taxableAmount: val.taxableAmount,
            totalAmount: val.totalAmount,
            deliveryaddressmobile: val.deliveryaddressmobile,
            deliveryaddressname: val.deliveryaddressname,
            pickupaddressmobile: val.pickupaddressmobile,
            pickupaddressname: val.pickupaddressname,
            paymentmode: val.paymentmode,
            pickuptime: val.pickuptime,
          });
          count++;
        });
        setRowData(list);
      }
    });
  };
  if (loading) return <Loading />;
  else {
    return (
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <h1 class="main-title">MVIKAS Rate Card</h1>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <Card className="custum-shadow order-search-box">
              <div className="row">
                <div className="col">
                  <TextField
                    className="pd-input"
                    id="Source"
                    required
                    fullWidth
                    label="Source"
                    onChange={(e) => InputChange(e)}
                    name="fromPinCode"
                    value={data.fromPinCode}
                    placeholder="Pincode"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PlaceOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col">
                  <Autocomplete
                    multiple
                    limitTags={2}
                    id="multiple-limit-tags"
                    value={destinationauto}
                    options={destinationpin}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, value) => {
                      let str = "";
                      setDestinationauto(value);
                      value.map((val) => {
                        str = str + val.title + ",";

                        setData({ ...data, toPincode: str });
                      });
                    }}
                    name="destinationpin"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"
                        required
                        onChange={(e) => {
                          if (e.target.value.length >= 2) {
                            autocompletepins(e.target.value).then((res) => {
                              let list = [];

                              res.map((data) =>
                                list.push({ title: data.toString() })
                              );
                              setDestinationpin(list);
                            });
                          }
                        }}
                        placeholder="Destination"
                      />
                    )}
                    fullWidth
                  />
                </div>
                <div className="col">
                  <TextField
                    id="Avg_Shippment_Weight"
                    label="Avg. Shippment Wt."
                    name="shipmentWt"
                    required
                    value={data.shipmentWt}
                    onChange={(e) => InputChange(e)}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <ScaleOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
                </div>
                <div className="col">
                  <TextField
                    id="Avg_Shippment_Value"
                    required
                    label="Avg. Shippment Value"
                    onChange={(e) => InputChange(e)}
                    name="shipmentValue"
                    value={data.shipmentValue}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CurrencyRupeeOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                  />
                </div>
              </div>
              <div className="text-center">
                <Button
                  variant="contained"
                  className="search-btn"
                  onClick={submitData}
                >
                  Search
                </Button>
              </div>
            </Card>
          </div>
        </section>

        <section className="content mt-5">
          <div className="container-fluid">

            {rowdata.length === 0 ? (
              <>
              <div className="no-data">
                <LocalShippingTwoToneIcon sx={{ fontSize: 60 }} />
                <h4>No Data Found</h4>
              </div>
              <div className="facts-boxs">
                    <h4>
                      Did you know that the largest ship in the world that is
                      used for logistics can carry and transport the Eiffel
                      tower with no problem?
                    </h4>
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="card">
                          <span>01</span>
                          <p className="scroll-p">
                            According to the World Bank Logistics Performance
                            Index of 2018, India, despite being a large economy,
                            ranked 44th on the list with a rating of 3.18.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <span>02</span>
                          <p className="scroll-p">
                            In totality, an approximate 8.5 million trucks of
                            all shapes and sizes ply on the winding roads and
                            highways of India.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <span>03</span>
                          <p className="scroll-p">
                            Indian Railways operates more than 9,200 trains a
                            day and transports 1,110 million tons of freight
                            during the fiscal year.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="card">
                          <span>04</span>
                          <p className="scroll-p">
                            High-capacity parcel vans also commonly known as
                            Green Parcel Vans have been used in rakes designed
                            to transport fruit and vegetables for special
                            purposes. The high-capacity parcel van carries 23 t
                            against the regular parcel van carrying 18 t of
                            goods.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
              </>
            ) : (
              <>
                <div style={{ height: 300, width: "100%", marginTop: "10px" }}>
                  <DataGrid
                    rows={rowdata}
                    components={{ Toolbar: GridToolbar }}
                    columns={columnname}
                  />
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    );
  }
};

export default DownloadRateSheet;
