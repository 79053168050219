import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "../Services/UserApi";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Tooltip } from "@mui/material";
import { Vendorneworder } from "../Services/DashboardApi";
import { markasread } from "../Services/OrderApi";
import {
  GenerateCurrentUserlogin,
  GetUserLastLogin,
  UserLastLogin,
} from "../Services/UserApi";

const Header = (props) => {
  const { submitted } = props;
  const [username, setUsername] = useState("");

  const navigate = useNavigate();

  const Logoutbtn = () => {
    UserLastLogin(localStorage.getItem("user_email")).then((r) => {});

    Logout().then((res) => {
      localStorage.removeItem("Logkey");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_email");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_role");
      navigate("/");
    });
  };

  useEffect(() => {
    const removeTokenOnUnload = () => {
      localStorage.removeItem("Logkey");
      navigate("/");
    };

    window.addEventListener("beforeunload", removeTokenOnUnload);

    return () => {
      window.removeEventListener("beforeunload", removeTokenOnUnload);
    };
  }, []);

  const role = localStorage.getItem("user_role").split(",");
  const [count, setCount] = useState(0);
  // const [userdata, setUserData] = useState({
  //   name: "",
  // });

  useEffect(() => {
    const token = localStorage.getItem("Logkey");
    if (props) {
      GenerateCurrentUserlogin(token).then((r) => {
        setUsername(r);
      });
    }
  }, [submitted]);

  const [lastLoginTime, setLastLoginTime] = useState("");

  useEffect(() => {
    GetUserLastLogin(localStorage.getItem("user_email")).then((r) => {
      setLastLoginTime(r.data.data);
    });

    GetUserLastLogin();
  }, []);

  useEffect(() => {
    Vendorneworder().then((res) => {
      setCount(res.data);
    });
  }, []);

  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-expand-admin navbar-white navbar-light d-flex justify-content-between">
        {/* Left navbar links */}

        <div className="content-header">
          <div className="left-side">
            <a
              className="pushmenu"
              data-widget="pushmenu"
              href="#"
              role="button"
              id="ToggleAuto"
            >
              <img src="images/menu.svg" />
            </a>
            <div className="welcome-mess">
              <span>Welcome</span>
              <h1 className="main-title">{username.name}</h1>
            </div>
          </div>
          <div className="right-side ml-auto">
            <p className="text-right mb-0 last-login">
              {" "}
              Your last login {lastLoginTime}
            </p>

            <div className="nav-item d-inline-block d-sm-inline-block">
              {/* {role.includes("Vendor") ? (
                <Tooltip title="Total New Orders" arrow>
                  <Badge
                    badgeContent={count}
                    color="error"
                    sx={{ marginRight: "10px" }}
                  >
                    <NotificationsIcon
                      onClick={() => {
                        if (count === 0) navigate("/orderview");
                        else
                          markasread().then((res) => {
                            ///navigate("/orderview");
                          });
                      }}
                    />
                  </Badge>
                </Tooltip>
              ) : (
                <></>
              )} */}
              <div className="btn-group">
                <button
                  className="btn dropdown-toggle no-arrow"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ padding: "0px" }}
                >
                  <span className="user-img">
                    <img src="images/user.svg" />
                  </span>{" "}
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-Log"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li>
                    {/* {role.includes("User") ? 
                  <Link to="/setting">
                      <span className="dropdown-item dropdown-item-Log">
                        <i className="fas fa-cog"></i> Profile
                      </span>
                    </Link> :
                    
                       <Link to="/profile">
                      <span className="dropdown-item dropdown-item-Log">
                        <i className="fas fa-cog"></i> Profile
                      </span>
                    </Link>
                    
                     } */}

                    <>
                      {role.includes("User") ? (
                        <Link to="/setting">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      ) : role.includes("Vendor") ? (
                        <Link to="/vsetting">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      ) : (
                        <Link to="/profile">
                          <span className="dropdown-item dropdown-item-Log">
                            <i className="fas fa-cog"></i> Profile
                          </span>
                        </Link>
                      )}
                    </>
                  </li>
                  <li>
                    <span
                      className="dropdown-item dropdown-item-Log"
                      onClick={Logoutbtn}
                    >
                      <i className="fas fa-sign-out-alt"></i> Log Out
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </div>
      </nav>
    </div>
  );
};

export default Header;
