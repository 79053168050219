import * as React from "react";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Avatar from "@mui/material/Avatar";
import { blueGrey } from "@mui/material/colors";
import LocalShippingTwoToneIcon from "@mui/icons-material/LocalShippingTwoTone";
import {
  GetOrderList,
  GetSaleOrderList,
  UpdateOrderStatus,
  GetOrderStatusList,
  DeleteOrder,
  GetDocketNumber,
  UpdateDocketNumber,
} from "../Services/AdminApi";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentUrl } from "../Services/UrlApi";
import { useLocation } from "react-router";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { saveAs } from "file-saver";
import { red } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
let Url = `${CurrentUrl}Admin/`;

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
    </GridToolbarContainer>
  );
}
function NoRowsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fas fa-boxes"></i>
      <p>No order data available</p>
    </Stack>
  );
}
function NoResultsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fal fa-list"></i>
      <p>No results in DataGrid</p>
    </Stack>
  );
}

export default function VendorSales() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  // const [orderstatus, setOrderstatus] = useState({});
  const [orderstatus, setOrderstatus] = useState({
    1: { remarks: "" }, // BOOKED
    2: { remarks: "" }, // IN-TRANSIT
    4: { remarks: "" }, // DELIVERED
    5: { remarks: "" }, // CANCELLED
  });

  const [remarks, setRemarks] = useState("No remarks");
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [status, setStatus] = useState("");
  // const [savedRemarks, setSavedRemarks] = useState("");
  const rowsPerPageOptions = [100, 200,500, 1000];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [docketnumber, setdocketnumber] = useState("")

  useEffect(() => {
    if (!showProgress) {
      return;
    }
    const timer = setTimeout(() => {
      setShowProgress(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [showProgress]);

  // Delete
  const [deleteopen, setOpenDelete] = useState(false);

  const deleteClickOpen = () => {
    setOpenDelete(true);
  };

  const deleteClose = () => {
    setOpenDelete(false);
  };

  const [docketupdatepopupopen, setDocketupdatepopupopen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpenDialog = (params) => {
    setSelectedRow(params.row);
    console.log("my handleopen params", params.row.lspDocketNo);
    setdocketnumber(params.row.lspDocketNo)
    setDocketupdatepopupopen(true);
  };

  const handleCloseDialog = () => {
    setDocketupdatepopupopen(false);
  };

  const handleUpdateDocketNumber = async () => {
    try {
      const updatedData = {
        orderid: selectedRow.order_id,
        docketno: docketnumber,
      };
      const response = await UpdateDocketNumber(updatedData);

      if (response.ok) {
        setDocketupdatepopupopen(false);
        toast.success("LSP Docket No. Updated Successfully");
        Refreshafterlpdocketupdate()
      } else {
        console.error("Failed to update docket number:", response);
      }
    } catch (error) {
      console.error("Failed to update docket number:", error);
    }
  };
  const Refreshafterlpdocketupdate = () => {
    GetSaleOrderList()
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const columns = [
    { field: "order_id", headerName: "Order ID ", width: 100 },
    { field: "invoiceNumber", headerName: "Invoice Number", width: 150 },
    { field: "vendor_Name", headerName: "LP Name", width: 150 },
    { field: "noOfArticles", headerName: "Articles", width: 150 },
    { field: "itemInvoice", headerName: "Customer Invoice No", width: 200 },
    { field: "lspDocketNo",  headerName: "LSP Docket Number",  width: 200,},
    {
      field: "action",
      headerName: "Edit Docket Number",
      sortable: false,
      minWidth: 180,
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <Button onClick={() => handleOpenDialog(params)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: grey[300] }}>
                <DriveFileRenameOutlineTwoToneIcon
                  fontSize="small"
                  sx={{ color: grey[900] }}
                />
              </Avatar>
            </Button>
          </Box>
        );
      },
    },
    { field: "itemname", headerName: "Item Name", width: 150 },
    { field: "transporterName", headerName: "Transporter Name", width: 150 },
    { field: "mvikasDocketNo", headerName: "MVikas Docket Number", width: 300 },
    { field: "eWayBillNo", headerName: "E-way Bill Number", width: 300 },
    { field: "eWayBillExpDate", headerName: "E-way Bill Expiry Date", width: 300 },
    { field: "transporterName", headerName: "Transporter Name", width: 300 },

    {
      field: "status",
      width: 150,
      headerName: "Status",
      disableExport: true,
      sortable: false,
      renderCell: (params) => {
        const role = localStorage.getItem("user_role").split(",");
        return (
          <Button onClick={() => Getsalesorderstatus(params)}>
            <Avatar sx={{ width: 40, height: 40, bgcolor: blueGrey[100] }}>
              <LocalShippingTwoToneIcon
                fontSize="small"
                sx={{ color: blueGrey[900] }}
              />
            </Avatar>
          </Button>
        );
      },
    },
    {
      field: "iteminvoice",
      headerName: "Item Invoice",
      disableExport: true,
      width: 100,
      renderCell: (params) => {
        return <InvoiceItems invoicePath={params.row.invoicePath} />;
      },
    },
    {
      field: "Action",
      width: 150,
      headerName: "Action",
      disableExport: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            onClick={() => {
              DeleteOrder(params.row.order_id);
            }}
          >
            <Avatar sx={{ width: 40, height: 40, bgcolor: red[100] }}>
              <DeleteRoundedIcon fontSize="small" sx={{ color: red[900] }} />
            </Avatar>
          </Button>
        );
      },
    },
    { field: "customername", headerName: "Customer Name", width: 150 },
    { field: "customerphone", headerName: "Customer Phone", width: 150 },
    { field: "customeremail", headerName: "Customer Email", width: 150 },
    { field: "order_Date", headerName: "Order Date", width: 150 },
    { field: "order_Status", headerName: "Order Status", width: 150 },
    { field: "delivery_Address", headerName: "Delivery Address", width: 300 },
    { field: "delivery_name", headerName: "Delivery Person Name", width: 150 },
    {
      field: "delivery_mobile",
      headerName: "Delivery Person Contact",
      width: 150,
    },
    { field: "pickup_Address", headerName: "Pickup Address", width: 400 },
    { field: "pickup_name", headerName: "Pickup Person Name", width: 300 },
    { field: "pickup_mobile", headerName: "Pickup Person Contact", width: 300 },
    { field: "frompincode", headerName: "From Pin", width: 150 },
    { field: "topincode", headerName: "To Pin", width: 150 },
    { field: "chargable_weight", headerName: "Chargable Wt.", width: 150 },
    { field: "taxableAmount", headerName: "Taxable Amt.", width: 150 },
    { field: "gst_Amount", headerName: "GST Amt.", width: 150 },
    { field: "total_Amount", headerName: "Total Amount", width: 150 },
  ];

  const DeleteOrder = async (obj) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${Url}DeleteOrder?orderid=${obj}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(obj),
    })
      .then((r) => {
        toast.success("Order Deleted Successfully");
        Refreshaafterdelete();
        return r.json();
      })
      .catch((e) => {
        return e.response;
      });
  };

  const Refreshaafterdelete = () => {
    GetSaleOrderList()
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const InvoiceItems = (props) => {
    const [invoiceopen, setInvoiceOpen] = useState("");
    const handleInvoiceOpen = () => {
      setInvoiceOpen(true);
    };

    const handleInvoiceClose = () => {
      setInvoiceOpen(false);
    };

    const imagedown = (params) => {
      let url = `${CurrentUrl}${props.invoicePath}`;
      saveAs(url, "Order invoice");
    };

    return (
      <Box>
        <IconButton onClick={handleInvoiceOpen}>
          {" "}
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog
          className="fullScreen-box"
          fullScreen
          open={invoiceopen}
          onClose={handleInvoiceClose}
        >
          <DialogContent style={{ textAlign: "center" }}>
            {/* <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" /> */}
            <iframe
              className="iframe-data"
              width={"100%"}
              height={"100%"}
              src={`${CurrentUrl}${props.invoicePath}`}
            >
              {" "}
            </iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={imagedown}>Download</Button>
            <Button onClick={handleInvoiceClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const Getsalesorderstatus = async (order) => {
    let response = await GetOrderStatusList(order.id).then((r) => {
      return r;
    });

    if (response != null) {
      let orderDetails = {
        isBooked: response.data[0].isBooked,
        isCancelled: response.data[0].isCancelled,
        isDelivered: response.data[0].isDelivered,
        isIntransist: response.data[0].isIntransist,
        isRTO: response.data[0].isRTO,
        orderId: response.data[0].orderId,
        remarks: "",
        email: order.row.customeremail,
        status: status,
      };
      localStorage.setItem("OrderId", orderDetails.orderId);
      setOrderstatus(orderDetails);
      setOpen(true);
    }
  };

  const openStatusPopup = async (status) => {
    const myorderId = localStorage.getItem("OrderId");
    setSelectedStatus(status);
    let response = await GetOrderStatusList(myorderId);
    if (response && response.data && response.data.length > 0) {
      if (status == 1) {
        setRemarks(response.data[0].bookedRemarks);
      } else if (status == 2) {
        setRemarks(response.data[0].intransitRemarks);
      } else if (status == 4) {
        setRemarks(response.data[0].deliveredRemarks);
      } else {
        setRemarks(response.data[0].cancelledRemarks);
      }
    }

    setOpenStatusDialog(true);
  };
  const closeStatusPopup = () => {
    setOpenStatusDialog(false);
    closeStatusPopup();
  };

  const updateStatusRemarks = (status) => {
    callUpdateOrderStatus();

    closeStatusPopup();
  };

  const getOrderStatusRemarksKey = (status) => {
    switch (status) {
      case 1:
        return "bookedRemarks";
      case 2:
        return "intransitRemarks";
      case 4:
        return "deliveredRemarks";
      case 5:
        return "cancelledRemarks";
      default:
        return "";
    }
  };
  const Updatesalesorderstatus = () => {
    setOpen(true);
    toast.success("Updated Successfully");
    callUpdateOrderStatus();
    setOrderstatus({});
  };
  const callUpdateOrderStatus = () => {
    let statuschange = {
      orderId: orderstatus.orderId,
      status: selectedStatus,
      remarks: remarks,
      email: orderstatus.email,
    };
    try {
      var result = UpdateOrderStatus(statuschange);
      // if (result.code == 200){
      toast.success("Updated Successfully");
      // }
      setOpen(false);
      console.log("Result", result);
    } catch (error) {
      console.error("API error:", error);
    }
  };

  const updateOrderStatusFromUI = (OStatus) => {
    if (OStatus === 1) {
      setOrderstatus((val) => ({ ...val, isBooked: true }));
    } else if (OStatus === 2) {
      setOrderstatus((val) => ({ ...val, isIntransist: true }));
    } else if (OStatus === 4) {
      setOrderstatus((val) => ({ ...val, isDelivered: true }));
    } else if (OStatus === 5) {
      setOrderstatus((val) => ({ ...val, isCancelled: true }));
    } else {
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowProgress(true);
  };
  const myParam = useLocation().search;
  const order_status = new URLSearchParams(myParam).get("order_status");
  useEffect(() => {
    loadData();
  }, []);
  const loadData = () => {
    setData([]);
    var params = "";
    if (order_status) {
      setStatus(order_status);
    }
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (
      order_status != null &&
      order_status != undefined &&
      order_status != ""
    ) {
      params += `status=${order_status}&`;
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    GetSaleOrderList(params)
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Sales Report</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Sales Report</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
              <div className="col">
                <TextField
                  required
                  fullWidth
                  id="outlined-required"
                  label="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col">
                <FormControl fullWidth>
                  <InputLabel
                    id="Status"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    Status
                  </InputLabel>
                  <Select
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    labelId="Status"
                    id="Status"
                    fullWidth
                    label="Status"
                  >
                    <MenuItem value="">
                      <em>Status</em>
                    </MenuItem>
                    <MenuItem value={"Booked"}>Booked</MenuItem>
                    <MenuItem value={"Intransit"}>In-Transit</MenuItem>
                    <MenuItem value={"RTO"}>RTO</MenuItem>
                    <MenuItem value={"Delievered"}>Delievered</MenuItem>
                    <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="From"
                    value={fromDate}
                    onChange={(e) => {
                      setfromDate(e.toString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="col datepicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="To"
                    value={toDate}
                    onChange={(e) => {
                      setToDate(e.toString());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className="">
                <IconButton
                  onClick={() => {
                    loadData();
                  }}
                  size="small"
                  className="search-icon"
                >
                  <ManageSearchIcon />
                </IconButton>
              </div>
            </Card>
            <Card className="custum-shadow mt-3">
              <div style={{ height: "600px" }}>
                <DataGrid
                  sx={{
                    p: "1rem",
                    "@media print": {
                      ".table-ui": { border: "none" },
                    },
                  }}
                  className="table-ui"
                  disableColumnMenu
                  disableDensitySelector
                  getRowId={(row) => row.id ?? row.order_id}
                  rows={data}
                  columns={columns}
                  // pageSize={10}
                  // rowsPerPageOptions={[10]}
                  pageSize={rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPageSizeChange={(newPageSize) => {
                    setRowsPerPage(newPageSize);
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                    NoRowsOverlay,
                    NoResultsOverlay,
                  }}
                />
              </div>
            </Card>
            {/* Dialog for updating docket number */}
            <Dialog open={docketupdatepopupopen} onClose={handleCloseDialog}>
              {/* <DialogTitle>Update Docket Number</DialogTitle> */}
              <DialogContent>
                <TextField
                  fullWidth
                  label="LSP Docket Number"
                  value={docketnumber}
                  onChange={(e) => setdocketnumber(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                <Button onClick={handleUpdateDocketNumber}>Update</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="xs"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle>{"Order Status"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="1"
                            defaultChecked={
                              orderstatus?.isBooked ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(1);
                            }}
                          />
                        }
                        label="Booked"
                      />
                      {orderstatus?.isBooked == true && (
                        <Button
                          onClick={() => openStatusPopup(1)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="2"
                            defaultChecked={
                              orderstatus?.isIntransist ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(2);
                            }}
                          />
                        }
                        label="In-Transit"
                      />
                      {orderstatus?.isIntransist == true && (
                        <Button
                          onClick={() => openStatusPopup(2)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="4"
                            defaultChecked={
                              orderstatus?.isDelivered ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(4);
                            }}
                          />
                        }
                        label="Delivered"
                      />
                      {/* {orderstatus?.isDelivered == true &&
                        orderstatus?.isBooked == true &&
                        orderstatus?.isIntransist == true && (
                          <Button
                            onClick={() => openStatusPopup(4)}
                            className="ml-auto"
                            variant="text"
                          >
                            Remarks
                          </Button>
                        )} */}
                      {orderstatus?.isDelivered == true && (
                        <Button
                          onClick={() => openStatusPopup(4)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                  </div>
                  <div className="order-list">
                    <div className="checkbox-date">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="5"
                            defaultChecked={
                              orderstatus?.isCancelled == true ? true : false
                            }
                            onChange={(e) => {
                              updateOrderStatusFromUI(5);
                            }}
                          />
                        }
                        label="Cancelled"
                      />
                      {orderstatus?.isCancelled == true && (
                        <Button
                          onClick={() => openStatusPopup(5)}
                          className="ml-auto"
                          variant="text"
                        >
                          Remarks
                        </Button>
                      )}
                    </div>
                    {/* <Button onClick={() => {Updatesalesorderstatus();}} variant="contained">
          Update
        </Button> */}
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </section>
      </div>
      {showProgress && (
        <div className="circular-progress-ui">
          <CircularProgress />
        </div>
      )}
      {/*  */}
      <ToastContainer />
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={deleteopen}
        onClose={deleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="delete-alert">
            <HelpOutlineRoundedIcon />
            <p>Are you sure you want to delete the order?</p>
            <Button onClick={deleteClose} variant="contained">
              Yes
            </Button>
            <Button
              onClick={deleteClose}
              autoFocus
              variant="outlined"
              className="ml-2"
            >
              No
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openStatusDialog}
        onClose={closeStatusPopup}
        maxWidth="xs"
        aria-describedby="status-dialog-description"
      >
        <DialogTitle>Remarks</DialogTitle>
        <DialogContent>
          <DialogContentText id="status-dialog-description">
            <TextField
              fullWidth
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              multiline
              style={{
                margin: "15px 0",
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {/* <TextField
              fullWidth
              label="Remarks"
              value={savedRemarks}
              multiline
              style={{ margin: "15px 0" }}
              InputLabelProps={{ shrink: true }}
            /> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStatusPopup}>Close</Button>
          <Button onClick={updateStatusRemarks} color="primary">
            Save
          </Button>
          {/* <Button
            onClick={() => {
              Updatesalesorderstatus();
            }}
            variant="contained"
          >
            Update
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
}
