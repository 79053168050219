import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import DynamicTable from "../DynamicTable";
import {
  Getserviceshavingzone,
  Getzonesbyservice,
} from "../../Services/ServiceApi";

import { GetZoneName } from "../../Services/ZoneApi";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { UploadRate, Getratebyservice } from "../../Services/RateApi";
import "../My.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";
import { Link } from "react-router-dom";


const ExcelUpload = () => {
  var curr = new Date();
  const [items, setItems] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [allservice, SetAllService] = useState([]);
  const [allzonenm, setAllzonenm] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tabldata, setTabledata] = useState([]);
  const [inputList, setInputList] = useState([
    {
      from: "",
      to: "",
      unit: "KG",
      rate: "",
    },
  ]);
  const [ratesheetid, setRatesheetid] = useState(0);
  const unit = ["KG"];
  curr.setDate(curr.getDate());
  var todaydate = curr.toISOString().substr(0, 10);
  curr.setDate(curr.getDate() + 30);
  var nextday = curr.toISOString().substr(0, 10);
  const [ratelistData, setRatelistData] = useState({
    serviceid: "",
    fromdate: todaydate,
    todate: nextday,
    ratelist: [],
    min_Weight: "",
    max_Weight: "",
    min_Freight: "",
    docket_Charge: "",
    min_FOV: "",
    actual_FOV: "",
    minGreenTax: "",
    greenTaxperkg: "",
    fsc: "",
    min_ODA: "",
    actual_ODA: "",
    handlingChrg: "",
    gst: "",
    cod: "",
    cft: "",
    divisor: "",
    min_ODA2: "",
    actual_ODA2: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Getserviceshavingzone().then((res) => {
      SetAllService(res);
    });

    GetZoneName().then((res) => {
      setAllzonenm(React.Children.toArray(res.map((val) => val.name)));
      setLoading(false);
    });
  }, []);

  let zoneTHead;
  let zoneTbody;
  zoneTHead = allzonenm.map((val, i) => {
    return (
      <>
        <th key={i}>{val}</th>
      </>
    );
  });
  zoneTbody = allzonenm.map((val, i) => {
    return (
      <>
        <tr>
          <td>{val}</td>
        </tr>
      </>
    );
  });
  const readExcel = (e) => {
    const file = e.target.files[0];
    const val = file.name.toLowerCase(),
      regex = new RegExp("(.*?).(xlsx)$");
    if (!regex.test(val)) {
      toast.warn("Please select .XLSX file format");
      e.target.value = "";
    } else {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          debugger;
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { defval: 0 });
          const wsname1 = wb.SheetNames[1];
          const ws1 = wb.Sheets[wsname1];
          const data1 = XLSX.utils.sheet_to_json(ws1, { defval: 0 });
          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        setHeaders(Object.keys(d[0]));
        d.map((val) => {});
        setRatelistData({
          ...ratelistData,
          ["ratelist"]: d.map((val) => {
            return JSON.stringify(val);
          }),
        });

        setItems(d);
      });
    }
  };

  const inputEvent = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (
      name === "min_Weight" ||
      name === "max_Weight" ||
      name === "min_FOV" ||
      name === "min_Freight" ||
      name === "min_ODA" ||
      name === "docket_Charge" ||
      name === "actual_ODA" ||
      name === "actual_FOV" ||
      name === "greenTaxperkg" ||
      name === "minGreenTax" ||
      name === "fsc" ||
      name === "gst" ||
      name === "cod" ||
      name === "handlingChrg" ||
      name === "cft" ||
      name === "divisor" ||
      name === "actual_ODA2" ||
      name === "min_ODA2"
    ) {
      if (!isNaN(value)) {
        setRatelistData({ ...ratelistData, [name]: value });
      }
    } else if (name === "fromdate" || name === "todate") {
      setRatelistData({ ...ratelistData, [name]: value });
    } else if (name === "serviceid") {
      setLoading(true);
      Getratebyservice(e.target.value).then((res) => {
        console.log(res);
        if (res.status === 200) {
          let list = [];
          let listlen = 1;

          for (var i in res.data.rates) {
            listlen++;
            list.push(res.data.rates[i].zoneidto);
            if (listlen > Math.sqrt(res.data.rates.length)) break;
          }
          setRatesheetid(res.data.additionalcharges.rateSheetId);
          Bindtablecolumn(list);
          Bindtabledata(list, res.data.rates);
          setLoading(false);
          document.getElementById("test-table-xls-button").click();
          document.getElementById("messagemodal").click();
          setColumns([]);
          setTabledata([]);
        } else {
          Getzonesbyservice(value).then((res) => {
            setAllzonenm(res.data.map((val) => val.name));
            setRatesheetid(0);
            setLoading(false);
            document.getElementById("test-table-xls-button").click();
            document.getElementById("messagemodal").click();
          });
        }
      });

      setRatelistData({ ...ratelistData, [name]: value });
    }
  };
  const Bindtablecolumn = (list) => {
    setColumns(
      list.map((head, i) => {
        return <th key={i}>{head}</th>;
      })
    );
  };
  const Bindtabledata = (list, ratesdata) => {
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };
  const saveData = (e) => {
    e.preventDefault();
    const { serviceid, fromdate, todate, ratelist, cft, divisor } =
      ratelistData;
    setLoading(true);
    if (
      serviceid === "" ||
      fromdate === "" ||
      todate === "" ||
      ratelist.length === 0 ||
      cft === "" ||
      divisor === ""
    ) {
      setLoading(false);
      toast.warning("Please fill all mandatories fields");
    } else {
      if (ratelistData.min_Weight === "") ratelistData.min_Weight = 0;
      if (ratelistData.max_Weight === "") ratelistData.max_Weight = 0;
      if (ratelistData.min_FOV === "") ratelistData.min_FOV = 0;
      if (ratelistData.min_Freight === "") ratelistData.min_Freight = 0;
      if (ratelistData.min_ODA === "") ratelistData.min_ODA = 0;
      if (ratelistData.docket_Charge === "") ratelistData.docket_Charge = 0;
      if (ratelistData.actual_ODA === "") ratelistData.actual_ODA = 0;
      if (ratelistData.actual_FOV === "") ratelistData.actual_FOV = 0;
      if (ratelistData.greenTax === "") ratelistData.greenTax = 0;
      if (ratelistData.fsc === "") ratelistData.fsc = 0;
      if (ratelistData.gst === "") ratelistData.gst = 0;
      if (ratelistData.cod === "") ratelistData.cod = 0;
      if (ratelistData.handlingChrg === "") ratelistData.handlingChrg = [];
      if (ratelistData.greenTaxperkg === "") ratelistData.greenTaxperkg = 0;
      if (ratelistData.minGreenTax === "") ratelistData.minGreenTax = 0;
      if (ratelistData.actual_ODA2 === "") ratelistData.actual_ODA2 = 0;
      if (ratelistData.min_ODA2 === "") ratelistData.min_ODA2 = 0;
      UploadRate(ratelistData, ratesheetid).then((res) => {
        if (res.status === 200) {
          setLoading(false);
          curr = new Date();
          curr.setDate(curr.getDate());
          todaydate = curr.toISOString().substr(0, 10);
          curr.setDate(curr.getDate() + 30);
          nextday = curr.toISOString().substr(0, 10);

          setRatelistData({
            serviceid: "",
            fromdate: todaydate,
            todate: nextday,
            ratelist: [],
            min_Weight: "",
            max_Weight: "",
            min_Freight: "",
            docket_Charge: "",
            min_FOV: "",
            actual_FOV: "",
            minGreenTax: "",
            greenTaxperkg: "",
            fsc: "",
            min_ODA: "",
            actual_ODA: "",
            handlingChrg: "",
            gst: "",
            cod: "",
            cft: "",
            divisor: "",
            min_ODA2: "",
            actual_ODA2: "",
          });
          setInputList([{ from: "", to: "", unit: "KG", rate: "" }]);
          setHeaders([]);
          setItems([]);
          setRatesheetid(0);

          toast.success("Save data successfully");
        } else {
          setLoading(false);
          toast.error(res.data);
        }
      });
    }
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    setRatelistData({ ...ratelistData, ["handlingChrg"]: inputList });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setRatelistData({
      ...ratelistData,
      ["handlingChrg"]: inputList.filter((val, i) => {
        return i !== index;
      }),
    });
  };

  // handle click event of the Add button
  const handleAddClick = (e) => {
    const list = [...inputList];

    if (
      list[e]["from"] !== "" &&
      list[e]["to"] !== "" &&
      list[e]["rate"] !== ""
    ) {
      setInputList([...inputList, { from: "", to: "", unit: "KG", rate: "" }]);
    } else toast.warning("Please enter handling charges");
  };

  if (loading) return <Loading />;
  else {
    return (
      <>
        <button
          id="messagemodal"
          type="button"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          style={{ display: "none" }}
        ></button>

        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">
                  Rate Upload Instructions
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                Sample format has been downloaded for the selected service.
                Please fill in rate in given format and save file as "Excel
                Workbook". Then upload the same file.
                <br />
                <span style={{ color: "red" }}>
                  Please do not change any predefined columns or rows.
                </span>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper ">
          <div className="container px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap my-3">
              <h3>Upload Rates</h3>

              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-shine btn-success d-none"
                table="table-to-xls"
                filename="RateList"
                sheet="RateList"
                buttonText={<i className="fas fa-download"> Sample Format</i>}
              />
              <Link to="/viewuploadedrate" className="text-white btn btn-info">
                View Rate
              </Link>
            </div>
            <form>
              <div className="row px-3">
                <div className="col-md-6 mb-4">
                  <div className="row">
                    <div className="col-md-6 mb-4">
                      <span>
                        Select Service Name
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <select
                        className="form-control"
                        onChange={inputEvent}
                        name="serviceid"
                        value={ratelistData.serviceid}
                      >
                        <option selected hidden>
                          Select
                        </option>
                        {!(allservice.length > 0) ? (
                          <option>No Data Found</option>
                        ) : (
                          allservice.map((val, i) => {
                            return (
                              <option key={i} value={val.id}>
                                {val.name}
                              </option>
                            );
                          })
                        )}
                      </select>
                    </div>
                    <div className="col-md-6 mb-4">
                      {" "}
                      <div className="d-flex flex-column">
                        <span>
                          Upload Excel file
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        <input
                          type="file"
                          className="form control"
                          accept=".xlsx"
                          onChange={(e) => {
                            readExcel(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>
                    Rate Formula <span style={{ color: "red" }}>*</span>
                  </span>

                  <div className="input-group mb-3">
                    <span className="input-group-text">No.</span>
                    <input
                      type="text"
                      className="form-control mr-1"
                      placeholder="CFT"
                      aria-label="Minimum"
                      onChange={inputEvent}
                      name="cft"
                      value={ratelistData.cft}
                    />

                    <input
                      type="text"
                      className="form-control"
                      placeholder="LBH*CFT Divide By"
                      aria-label="Maximum"
                      onChange={inputEvent}
                      name="divisor"
                      value={ratelistData.divisor}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>
                    Enter Date <span style={{ color: "red" }}>*</span>
                  </span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">From</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={inputEvent}
                      name="fromdate"
                      value={ratelistData.fromdate}
                    />
                    <span className="input-group-text ml-1">To</span>
                    <input
                      type="date"
                      className="form-control"
                      onChange={inputEvent}
                      name="todate"
                      value={ratelistData.todate}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Chargeable Weight</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">Kg</span>
                    <input
                      type="text"
                      className="form-control mr-1"
                      placeholder="Minimum"
                      aria-label="Minimum"
                      onChange={inputEvent}
                      name="min_Weight"
                      value={ratelistData.min_Weight}
                    />

                    <input
                      type="text"
                      className="form-control"
                      placeholder="Maximum"
                      aria-label="Maximum"
                      onChange={inputEvent}
                      name="max_Weight"
                      value={ratelistData.max_Weight}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Freight Charges</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Minimum Freight"
                      aria-label="Minimum Freight"
                      onChange={inputEvent}
                      name="min_Freight"
                      value={ratelistData.min_Freight}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Docket Charges</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Docket Charges"
                      aria-label="Docket"
                      onChange={inputEvent}
                      name="docket_Charge"
                      value={ratelistData.docket_Charge}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>FOV Charges</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Minimum FOV"
                      aria-label="Minimum FOV"
                      onChange={inputEvent}
                      name="min_FOV"
                      value={ratelistData.min_FOV}
                    />
                    <span className="input-group-text ml-1">%</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="FOV (in %)"
                      aria-label="FOV"
                      onChange={inputEvent}
                      name="actual_FOV"
                      value={ratelistData.actual_FOV}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Green Tax</span>
                  <div className="input-group mb-3 ">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control mr-1"
                      placeholder="Minimum Green Tax"
                      aria-label="Minimum Green"
                      onChange={inputEvent}
                      name="minGreenTax"
                      value={ratelistData.minGreenTax}
                    />
                    <span className="input-group-text ">Per kg</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Green Tax per kg"
                      aria-label="Green"
                      onChange={inputEvent}
                      name="greenTaxperkg"
                      value={ratelistData.greenTaxperkg}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Outer Delivery Area (ODA1)</span>
                  <div className="input-group mb-3 ">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control mr-1"
                      placeholder="Minimum ODA1"
                      aria-label="Minimum ODA1"
                      onChange={inputEvent}
                      name="min_ODA"
                      value={ratelistData.min_ODA}
                    />
                    <span className="input-group-text ">Per kg</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ODA1 per kg"
                      aria-label="ODA1"
                      onChange={inputEvent}
                      name="actual_ODA"
                      value={ratelistData.actual_ODA}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Outer Delivery Area (ODA2)</span>
                  <div className="input-group mb-3 ">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control mr-1"
                      placeholder="Minimum ODA2"
                      aria-label="Minimum ODA2"
                      onChange={inputEvent}
                      name="min_ODA2"
                      value={ratelistData.min_ODA2}
                    />
                    <span className="input-group-text ">Per kg</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ODA2 per kg"
                      aria-label="ODA2"
                      onChange={inputEvent}
                      name="actual_ODA2"
                      value={ratelistData.actual_ODA2}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>FSC</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">%</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="FSC"
                      aria-label="FSC"
                      onChange={inputEvent}
                      name="fsc"
                      value={ratelistData.fsc}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>GST</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">%</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="GST"
                      aria-label="GST"
                      onChange={inputEvent}
                      name="gst"
                      value={ratelistData.gst}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <span>Cash On Delivery (COD)</span>
                  <div className="input-group mb-3">
                    <span className="input-group-text">&#8377;</span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="COD"
                      aria-label="COD"
                      onChange={inputEvent}
                      name="cod"
                      value={ratelistData.cod}
                    />
                  </div>
                </div>

                <span>Handling Charges</span>
                {inputList.map((x, i) => {
                  return (
                    <div className="border-bottom mb-3 d-md-flex  d-block p-0">
                      <div className="col-md-6 p-0">
                        <div className="col-12">
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="From"
                              aria-label="From"
                              onChange={(e) => handleInputChange(e, i)}
                              value={x.from}
                              name="from"
                            />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="To"
                              aria-label="To"
                              name="to"
                              onChange={(e) => handleInputChange(e, i)}
                              value={x.to}
                            />
                            <select
                              className="form-select"
                              onChange={(e) => handleInputChange(e, i)}
                              name="unit"
                              value={x.unit}
                            >
                              {unit.map((val, i) => {
                                return <option key={i}>{val}</option>;
                              })}
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 d-flex">
                        <div className="input-group mb-3">
                          <span className="input-group-text">&#8377;</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={`Rate per KG`}
                            aria-label="Rate"
                            onChange={(e) => handleInputChange(e, i)}
                            value={x.rate}
                            name="rate"
                          />
                        </div>

                        {inputList.length !== 1 && (
                          <span
                            className="fas fa-times-circle btn text-danger ml-2 p-0 p-lg-auto"
                            onClick={() => handleRemoveClick(i)}
                          ></span>
                        )}

                        {inputList.length - 1 === i && (
                          <span
                            style={{
                              transform: "rotate(45deg)",
                            }}
                            className="fas fa-times-circle btn text-success  p-0 p-lg-auto"
                            onClick={() => handleAddClick(i)}
                          ></span>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </form>
            <table className="table table-responsive d-none" id="table-to-xls">
              <thead>
                <tr>
                  <th>From/To</th>
                  {tabldata.length === 0 ? zoneTHead : columns}
                </tr>
              </thead>
              <tbody>{tabldata.length === 0 ? zoneTbody : tabldata}</tbody>
            </table>

            <div className="row mt-3">
              <div className="col">
                <DynamicTable headers={headers} data={items} />
              </div>
            </div>
            <div className="text-end">
              <input
                type="button"
                className="btn btn-success  mb-5 mt-3 text-center"
                onClick={saveData}
                value="Save & Submit"
              />
            </div>
          </div>
        </div>
        <ToastContainer />
      </>
    );
  }
};

export default ExcelUpload;
