import * as React from "react";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  GetOrderList,
  GetSaleOrderList,
  GetPurchaseOrderList,
  GetAllOrderList,
} from "../Services/AdminApi";
import { VendorOrderList } from "../Services/VendorApi";
import { react } from "fontawesome";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { useLocation } from "react-router";
import { saveAs } from "file-saver";
import { CurrentUrl } from "../Services/UrlApi";
function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
      {/* <div>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Export
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>Download CSV</MenuItem>
          <MenuItem onClick={handleClose}>Download PDF</MenuItem>
        </Menu>
      </div> */}
    </GridToolbarContainer>
  );
}

function NoRowsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fas fa-boxes"></i>
      <p>No order data available</p>
    </Stack>
  );
}

function NoResultsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fal fa-list"></i>
      <p>No results in DataGrid</p>
    </Stack>
  );
}

export default function OrdersList() {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [status, setStatus] = useState("");

  const columns = [
    { field: "order_id", headerName: "Order No." },
    // { field: "customername", headerName: "Customer Name" , width: 200},
    // { field: "customerphone", headerName: "Customer Phone" },
    // { field: "customeremail", headerName: "Customer Email" , width: 220},
    { field: "frompincode", headerName: "From Pin" },
    { field: "topincode", headerName: "To Pin" },
    // { field: "chargable_weight", headerName: "Chargable Weight", width: 200 },
    { field: "taxableAmount", headerName: "Taxable Amt.", width: 150 },
    { field: "gst_Amount", headerName: "GST Amt.", width: 150 },
    { field: "total_Amount", headerName: "Total Amount", width: 150 },
    { field: "pickup_address", headerName: "Pickup Address", width: 400 },
    {
      field: "pickup_person_name",
      headerName: "Pickup Person Name",
      width: 200,
    },
    {
      field: "pickup_person_contact",
      headerName: "Pickup Person No.",
      width: 150,
    },
    { field: "delivery_address", headerName: "Delivery Address", width: 400 },
    {
      field: "delivery_person_name",
      headerName: "Delivery Person Name",
      width: 200,
    },
    {
      field: "delivery_person_contact",
      headerName: "Delivery Person No.",
      width: 150,
    },
    {
      field: "customer_invoice_no",
      headerName: "Customer Invoice No.",
      width: 200,
    },

    // { field: "order_Status", headerName: "Status" },
    {
      field: "iteminvoice",
      headerName: "Item Invoice",
      width: 150,
      renderCell: (params) => {
        return (
          <Box className="d-flex">
            <InvoiceItems invoicePath={params.row.customer_invoice_path} />
          </Box>
        );
      },
    },
  ];

  const InvoiceItems = (props) => {
    const [invoiceopen, setInvoiceOpen] = React.useState(false);
    const handleInvoiceOpen = () => {
      setInvoiceOpen(true);
    };

    const handleInvoiceClose = () => {
      setInvoiceOpen(false);
    };

    const imagedown = (params) => {
      let url = `${CurrentUrl}${props.invoicePath}`;
      saveAs(url, "Order invoice");
    };

    return (
      <Box>
        <IconButton onClick={handleInvoiceOpen}>
          {" "}
          <RemoveRedEyeRoundedIcon />
        </IconButton>
        <Dialog
          className="fullScreen-box"
          fullScreen
          open={invoiceopen}
          onClose={handleInvoiceClose}
        >
          <DialogContent style={{ textAlign: "center" }}>
            {/* <img src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg" /> */}
            <iframe
              className="iframe-data"
              width={"100%"}
              height={"100%"}
              src={`${CurrentUrl}${props.invoicePath}`}
            >
              {" "}
            </iframe>
          </DialogContent>
          <DialogActions>
            <Button onClick={imagedown}>Download</Button>
            <Button onClick={handleInvoiceClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  };

  const myParam = useLocation().search;
  const order_Status = new URLSearchParams(myParam).get("order_Status");

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setData([]);
    var params = "";

    if (order_Status) {
      setStatus(order_Status);
    }
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (
      order_Status != null &&
      order_Status != undefined &&
      order_Status != ""
    ) {
      params += `status=${order_Status}&`;
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    VendorOrderList(localStorage.getItem("user_email"), params)
      .then((result) => {
        setData(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Order List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">My Payments</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                required
                fullWidth
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-required"
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel
                  id="Status"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                  labelId="Status"
                  id="Status"
                  fullWidth
                  label="Status"
                >
                  <MenuItem value="">
                    <em>Status</em>
                  </MenuItem>
                  <MenuItem value={"Booked"}>Booked </MenuItem>
                  <MenuItem value={"Intransit"}>In-Transit </MenuItem>
                  <MenuItem value={"RTO"}>RTO</MenuItem>
                  <MenuItem value={"Delivered"}>Delivered</MenuItem>
                  <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="">
              <IconButton
                size="small"
                className="search-icon"
                onClick={() => {
                  loadData();
                }}
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
            <div style={{ height: "400px" }}>
              <DataGrid
                sx={{
                  p: "1rem",
                  "@media print": {
                    ".table-ui": { border: "none" },
                  },
                }}
                className="table-ui"
                disableColumnMenu
                disableDensitySelector
                getRowId={(row) => row.id ?? row.order_id}
                rows={data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  Toolbar: CustomToolbar,
                  NoRowsOverlay,
                  NoResultsOverlay,
                }}
              />
            </div>
          </Card>
        </div>
      </section>
    </div>
  );
}
