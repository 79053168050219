import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";
import { Getalluser, Updateuser } from '../Services/AdminApi';
import EditIcon from '@mui/icons-material/Edit';
import Loading from "./Loading";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import { MobileStepper, Modal, Box, MenuItem, InputLabel, Select, FormControl, TextField, Button } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,

  p: 4,
};
const monthlydata = ["Setting up a new business",
  "Between 1-10 orders/ Few personal shipments",
  "11-100 orders",
  "101-1000 orders",
  "1001-5000 orders",
  "More than 5000 orders"
]
const companydescdata = ["Direct to Consumer brand",
  "Trader/ Wholesaler/ Reseller",
  "Social seller selling on Whatsapp, Facebook, Instagram",
  "Services sending documents",
  "Individual sending a few personal shipments",
  "Others"]
const avgshipmentdata = ["Less than 1 kg"
  , "1 to 5"
  , "5 to 50"
  , "More than 50"]
const steps =
{
  0: {
    input: monthlydata.map((val) => { return <MenuItem value={val}>{val}</MenuItem> })
    ,
    label: 'How many orders do you ship in a month ?', value: 'monthlyshipping'
  }


  ,
  1: {
    input:
      companydescdata.map((val) => { return <MenuItem value={val}>{val}</MenuItem> })
    ,
    label: 'Which of the following best describes your company or need ?', value: 'company_desc'
  }

  ,
  2: {
    input: <>

    </>
    ,
    label: 'Items you deal in', value: 'deal_item'
  },
  3: {
    input: avgshipmentdata.map((val) => { return <MenuItem value={val}>{val}</MenuItem> })
    ,
    label: 'Average weight of the shipment', value: 'avg_wt_of_shipment'
  },
  4: {
    input: <></>
    ,
    label: 'Credits', value: 'credit'
  }
}

const UserDetail = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [rowdata, setRowData] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [additionUserData, setAdditionUserData] = useState({
    monthlyshipping: "",
    company_desc: "",
    deal_item: "",
    avg_wt_of_shipment: "", credit: '',
    email: "",
  })
  const { type } = useParams();
  const Ucolumnname = [
    { field: "name", headerName: "Trade Name", width: 150 },
    { field: "contact_person_name", headerName: "Contact Person Name", width: 150 },
    { field: "mobile_no", headerName: "Mobile No.", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "business_Address", headerName: "Address", width: 150 },
    { field: "pan", headerName: "PAN No.", width: 150 },
    { field: "GST", headerName: "GST No.", width: 150 },
    { field: "avg_wt_of_shipment", headerName: "Avg. Shipment Wt.", width: 150 },
    { field: "creditlimit", headerName: "Credit Limit", width: 150 },
    { field: "deal_item", headerName: "Deals In", width: 150 },
    { field: "monthlyshipping", headerName: "No. of shipment (Monthly)", width: 150 },
    {
      field: "action", headerName: "Action", width: 150, renderCell: (params) => {

        return (<IconButton><EditIcon
          onClick={() => openModal(params.row.email)} /></IconButton>)
      }
    },]
  const Vcolumnname = [
    { field: "name", headerName: "Trade Name", width: 150 },
    { field: "contact_person_name", headerName: "Contact Person Name", width: 150 },
    { field: "mobile_no", headerName: "Mobile No.", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "business_Address", headerName: "Address", width: 150 },
    { field: "pan", headerName: "PAN No.", width: 150 },
    { field: "GST", headerName: "GST No.", width: 150 },
  ]
  const openModal = (email) => {
    setOpen(true)
    setAdditionUserData({ ...additionUserData, 'email': email })
  }
  useEffect(() => {
    setLoading(true)
    Getalluser(type).then((res) => {
      setLoading(false)
      if (res.status !== 200) {
        setRowData([])
      } else {

        const list = [];
        res.data.map((val) => {
          list.push({
            id: val.id,
            account_no: val.account_no,
            avg_wt_of_shipment: val.avg_wt_of_shipment,
            bank_name: val.bank_name,
            benifesory_name: val.benifesory_name,
            business_Address: val.business_Address,
            c_Address: val.c_Address,
            company_desc: val.company_desc,
            contact_person_name: val.contact_person_name,
            creditlimit: val.creditlimit,
            deal_item: val.deal_item,
            email: val.email,
            gst: val.gst,
            id: val.id,
            ifsc_code: val.ifsc_code,
            mobile_no: val.mobile_no,
            monthlyshipping: val.monthlyshipping,
            name: val.name,
            pan: val.pan,

          })

        });
        setRowData(list);
      }
    })
  }, [])
  const handleNext = () => {
    if (activeStep === 4) {
      setActiveStep(0)

      Updateuser(additionUserData).then((res) => { console.log(res) })
    }
    else
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  if (loading)
    return (<Loading />)
  else {
    return (
      <div className="content-wrapper">
        <div className="d-flex justify-content-between align-items-center wrap-flex p-3 ">
          <h3>{type} Details</h3>
        </div>

        <>

          <div style={{ height: '500px', width: '100%' }}>
            <DataGrid rows={rowdata} sx={{ padding: '30px' }} components={{ Toolbar: GridToolbar }}
              columns={type === 'User' ? Ucolumnname : Vcolumnname} /></div></>
        <Modal
          open={open}

          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box component="div" sx={{ display: 'flex', justifyContent: 'end' }}><IconButton>
              <CloseIcon onClick={() => setOpen(false)} /></IconButton></Box>
            <span >{steps[activeStep].label} </span>
            <FormControl fullWidth style={{ margin: '20px 0px' }}>
              {activeStep === 2 ? <TextField id="outlined-basic" label="Items Name" value={additionUserData[steps[activeStep].value]} variant="outlined" onChange={(e) => { setAdditionUserData({ ...additionUserData, [steps[activeStep].value]: e.target.value }) }} /> :
                activeStep === 4 ? <TextField id="outlined-basic" label="Credit" value={additionUserData[steps[activeStep].value]} variant="outlined" onChange={(e) => {
                  if (!isNaN(e.target.value))
                    setAdditionUserData({ ...additionUserData, [steps[activeStep].value]: e.target.value })
                }} /> : <>
                  <InputLabel id="demo-simple-select-label">Select</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={additionUserData[steps[activeStep].value]}
                    label="Select"
                    onChange={(e) => { setAdditionUserData({ ...additionUserData, [steps[activeStep].value]: e.target.value }) }}
                  > {steps[activeStep].input}

                  </Select></>}
            </FormControl>

            <MobileStepper
              variant="dots"
              steps={5}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1 }}
              nextButton={
                <Button size="small" onClick={handleNext}>
                  {activeStep === 4 ? "Finish" : "Next"}
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
        </Modal>
      </div>


    )
  }
}

export default UserDetail