import * as React from "react";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
// import { toWords } from 'num-to-words';
import { ToWords } from "to-words";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

import html2canvas from "html2canvas";
import { GetPaymentList } from "../Services/UserApi";
import { useState, useEffect } from "react";
import "./pdf.css";
import jsPDF from "jspdf";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
      {/* <div>
        <Button
          id="fade-button"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          startIcon={<FileDownloadOutlinedIcon />}
        >
          Export
        </Button>
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem onClick={handleClose}>Download CSV</MenuItem>
          <MenuItem onClick={handleClose}>Download PDF</MenuItem>
        </Menu>
      </div> */}
    </GridToolbarContainer>
  );
}

export default function MyPayments() {
  function pdfhandle(params) {
    setPaymentdata(params.row);
    window.html2canvas = html2canvas;

    var doc = new jsPDF("p", "px", [790, 850]);

    var content = document.getElementById("pdf-content");
    document.getElementById("pdf-content").style.display = "block";
    doc.html(content, {
      callback: function (doc) {
        doc.save("Payment");
        document.getElementById("pdf-content").style.display = "none";
      },
    });
  }

  const columns = [
    // { field: "sno", headerName: "S.No", width: 100},
    { field: "payment_Type", headerName: "Type" },

    // { field: "paymentId", headerName: "Payment ID ", flex: 1 },
    { field: "admin_name", headerName: " Payment To", flex: 1 },
    { field: "lP_Name", headerName: "Service From", flex: 1 },
    // { field: "rate", headerName: "Rate", flex: 1 },
    { field: "taxable_Amount", headerName: "Taxable Amount", flex: 1 },
    // { field: "gSt", headerName: "GST", flex: 1 },
    { field: "total_Amount", headerName: "Total Amount", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "download",
      headerName: "Download",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={() => {
              pdfhandle(params);
            }}
            aria-label="delete"
          >
            {" "}
            <SimCardDownloadIcon />
          </IconButton>
        );
      },
    },
  ];

  const [paymentList, setPaymentList] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [paymentdata, setPaymentdata] = useState([]);
  const [status, setstatus] = useState("");

  useEffect(() => {
    loadCustomerPayments();
  }, []);

  const loadCustomerPayments = () => {
    setPaymentList([]);

    var params = "";
    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }
    if (status != null && status != undefined && status != "") {
      params += `status=${status}&`;
    }
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    GetPaymentList(localStorage.getItem("user_email"), params)
      .then((result) => {
        result.data.sort(
          (a, b) => new Date(b.payment_Date) - new Date(a.payment_Date)
        );
        setPaymentList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">My Payments</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">My Payments</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                // required
                fullWidth
                id="outlined-required"
                label="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel
                  id="Status"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  onChange={(e) => setstatus(e.target.value)}
                  labelId="Status"
                  id="Status"
                  fullWidth
                  label="Status"
                >
                  {/* <MenuItem value="">
                    <em>All</em>
                  </MenuItem> */}
                  <MenuItem value={"Success"}>Success</MenuItem>
                  <MenuItem value={"Pending"}>Pending</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>

            <div className="">
              <IconButton
                onClick={() => {
                  loadCustomerPayments();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3" id="custum-shadow mt-3">
            <DataGrid
              autoHeight
              sx={{
                p: "1rem",
                "@media print": {
                  ".table-ui": { border: "none" },
                },
              }}
              className="table-ui"
              disableDensitySelector
              getRowId={(row) => row.id ?? row.paymentId}
              rows={paymentList}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
              components={{
                Toolbar: CustomToolbar,
              }}
            />
          </Card>
        </div>
      </section>

      <div id="pdf-content" style={{ display: "none" }}>
        <div
          className="page"
          style={{ fontFamily: "Arial, Helvetica, sans-serif" }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "separate",
              borderSpacing: "0px 20px",
            }}
          >
            <tr>
              <td style={{ width: "10%" }}>
                <img src="images/logo-pdf.png" style={{ width: "140px" }} />
              </td>
              <td
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#F00",
                  textAlign: "center",
                }}
              >
                ADVANCE RECEIPT VOUCHER
              </td>
            </tr>
          </table>
          <table style={{ width: "100%", fontSize: "14px" }}>
            <tr>
              <td
                colspan="3"
                style={{ fontSize: "18px", fontWeight: "600", color: "#F00" }}
              >
                {paymentdata.admin_name}
              </td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td>
                <p style={{ width: "200px", margin: 0 }}>
                  {paymentdata.admin_address}
                </p>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>GSTIN:</td>
                    <td>{paymentdata.admin_gst}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>State:</td>
                    <td> {paymentdata.admin_state}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>PAN:</td>
                    <td>{paymentdata.admin_Pan}</td>
                  </tr>
                </table>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Payment Date:</td>
                    <td>{paymentdata.paymentDate}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Payment No.:</td>
                    <td>{paymentdata.paymentId}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Reference No.:</td>
                    <td>-</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr>
              <td
                colspan="3"
                style={{ background: "#f00", padding: "0", height: "1px" }}
              ></td>
            </tr>
            <tr>
              <td colspan="3"></td>
            </tr>
            <tr style={{ verticalAlign: "top" }}>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Customer Name</td>
                  </tr>
                  <tr>
                    <td>{paymentdata.custname}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Customer GSTIN:</td>
                  </tr>
                  <tr>
                    <td>{paymentdata.cust_gstin}</td>
                  </tr>
                </table>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Billing Address</td>
                  </tr>
                  <tr>
                    <td>
                      {paymentdata.cBillingaddress}
                      {/* <br />
                      DELHI
                      <br />
                      Delhi, 121006 */}
                    </td>
                  </tr>
                </table>
              </td>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Shipping Address</td>
                  </tr>
                  <tr>
                    <td>
                      {paymentdata.cShippingaddress}
                      {/* <br />
                      DELHI
                      <br />
                      Delhi, 121006 */}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style={{ background: "#f00", padding: "0", height: "1px" }}
              ></td>
            </tr>
            <tr>
              <td>
                <table style={{ width: "100%" }}>
                  <tr>
                    <td style={{ fontWeight: "600" }}>Place of Supply:</td>
                    <td> {paymentdata.placeOfsupply}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                style={{ background: "#f00", padding: "0", height: "1px" }}
              ></td>
            </tr>
          </table>

          <table
            className="item-table"
            style={{
              width: "100%",
              fontSize: "14px",
              textAlign: "left",
              border: "1px solid #c9c6a5",
              borderCollapse: "separate",
              borderSpacing: "0px",
            }}
          >
            <thead style={{ background: "#fffac3" }}>
              <tr>
                <th></th>
                <th>Item</th>
                <th>Quantity</th>
                <th>Rate/Item(₹)</th>
                <th>Taxable Value(₹)</th>
                <th style={{ borderLeft: "1px solid #c9c6a5" }}>GST(₹)</th>
                <th>Total(₹)</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ verticalAlign: "top" }}>
                <td>1.</td>
                <td style={{ textAlign: "center" }}>
                  <p
                    style={{ margin: "0px", width: "100px", fontWeight: "500" }}
                  >
                    {paymentdata.item}
                  </p>
                  <span style={{ fontSize: "12px", display: "block" }}>
                    - As per Annexure
                  </span>
                </td>

                <td style={{ textAlign: "center" }}>1.00 </td>
                <td style={{ textAlign: "center" }}>{paymentdata.rate}</td>
                <td style={{ textAlign: "center" }}>
                  {paymentdata.taxable_Amount}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid #c9c6a5",
                    textAlign: "center",
                  }}
                >
                  {paymentdata.gstAmount}
                  <span style={{ fontSize: "12px", display: "block" }}>
                    @18%
                  </span>
                </td>

                <td style={{ textAlign: "center" }}>
                  {paymentdata.total_Amount}
                </td>
              </tr>
            </tbody>
            <tfoot
              style={{
                background: "#fffac3",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              <tr>
                <td colspan="4" align="center">
                  Total
                </td>
                <td style={{ textAlign: "center" }}>
                  {paymentdata.taxable_Amount}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid #c9c6a5",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {paymentdata.gstAmount}
                </td>

                <td style={{ textAlign: "center" }}>
                  {paymentdata.total_Amount}
                </td>
              </tr>
            </tfoot>
          </table>

          <table
            className="table-3"
            style={{
              width: "100%",
              fontSize: "14px",
              textAlign: "right",
              borderCollapse: "separate",
              borderSpacing: "0px 10px",
              fontSize: "15px",
            }}
          >
            <tr>
              <td>Taxable Amount: {paymentdata.taxable_Amount}</td>
            </tr>
            <tr>
              <td>Total Tax: {paymentdata.gstAmount}</td>
            </tr>
            <tr>
              <td style={{ fontSize: "20px", fontWeight: "800" }}>
                Total Value: {paymentdata.total_Amount}
              </td>
            </tr>
            <tr>
              <td>Total amount (in words): {paymentdata.total_Amount}</td>
            </tr>
          </table>
          <table
            className="table-4"
            style={{
              width: "100%",
              fontSize: "13px",
              textAlign: "left",
              borderCollapse: "separate",
              borderSpacing: "0px 5px",
              marginTop: "50px",
            }}
          >
            <tr>
              <td style={{ width: "50%" }}></td>
              <td style={{ verticalAlign: "top", width: "50%" }}>
                <table
                  style={{
                    width: "100%",
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  <tr>
                    <td>For MVIKAS SUPER LOGISTICS PRIVATE LIMITED</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        borderBottom: "2px solid #000",
                        height: "100px",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td>Authorised Signatory</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
