import React, { useEffect, useState, useCallback } from "react";
import { GetOrderdata } from "../Services/OrderApi";
import Loading from "./Loading";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { forwardRef } from "react";
import RatingPreview from "./RatingPreview";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Modalbreakpoints from "./Modalbreakpoint";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import ReceiptIcon from "@mui/icons-material/Receipt";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip, Button } from "@mui/material";
import Invoice from "./Invoice";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const OrderView = () => {
  const [columnname, setColumnName] = useState([]);
  const [rowdata, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const role = localStorage.getItem("user_role").split(",");

  var curr = new Date();
  curr.setDate(curr.getDate() - 7);
  var todaydate = curr.toISOString().substr(0, 10);
  curr.setDate(curr.getDate() + 7);
  var nextday = curr.toISOString().substr(0, 10);
  const [data, setData] = useState({
    fromdate: todaydate,
    todate: nextday,
  });
  const [modal, setModal] = useState();
  const [indx, setIndx] = useState("");

  const Bindtable = () => {
    debugger;
    let count = 0;
    GetOrderdata(localStorage.getItem("user_id"), data)
    .then((res) => {
      debugger;
      setLoading(false);
      const list = [];
      res.data.map((val) => {
        list.push({
          order_id: val.order_id,
          lpname: val.lpname,
          frompincode: val.frompincode,
          topincode: val.topincode,
          chargable_weight: val.chargable_weight,
          taxableAmount: val.taxableAmount,
          gst_Amount: val.gst_Amount,
          totalAmount: val.totalAmount,
          insertdate: val.insertdate
            .toString()
            .substring(0, 10)
            .split("-")
            .reverse()
            .join("-"),
          returnaddress: val.returnaddress === null ? "" : val.returnaddress,
          deliveryaddress:
            val.deliveryaddress === null ? "" : val.deliveryaddress,
          pickupaddress: val.pickupaddress === null ? "" : val.pickupaddress,
          itemname: val.itemname,
          action: (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => {
                  modalView(0, val.order_id);
                }}
              >
                <RemoveRedEyeIcon />
              </button>
              <Tooltip title="Download Invoice" arrow>
                <Button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    modalView(2, val.order_id);
                  }}
                >
                  <ReceiptIcon />
                </Button>
              </Tooltip>
              {val.rating === 0 || val.rating === null ? (
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    modalView(1, val.order_id);
                  }}
                >
                  Rating
                </button>
              ) : (
                <></>
              )}
            </div>
          ),
          customername: val.customername,
          customerphone: val.customerphone,
          customeremail: val.customeremail,
          cft: val.cft,
          chargable_weight: val.chargable_weight,
          codAmount: val.codAmount,
          customeremail: val.customeremail,
          customername: val.customername,
          customerphone: val.customerphone,
          docketcharge: val.docketCharge,
          fovPercentage: val.fovPercentage,
          fov_amount: val.fov_amount,
          fsc_amount: val.fsc_amount,
          fsc_percent: val.fsc_percent,
          greentax: val.greentax,
          greentax_amount: val.greentax_amount,
          gst: val.gst,
          gst_Amount: val.gst_Amount,
          handlingCharge: val.handlingCharge,
          minFOV: val.minFOV,
          minFreight: val.minFreight,
          min_Chargable_weight: val.min_Chargable_weight,
          oda_amount: val.oda_amount,
          rate: val.rate,
          shipment_value: val.shipment_value,
          shipment_weight: val.shipment_weight,
          taxableAmount: val.taxableAmount,
          totalAmount: val.totalAmount,
          deliveryaddressmobile: val.deliveryaddressmobile,
          deliveryaddressname: val.deliveryaddressname,
          pickupaddressmobile: val.pickupaddressmobile,
          pickupaddressname: val.pickupaddressname,
          insurance: val.insurance ? "Yes" : "No",
          paymentmode: val.paymentmode,
          pickuptime: val.pickuptime,
          boxes: val.boxes,
        });
        count++;
      });

      setRowData(list);
      if (role.includes("Vendor")) {
        setColumnName([
          { field: "order_id", title: "Order No." },
          { field: "customername", title: "Customer Name" },
          { field: "customerphone", title: "Customer Phone" },
          { field: "customeremail", title: "Customer Email" },
          { field: "frompincode", title: "From Pin" },
          { field: "topincode", title: "To Pin" },
          { field: "shipment_weight", title: "Gross Wt." },
          { field: "chargable_weight", title: "Chargable Wt." },
          { field: "taxableAmount", title: "Taxable Amt." },
          { field: "gst_Amount", title: "GST Amt." },
          { field: "totalAmount", title: "Total Amt." },
          { field: "pickupaddressname", title: "Pickup Name" },
          { field: "pickupaddressmobile", title: "Pickup Mobile" },
          { field: "pickupaddress", title: "Pickup Address" },
          { field: "deliveryaddressname", title: "Delivery Name" },
          { field: "deliveryaddressmobile", title: "Delivery Mobile" },
          { field: "deliveryaddress", title: "Delivery Address" },
          { field: "returnaddress", title: "Return Address" },
          { field: "itemname", title: "Item Type" },
          { field: "boxes", title: "No. of Boxes" },
          { field: "paymentmode", title: "Payment Mode" },
          { field: "pickuptime", title: "Pickup Slot" },
          { field: "insurance", title: "Insured" },
          { field: "insertdate", title: "Order Date" },
          { field: "action", title: "Action" },
        ]);
      } else {
        setColumnName([
          { field: "order_id", title: "Order No." },
          { field: "lpname", title: "LP Name" },

          { field: "frompincode", title: "From Pin" },
          { field: "topincode", title: "To Pin" },
          { field: "shipment_weight", title: "Gross Wt." },
          { field: "chargable_weight", title: "Chargable Wt." },
          { field: "taxableAmount", title: "Taxable Amt." },
          { field: "gst_Amount", title: "GST Amt." },
          { field: "totalAmount", title: "Total Amt." },
          { field: "pickupaddressname", title: "Pickup Name" },
          { field: "pickupaddressmobile", title: "Pickup Mobile" },
          { field: "pickupaddress", title: "Pickup Address" },
          { field: "deliveryaddressname", title: "Delivery Name" },
          { field: "deliveryaddressmobile", title: "Delivery Mobile" },
          { field: "deliveryaddress", title: "Delivery Address" },
          { field: "returnaddress", title: "Return Address" },
          { field: "itemname", title: "Item Type" },
          { field: "boxes", title: "No. of Boxes" },
          { field: "paymentmode", title: "Payment Mode" },
          { field: "pickuptime", title: "Pickup Slot" },
          { field: "insurance", title: "Insured" },
          { field: "insertdate", title: "Order Date" },
          { field: "action", title: "Action" },
        ]);
      }
    });
  };
  useEffect(() => {
    setLoading(true);
    debugger
    Bindtable();
  }, []);

  const modalView = (i, order_id) => {
    setIndx(i);
    setModal(
      i === 0 ? (
        <Modalbreakpoints data={data} orderid={order_id} />
      ) : i === 2 ? (
        <>
          <Invoice orderid={order_id} />
        </>
      ) : (
        <RatingPreview order_id={order_id} closeModal={closeModal} />
      )
    );
  };

  const closeModal = useCallback(() => {
    document.getElementById("closemodal").click();
    Bindtable();
    toast.success("Thanks for your ratings");
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="container px-1 px-lg-5">
          <div className=" d-block d-lg-flex justify-content-between align-items-center wrap-flex my-3 ">
            <h3> View Order</h3>{" "}
          </div>
          <div className="input-group mb-3">
            <span className="input-group-text">From</span>
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setLoading(true);
                setData({ ...data, [e.target.name]: e.target.value });
                GetOrderdata(localStorage.getItem("user_id"), {
                  fromdate: e.target.value,
                  todate: data.todate,
                }).then((res) => {
                  console.log(res);
                  setLoading(false);
                });
                Bindtable();
              }}
              name="fromdate"
              value={data.fromdate}
            />
            <span className="input-group-text ml-1">To</span>
            <input
              type="date"
              className="form-control"
              onChange={(e) => {
                setLoading(true);
                setData({ ...data, [e.target.name]: e.target.value });
                GetOrderdata(localStorage.getItem("user_id"), {
                  fromdate: data.fromdate,
                  todate: e.target.value,
                }).then((res) => {
                  setLoading(false);
                });
                Bindtable();
              }}
              name="todate"
              value={data.todate}
            />
          </div>

          <div className="p-3">
            <MaterialTable
              title="Orders"
              icons={tableIcons}
              columns={columnname}
              data={rowdata}
              options={{
                exportButton: true,
                columnsButton: true,
              }}
            />
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                {indx === 2 ? "Invoice" : "Breakups"}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closemodal"
                onClick={() => setModal("")}
              ></button>
            </div>
            <div class="modal-body">{modal} </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default OrderView;
