import React, { useEffect, useState } from "react";
import {
  GetAllService,
  UploadtatData,
  Getzonesbyservice,
} from "../Services/ServiceApi";
import DynamicTable from "./DynamicTable";
import { FormControlLabel, Button, Radio, RadioGroup } from "@mui/material";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";

const Uploadtat = () => {
  const [loading, setLoading] = useState(true);
  const [allzonenm, setAllzonenm] = useState([]);
  const [tattable, settattable] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [allservice, SetAllService] = useState([]);
  const [items, setItems] = useState([]);

  const [Uploadtatdata, setUploadtatdata] = useState({
    lpserviceid: 0,
    ratelist: [],
  });

  const [tabldata, setTabledata] = useState([]);

  const [oda, setoda] = useState("");
  
  const head = ["name"];
  useEffect(() => {
    BindallService();
  }, []);

  const BindallService = () => {
    GetAllService().then((res) => {
      setLoading(false);
      SetAllService(res);
    });
  };
  const Bindtabledata = (list, ratesdata) => {
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };

  const ClearFile = () => {
    document.getElementById("UploadtatBTn2").value = "";
  };

  const inputEvent = (e) => {
    setUploadtatdata({ ...Uploadtatdata, lpserviceid: e.target.value });
    Getzonesbyservice(e.target.value).then((res) => {
      console.log(res);
      setAllzonenm(res.data.map((val) => val.name));

      setLoading(false);
      document.getElementById("test-table-xls-button").click();
      ///document.getElementById("messagemodal").click();
    });
  };

  const readExcel = (e) => {
    const file = e.target.files[0];
    const val = file.name.toLowerCase(),
      regex = new RegExp("(.*?).(xlsx)$");
    if (!regex.test(val)) {
      toast.warn("Please select .XLSX file format");
    } else {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });

          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { defval: 0 });

          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      });

      promise.then((d) => {
        d.map((val) => {});
        setUploadtatdata({
          ...Uploadtatdata,
          ["ratelist"]: d.map((val) => {
            return JSON.stringify(val);
          }),
        });
        setHeaders(Object.keys(d[0]));
        setItems(d);
      });
    }
  };

  // saveUploadtat;

  const saveUploadtat = (e) => {
    e.preventDefault();

    if (
      Uploadtatdata.lpserviceid === 0 ||
      Uploadtatdata.ratelist.length === 0 ||
      oda === ""
    )
      toast.warn("Please Enter all mandatory fields");
    else {
      setLoading(true);
      UploadtatData(Uploadtatdata, oda).then((res) => {
        setLoading(false);

        if (res.status === 200) {
          setItems([]);
          setHeaders([]);
          toast.success("Record saved successfully");
        } else {
          toast.error(res.data);
        }
      });
    }
  };

  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="container px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center  wrap-flex">
              <h3> Upload tat</h3>

              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-shine btn-success d-none"
                table="table-to-xls-tat"
                filename="TatFile"
                sheet="RateList"
                buttonText={<i className="fas fa-download"> Sample Format</i>}
              />
            </div>

            <div className="row px-3">
              <div className="col-md-12 mb-4">
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <span>
                      Select Service Name
                      <span style={{ color: "red" }}>*</span>
                    </span>
                    <select
                      className="form-control"
                      name="serviceid"
                      onChange={inputEvent}
                    >
                      <option selected hidden>
                        Select
                      </option>
                      {!(allservice.length > 0) ? (
                        <option>No Data Found</option>
                      ) : (
                        allservice.map((val, i) => {
                          return (
                            <option key={i} value={val.id}>
                              {val.name}
                            </option>
                          );
                        })
                      )}
                    </select>
                  </div>
                  <div className="col-md-3 mb-4">
                    {" "}
                    <div className="d-flex flex-column">
                      <span>
                        Upload Excel file
                        <span style={{ color: "red" }}>*</span>
                      </span>
                      <input
                        type="file"
                        className="form control"
                        accept=".xlsx"
                        onChange={(e) => {
                          readExcel(e);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className="col-md-5 mb-4 "
                    style={{ display: "contents" }}
                  >
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      row
                    >
                      <FormControlLabel
                        value="Standard"
                        control={
                          <Radio
                            onClick={(e) => {
                              if (e.target.checked) setoda(false);
                            }}
                          />
                        }
                        label="Standard"
                        labelPlacement="Standard"
                      />
                      <FormControlLabel
                        value="ODA"
                        control={
                          <Radio
                            onClick={(e) => {
                              if (e.target.checked) setoda(true);
                            }}
                          />
                        }
                        label="ODA"
                        labelPlacement="ODA"
                      />
                    </RadioGroup>
                  </div>

                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <Button variant="contained" onClick={saveUploadtat}>
                      {" "}
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
              <table
                className="table table-responsive d-none"
                id="table-to-xls-tat"
              >
                <thead>
                  <tr>
                    <th>From/To</th>
                    {allzonenm.map((val) => {
                      return <th>{val}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {allzonenm.map((val) => {
                    return (
                      <tr>
                        <td>{val}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div className="row mt-3">
                <div className="col">
                  <DynamicTable headers={headers} data={items} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer />
      </>
    );
  }
};

export default Uploadtat;
