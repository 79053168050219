import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./Admin.css";
const Sidebar = () => {
  const navigate = useNavigate();
  const locate = useLocation();
  const [activelink, setActiveLink] = useState(locate.pathname.substring(1));
  const role = localStorage.getItem("user_role").split(",");

  const collapseActive = (id) => {
    document.body.classList.remove("sidebar-open");
    document.body.classList.add("sidebar-closed");
    switch (id) {
      case 1:
        navigate("/Dashboard");
        break;
      case 2:
        navigate("/Zone");
        break;
      case 3:
        navigate("/createService");
        break;
      case 4:
        navigate("/mapservicezone");
        break;
      case 5:
        navigate("/uploadoda");
        break;
      case 6:
        navigate("/uploadrate");
        break;
      case 7:
        navigate("/viewrate");
        break;
      case 8:
        navigate("/orderview");
        break;
      case 9:
        navigate("/adminhandler");
        break;
      case 10:
        navigate("/manageaddress");
        break;
      case 11:
        navigate("/settings");
        break;
      case 12:
        navigate("/uploadtat");
        break;
      case 13:
        navigate("/downloadratesheet");
        break;
      case 14:
        navigate("/setting");
        // reloadPage();
        break;
      case 15:
        navigate("/invoices");
        break;
      case 16:
        navigate("/reports");
        break;
      case 17:
        navigate("/invoice");
        break;
      case 18:
        navigate("/vsetting");
        break;
      case 19:
        navigate("/venderlist");
        break;
      case 20:
        navigate("/userdetails");
        break;
      case 21:
        navigate("/myorder");
        break;
      case 22:
        navigate("/mypayments");
        break;
      case 23:
        navigate("/orderslist");
        break;
      case 24:
        navigate("/customersdetails");
        break;
      case 25:
        navigate("/pincode");
        break;
      // case 26:
      //   navigate("/admininvoice");
      //   break;
      case 27:
        navigate("/uploadbulkorders");
        break;
      case 28:
        navigate("/PurchaseReport");
        break;
      case 29:
        navigate("/SalesReport");
        break;
      case 26:
        navigate("/salesinvoice");
        break;
      case 30:
        navigate("/purchaseinvoice");
        break;
        case 31:
          navigate("/venderprofile");
          break;
          case 32:
        navigate("/vendorcustomerdetails");
        break;
    }
  };

  document.body.addEventListener("click", () => {
    collapseActive();
  });

  const [isActive, setIsActive] = useState(false);
  const [isActive1, setIsActive1] = useState(false);
  const handleClick = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive((current) => !current);
  };

  const handleClick1 = (event) => {
    // 👇️ toggle isActive state on click
    setIsActive1((current) => !current);
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary">
        {/* Brand Logo */}
        <Link
          to={"/Dashboard"}
          className="brand-link"
          style={{ display: "flex", width: "auto" }}
        >
          <img
            src="./images/logo.svg"
            alt="AdminLTE Logo"
            className="brand-image"
            style={{ opacity: ".8" }}
          />
        </Link>
        {/* Sidebar */}
        <div className="sidebar-scroll">

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon className with font-awesome or any other icon font library */}

              <li
                className={
                  activelink === "Dashboard" ? "nav-item menu-open" : "nav-item"
                }
                id="1"
              >
                <span onClick={(e) => collapseActive(1)} className="nav-link">
                  <i className="fas fa-layer-group "></i>
                  <p>Dashboard</p>
                </span>
              </li>

              {role.includes("Admin") ? (
                <>
                  <li
                    className={
                      activelink === "customersdetails" ||
                      activelink === "nav-item"
                        ? "nav-item menu-open"
                        : "nav-item"
                    }
                    id="24"
                  >
                    <span
                      onClick={(e) => collapseActive(24)}
                      className="nav-link"
                    >
                      <i className="fas fa-user-friends"></i>
                      <p>Customers List</p>
                    </span>
                  </li>
                  <li
                    className={
                      activelink === "venderlist"
                        ? "nav-item menu-open"
                        : "nav-item"
                    }
                    id="19"
                  >
                    <span
                      onClick={(e) => collapseActive(19)}
                      className="nav-link"
                    >
                      {/* <i className="fas fa-rupee-sign "></i> */}
                      <i className="fas fa-users"></i>
                      <p>Vendor List</p>
                    </span>
                  </li>
                  <li
                    className={
                      activelink === "PurchaseReport" ||
                      activelink === "SalesReport" ||
                      isActive
                        ? "nav-item menu-is-opening menu-open"
                        : "nav-item"
                    }
                    onClick={handleClick}
                  >
                    <span className="nav-link">
                      <i className="fas fa-file"></i>
                      <p>Order Report</p>

                      <i className="right fas fa-angle-right arrow-right"></i>
                    </span>
                    <ul className="nav nav-treeview">
                      <li
                        className={
                          activelink === "PurchaseReport" ||
                          activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="28"
                      >
                        <span
                          onClick={(e) => collapseActive(28)}
                          className="nav-link"
                        >
                          <i className="fas fa-boxes"></i>
                          <p>Purchase</p>
                        </span>
                      </li>

                      <li
                        className={
                          activelink === "SalesReport" ||
                          activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="29"
                      >
                        <span
                          onClick={(e) => collapseActive(29)}
                          className="nav-link"
                        >
                          <i className="fas fa-boxes"></i>
                          <p>Sales</p>
                        </span>
                      </li>
                    </ul>
                  </li>


                  <li
                    className={
                      activelink === "purchaseinvoice" ||
                      activelink === "salesinvoice" ||
                      isActive1
                        ? "nav-item menu-is-opening menu-open"
                        : "nav-item"
                    }
                    onClick={handleClick1}
                  >
                    <span className="nav-link">
                      <i className="fas fa-file"></i>
                      <p>Invoice</p>

                      <i className="right fas fa-angle-right arrow-right"></i>
                    </span>
                    <ul className="nav nav-treeview">
                      <li
                        className={
                          activelink === "purchaseinvoice" ||
                          activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="30"
                      >
                        <span
                          onClick={(e) => collapseActive(30)}
                          className="nav-link"
                        >
                          <i className="fas fa-boxes"></i>
                          <p>Purchase Invoice</p>
                        </span>
                      </li>

                      <li
                        className={
                          activelink === "salesinvoice" ||
                          activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="26"
                      >
                        <span
                          onClick={(e) => collapseActive(26)}
                          className="nav-link"
                        >
                          <i className="fas fa-boxes"></i>
                          <p>Sales Invoice</p>
                        </span>
                      </li>
                    </ul>
                  </li>
{/* 
                  <li
                    className={
                      activelink === "admininvoice" || activelink === "nav-item"
                        ? "nav-item menu-open"
                        : "nav-item"
                    }
                    id="26"
                  >
                    <span
                      onClick={(e) => collapseActive(26)}
                      className="nav-link"
                    >
                      <i className="fas fa-receipt"></i>
                      <p>Invoices</p>
                    </span>
                  </li> */}

                  <li
                    className={
                      activelink === "uploadbulkorders" ||
                      activelink === "nav-item"
                        ? "nav-item menu-open"
                        : "nav-item"
                    }
                    id="27"
                  >
                    <span
                      onClick={(e) => collapseActive(27)}
                      className="nav-link"
                    >
                      <i className="fas fa-upload"></i>
                      <p>Upload Bulk Orders </p>
                    </span>
                  </li>

                  <li
                    className={
                      activelink === "adminhandler"
                        ? "nav-item menu-open"
                        : "nav-item"
                    }
                    id="9"
                  >
                    <span
                      onClick={(e) => collapseActive(9)}
                      className="nav-link"
                    >
                      {/* <i className="fas fa-rupee-sign "></i> */}
                      <i className="fas fa-user-lock "></i>
                      <p>Admin Handler</p>
                    </span>
                  </li>
                </>
              ) : (
                <>
                  {role.includes("Vendor") ? (
                    <>
                      <li
                        className={
                          activelink === "orderslist" ||
                          activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="23"
                      >
                        <span
                          onClick={(e) => collapseActive(23)}
                          className="nav-link"
                        >
                          <i className="fas fa-boxes"></i>
                          <p>My Orders</p>
                        </span>
                      </li>

                      {/* <li
                        className={
                          activelink === "customersdetails" ||
                          activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="24"
                      >
                        <span
                          onClick={(e) => collapseActive(32)}
                          className="nav-link"
                        >
                          <i className="fas fa-user-friends"></i>
                          <p>Customers List</p>
                        </span>
                      </li> */}

                      <li
                        className={
                          activelink === "invoice"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="17"
                      >
                        <span
                          onClick={(e) => collapseActive(17)}
                          className="nav-link"
                        >
                          <i className="fas fa-receipt"></i>
                          <p>Invoices</p>
                        </span>
                      </li>

                      <li
                        className={
                          activelink === "pincode" || activelink === "nav-item"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="25"
                      >
                        <span
                          onClick={(e) => collapseActive(25)}
                          className="nav-link"
                        >
                          <i className="fas fa-map-pin"></i>
                          <p>Pin Code</p>
                        </span>
                      </li>

                      <li
                        className={
                          activelink === "zonedata" ||
                          activelink === "createService" ||
                          activelink === "mapservicezone" ||
                          activelink === "uploadoda" ||
                          activelink === "uploadtat" ||
                          activelink === "uploadrate" ||
                          isActive
                            ? "nav-item menu-is-opening menu-open"
                            : "nav-item"
                        }
                        onClick={handleClick}
                      >
                        <span className="nav-link">
                          <i className="fas fa-calculator"></i>
                          <p>Rates</p>

                          <i className="right fas fa-angle-right arrow-right"></i>
                        </span>
                        <ul className="nav nav-treeview">
                          <li
                            class="nav-item"
                            className={
                              activelink === "Zone" || activelink === "zonedata"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="2"
                          >
                            <span
                              onClick={(e) => collapseActive(2)}
                              className="nav-link"
                            >
                              <i className="fas fa-globe-europe"></i>
                              <p>Zone</p>
                            </span>
                          </li>
                          <li
                            class="nav-item"
                            className={
                              activelink === "createService"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="3"
                          >
                            <span
                              onClick={(e) => collapseActive(3)}
                              className="nav-link"
                            >
                              <i className="fas fa-shapes"></i>
                              <p>Services</p>
                            </span>
                          </li>
                          <li
                            class="nav-item"
                            className={
                              activelink === "mapservicezone" ||
                              activelink === "ViewMapService"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="4"
                          >
                            <span
                              onClick={(e) => collapseActive(4)}
                              className="nav-link"
                            >
                              <i className="fas fa-map-signs"></i>
                              <p>Zone & Service Mapping</p>
                            </span>
                          </li>
                          <li
                            class="nav-item"
                            className={
                              activelink === "uploadoda"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="5"
                          >
                            <span
                              onClick={(e) => collapseActive(5)}
                              className="nav-link"
                            >
                              <i className="fas fa-upload"></i>
                              <p>Upload ODA</p>
                            </span>
                          </li>
                          <li
                            class="nav-item"
                            className={
                              activelink === "uploadtat"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="5"
                          >
                            <span
                              onClick={(e) => collapseActive(12)}
                              className="nav-link"
                            >
                              <i className="fas fa-upload"></i>
                              <p>Upload TAT</p>
                            </span>
                          </li>
                          <li
                            class="nav-item"
                            className={
                              activelink === "uploadrate" ||
                              activelink === "viewuploadedrate"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="6"
                          >
                            <span
                              onClick={(e) => collapseActive(6)}
                              className="nav-link"
                            >
                              <i className="fas fa-upload"></i>
                              <p>Upload Rates</p>
                            </span>
                          </li>
                        </ul>
                      </li>

                      <li
                        className={
                          activelink === "venderprofile"
                            ? "nav-item menu-open"
                            : "nav-item"
                        }
                        id="31"
                      >
                        <span
                          onClick={(e) => collapseActive(31)}
                          className="nav-link"
                        >
                          <i className="fas fa-cog"></i>
                          <p>Settings</p>
                        </span>
                      </li>
                    </>
                  ) : (
                    <>
                      {role.includes("User") ? (
                        <>
                          <li
                            className={
                              activelink === "viewrate"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="7"
                          >
                            <span
                              onClick={(e) => collapseActive(7)}
                              className="nav-link"
                            >
                              <i className="fas fa-box-open"></i>
                              <p>Book Orders</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "downloadratesheet"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="13"
                          >
                            <span
                              onClick={(e) => collapseActive(13)}
                              className="nav-link"
                            >
                              <i className="fas fa-box-open"></i>
                              <p>Rate Card</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "myorder"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="21"
                          >
                            <span
                              onClick={(e) => collapseActive(21)}
                              className="nav-link"
                            >
                              <i className="fas fa-boxes"></i>
                              <p>My Orders</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "mypayments"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="22"
                          >
                            <span
                              onClick={(e) => collapseActive(22)}
                              className="nav-link"
                            >
                              <i className="fas fa-money-check"></i>
                              <p>Payments</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "invoices"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="15"
                          >
                            <span
                              onClick={(e) => collapseActive(15)}
                              className="nav-link"
                            >
                              <i className="fas fa-receipt"></i>
                              <p>Invoices</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "setting"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="14"
                          >
                            <span
                              onClick={(e) => collapseActive(14)}
                              className="nav-link"
                            >
                              <i className="fas fa-cog "></i>
                              <p>Settings</p>
                            </span>
                          </li>

                          <li
                            className={
                              activelink === "viewrate"
                                ? "nav-item menu-open"
                                : "nav-item"
                            }
                            id="7"
                          >
                            <div
                              onClick={(e) => collapseActive(7)}
                              className="nav-link book-order-ui"
                            >
                              {/* <i className="fas fa-box-open"></i> */}
                              <h6>Book Your Order Now!</h6>
                              {/* <p>Lorem ipsum dolor sit amet, consectetur... </p> */}
                              <img src="images/img-2.svg" />
                            </div>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              )}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
};

export default Sidebar;
