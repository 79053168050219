import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Admin/`;

export const GetUserBasicDetails = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetUserBasicDetailsByEmail?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetDocketNumber = async (orderid) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetDocketnumber?orderid=${orderid}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UpdateDocketNumber = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}UpdateDocketNumber`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const GetSelectedCustomerOrderlist = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetOfflinePayment?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UpdateOfflinePayment = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}UpdateOfflinePayment`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AdminPieChart = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetPieChart`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetOrderStatusList = async (order) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetOrderStatusList?OrderId=${order}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const AddAdminBankAccount = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}AddAdminBankAccount`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const GetAdminBankAccountList = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAdminBankAccountList?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetCurrentAdminAccountList = async (email) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetCurrentAdminAccountList?AccountNo=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const UpdateAdminDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}UpdateAdminDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AdminChangePassword = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}AdminChangePassword`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const UpdateCreditLimit = async (email, amount) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(
    `${Url}UpdateOfflinePayment?Email=${email}&Amount=${amount}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  )
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const UpdateCustomerDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}UpdateCustomerDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AssignBilling = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}AssignBilling`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      // "content-type": "text/plain;charset=utf-8 ",
      accept: "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const AddBankDetails = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}AddBankDetails`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "content-type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const ChangePassword = async (obj) => {
  const token = localStorage.getItem("Logkey");

  return await fetch(`${Url}ChangePassword`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const UpdateOrderStatus = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}UpdateOrderStatus`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};

export const GetAllOrderList = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAllOrderList?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetBillingDetails = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetBillingDetails?Email=${email}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetSaleOrderList = async (param = "") => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetSaleOrderList?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetPurchaseOrderList = async (param = "") => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}GetPurchaseOrderList?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

// export const GetPurchaseOrderList = async (search, fromdate, todate) => {
//   const token = localStorage.getItem("Logkey");
//   return await axios
//     .get(`${Url}GetPurchaseOrderList/${(search, fromdate, todate)}`, {
//       headers: {
//         "access-control-allow-credentials": "true",
//         "Access-Control-Allow-Origin": "*",
//         Authorization: `Bearer ${token}`,
//         "Content-Type": "application/json",
//       },
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       return error.response;
//     });
// };

export const GetInvoice = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetInvoice?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetVendorInvoice = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetVendorInvoice?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DownloadInvoice = async (param = "") => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}DownloadInvoice?${param}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json; charset=utf-8",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DashboardDetails = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${CurrentUrl}Dashboard/GetDashboardDetails`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetCustomerList = async (params) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}CustomerList?${params}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const GetVendorList = async (details) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}VendorList?${details}`, {
      headers: {
        "access-control-allow-credentials": "true",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const DeleteUserVendor = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}DeleteUserVendor?email=${email}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
      body: JSON.stringify(email),
      //  body: JSON.stringify(email),
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

export const Deactivateuser = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Deactiveuser?email=${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
      body: JSON.stringify({ email: email }),
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

//new
export const activateuser = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Activeuser/${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
      body: JSON.stringify({ email: email }),
    },
  })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      console.log(e);
      return e;
    });
};

//old
export const Activeuser = async (email) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Activeuser/${email}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "text/xml",
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data);
};

export const Getexistingrole = async () => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}Getexistingrole`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

//ROLE API
export const Createrole = async (role) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Createrole/${role}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((res) => res);
};

//ASSIGNROLE API
export const Assignrole = async (data) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(
    `${Url}Assignrole?Email=${data.email}&rolename=${data.rolename}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "text/xml",
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    }
  ).then((data) => data);
};

// GetAllUserDetailsGetAllUserDetails

export const GetAllUserDetails = async () => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}GetAllUserDetails`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const Revokeuser = async (email, role) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Revokerole?email=${email}&role=${role}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "text/xml",
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
  }).then((data) => data);
};

export const Getalluser = async (type) => {
  const token = localStorage.getItem("Logkey");

  return await axios
    .get(`${Url}${type === "User" ? "Getalluser" : "Getallvendor"}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const Updateuser = async (data) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}Updateuser`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "text/xml",
      "content-type": "text/plain; charset=utf-8",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(data),
  }).then((data) => data);
};

//DELETE ORDER BY ADMIN
export const DeleteOrder = async (obj) => {
  const token = localStorage.getItem("Logkey");
  return await fetch(`${Url}DeleteOrder`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": `*`,
    },
    body: JSON.stringify(obj),
  })
    .then((r) => {
      return r.json();
    })
    .catch((e) => {
      return e.response;
    });
};
