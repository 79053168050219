import React,{useEffect, useState} from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import CreateZone from '../Components/CreateZone';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

const CreateZoneAdmin = () => {
	const navigate = useNavigate();
    const[userlog,setUserlog]=useState(localStorage.getItem('Logkey'));
    useEffect(()=>{
        if(userlog===undefined||userlog===null){
            navigate("/")
        }
    },[])
   
    return (
        <>
        
         <Header />
        <Sidebar />
        <CreateZone />
        {/* <Footer/>   */}
        </>
    )
}

export default CreateZoneAdmin
