import React, { useEffect, useState, useCallback } from "react";
import { Button, Rating, Box, TextField } from "@mui/material";
import { submitreview } from "../Services/OrderApi";
const RatingPreview = ({ order_id, closeModal }) => {
  const [value, setValue] = useState();
  const [ratingvalue, setRatingValue] = useState({
    orderid: order_id,
    rating: "",
    review: "",
  });
  const saveData = () => {
    submitreview(ratingvalue).then((res) => {
      closeModal();
    });
  };

  return (
    <Box component="div">
      <Box component="div">
        Rating
        <Box component="div">
          <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setRatingValue({
                ...ratingvalue,
                rating: parseInt(newValue),
              });
              setValue(newValue);
            }}
          />
        </Box>
      </Box>
      <Box component="div">
        <TextField
          id="outlined-multiline-static"
          label="Remarks"
          multiline
          rows={4}
          fullWidth
          value={ratingvalue.review}
          onChange={(event) => {
            setRatingValue({ ...ratingvalue, review: event.target.value });
          }}
        />
      </Box>
      <Box component="div" sx={{ marginTop: "5px" }}>
        <button onClick={() => saveData()} className="btn btn-primary">
          Submit
        </button>
      </Box>
    </Box>
  );
};

export default RatingPreview;
