import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';


export default function BulkOrders() {


  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Upload Bulk Orders</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Upload Bulk Orders</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <div className="container-fluid">
          <div className='row mt-2'>
            <div className='col-lg-6'>
              <Card className='card-down-up'>
              <CardActionArea>
                <CardContent>
                  <SimCardDownloadRoundedIcon sx={{ fontSize: 50 }}/>
                  <h4>download excel sample data file</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus semper lectus, a facilisis augue dignissim id.</p>
                </CardContent>
               </CardActionArea>
              </Card>
            </div>
            <div className='col-lg-6'>
              <Card className='card-down-up'>
              <CardActionArea>
                <CardContent>
                  <UploadFileRoundedIcon sx={{ fontSize: 50 }}/>
                  <h4>Upload Bulk Orders file</h4>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent finibus semper lectus, a facilisis augue dignissim id.</p>
                </CardContent>
               </CardActionArea>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
