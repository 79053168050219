import React from "react";
// import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import LogReg from "./LogReg";
import { Link, animateScroll as scroll } from "react-scroll";
import { toast, ToastContainer } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Box from "@mui/material/Box";
import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Orders/`;
const LogOut = () => {
  // window.localStorage.clear();
  localStorage.removeItem("Logkey");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_email");
  localStorage.removeItem("user_name");
};

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);
  const [User_name, setUname] = useState("");
  const [trackorder, setTrackorder] = useState("");

  const openDropdown = (e) => {};
  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState("./images/logo-white.png");

  // const [orderId, setOrderId] = useState("");
  // const [description, setDescription] = useState("");
  // const [remarks, setRemarks] = useState("");
  // const [invoiceNumber, setInvoiceNumber] = useState("");
  // const [selectedOption, setSelectedOption] = useState("");

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //logo scroll function
  const changeLogo = () => {
    if (window.scrollY >= 60) {
      setNavbarLogo("./images/logo.svg");
    } else {
      setNavbarLogo("./images/logo.svg");
    }
  };

  useEffect(() => {
    changeLogo();
    window.addEventListener("scroll", changeLogo);
  });

  function preventDefault(e) {
    e.preventDefault();
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpenTrack = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
    // resetResults();
  };

  // const resetResults = () => {
  //   setTrackorder(null);
  // };

  // const handleTrackOrder = async () => {
  //   const params = new URLSearchParams();
  //   params.append("Orderid", orderId);
  //   params.append("desc", description);
  //   params.append("remarks", remarks);
  //   params.append("invnumber", invoiceNumber);

  //   const response = await fetch(`${Url}TrackingOrderStatus?${params}`);
  //   const trackorder = await response.json();
  //   setTrackorder(trackorder);
  // };

  // const handleRadioChange = (e) => {
  //   const { value } = e.target;
  //   setOrderId("");
  //   setDescription("");
  //   setRemarks("");
  //   setInvoiceNumber("");
  //   setSelectedOption(value);

  // };

  // const handleInputChange = (e) => {
  //   switch (selectedOption) {
  //     case "orderId":
  //       setOrderId(e.target.value);
  //       break;
  //     case "description":
  //       setDescription(e.target.value);
  //       break;
  //     case "remarks":
  //       setRemarks(e.target.value);
  //       break;
  //     case "invoiceNumber":
  //       setInvoiceNumber(e.target.value);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const [orderkey, setOrderkey] = useState("");
  const handleorderChange = (e) => {
    setOrderkey(e.target.value);
  };

  const handleTrackOrder = async () => {
    const response = await fetch(
      `${Url}TrackingOrderStatus?Orderkey=${orderkey}`
    );
    const trackorder = await response.json();
    setTrackorder(trackorder);
    if (
      // trackorder.isCancelled === null &&
      // trackorder.isDelivered === null &&
      // trackorder.isIntransist === null &&
      trackorder.isBooked === null
    ) {
      toast('No record found');
    }
  };

  return (
    <>
      <div
        className={click ? "main-container-nav" : ""}
        onClick={() => Close()}
      />
      <nav
        className={navbar ? "navbar-nav-main active" : "navbar-nav-main active"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="container navbar-box">
          <Link exact to="/" className="nav-logo-nav">
            <img src={navbarLogo} className="img-fluid logo-img" alt="" />
          </Link>
          <ul className={click ? "nav-menu-nav active" : "nav-menu-nav"}>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                Home
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="about-us"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                About
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="our-services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                Our Services
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact
                to="location"
                className="nav-links-nav"
                onClick={click ? handleClick : null}
              >
                Reach Us
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link
                exact="true"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-70}
                duration={0}
                className="nav-links-nav"
                onClick={preventDefault}
              >
                Contact Us
              </Link>
            </li>
            <li className="nav-item-nav">
              <Link className="nav-links-nav" onClick={handleClickOpenTrack}>
                Track
              </Link>
            </li>
            <div className="nav-links-nav-btn">
              {User_name ? (
                <span style={{ display: "inline-flex", alignItems: "center" }}>
                  <div className="dropdown">
                    <Link
                      className="btn btn-secondary dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {
                        <>
                          {" "}
                          <i className="fas fa-user-circle"></i> {User_name}{" "}
                        </>
                      }
                    </Link>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li onClick={LogOut}>
                        <Link className="dropdown-item" id="rangKala">
                          Log Out
                        </Link>
                      </li>
                    </ul>
                  </div>
                </span>
              ) : (
                <button
                  type="button"
                  className="btn btn-secondary login-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Login/Signup
                </button>
              )}
            </div>
          </ul>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? "fa fa-times" : "fa fa-bars"}></i>
          </div>
        </div>
      </nav>
      {/* Modal */}
      <div
        className="modal fade "
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog modal-md modal-dialog-centered ">
          <div className="modal-content modal-ui">
            <div className="modal-header modal-header-border"></div>
            <div className="modal-body">
              <LogReg />
            </div>
          </div>
        </div>
      </div>
      {/* Old dialog box with radio buttons */}
      {/* <Dialog
        open={open} 
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Track Your Order"}</DialogTitle>
        <DialogContent>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedOption}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="orderId"
                control={<Radio />}
                label="Order ID"
              />
              <FormControlLabel
                value="description"
                control={<Radio />}
                label="Description"
              />
              <FormControlLabel
                value="remarks"
                control={<Radio />}
                label="Remarks"
              />
              <FormControlLabel
                value="invoiceNumber"
                control={<Radio />}
                label="Item Invoice No."
              />
            </RadioGroup>
          </FormControl>

          {selectedOption === "orderId" && (
            <TextField
              onChange={handleInputChange}
              id="outlined-basic"
              label="Enter order ID"
              variant="outlined"
              style={{ width: "450px" }}
            />
          )}
          {selectedOption === "description" && (
            <TextField
              onChange={handleInputChange}
              id="outlined-basic"
              label="Enter description"
              variant="outlined"
              style={{ width: "450px" }}
            />
          )}
          {selectedOption === "remarks" && (
            <TextField
              onChange={handleInputChange}
              id="outlined-basic"
              label="Enter remarks"
              variant="outlined"
              style={{ width: "450px" }}
            />
          )}
          {selectedOption === "invoiceNumber" && (
            <TextField
              onChange={handleInputChange}
              id="outlined-basic"
              label="Enter item invoice number"
              variant="outlined"
              style={{ width: "450px" }}
            />
          )}

          <Button variant="contained" onClick={handleTrackOrder}>
            Track
          </Button>

          <Box>
            <ul
              className={`order-status-list ${
                trackorder.isBookedDate ? "" : "hide-box"
              }`}
            >
              <li
                className={`${
                  trackorder.isBookedDate == "N/A"
                    ? ""
                    : trackorder.isBookedDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isBooked}</h4>
                <span>{trackorder.isBookedDate}</span>
                {trackorder.bookedRemarks &&
                  Array.isArray(trackorder.bookedRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.bookedRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>

              <li
                className={`${
                  // trackorder.isIntransistDate ? "active" : ""
                  trackorder.isIntransistDate == "N/A"
                    ? ""
                    : trackorder.isIntransistDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isIntransist}</h4>
                <span>{trackorder.isIntransistDate}</span>
                {trackorder.intransitRemarks &&
                  Array.isArray(trackorder.intransitRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.intransitRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>
              <li
                className={`${
                  // trackorder.isDeliveredDate ? "active" : ""
                  trackorder.isDeliveredDate == "N/A"
                    ? ""
                    : trackorder.isDeliveredDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isDelivered}</h4>
                <span>{trackorder.isDeliveredDate}</span>
                {trackorder.deliveredRemarks &&
                  Array.isArray(trackorder.deliveredRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.deliveredRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>
              {trackorder.isDelivered === "Delivered" ? null : (
                <li
                  className={`${
                    // trackorder.isCancelledDate ? "active" : ""
                    trackorder.isCancelledDate == "N/A"
                      ? ""
                      : trackorder.isCancelledDate
                      ? "active"
                      : ""
                  }`}
                >
                  <h4>{trackorder.isCancelled}</h4>
                  <span>{trackorder.isCancelledDate}</span>
                  {trackorder.cancelledRemarks &&
                    Array.isArray(trackorder.cancelledRemarks) && (
                      <ol className="remarks-list">
                        {trackorder.cancelledRemarks.map((remark, index) => (
                          <li key={index}>{remark}</li>
                        ))}
                      </ol>
                    )}
                </li>
              )}
            </ul>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Dismiss
          </Button>
        </DialogActions>
      </Dialog> */}
      <ToastContainer />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Track Your Order</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <TextField
            sx={{
              mt: 1,
            }}
            required
            fullWidth
            value={orderkey}
            onChange={handleorderChange}
            label="Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket"
            placeholder="Enter Order Id/ Invoice Number/ Mvikas Docket / LSP Docket"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GpsFixedIcon />
                </InputAdornment>
              ),
            }}
          />
          {/* <Box>
            <ul className={`order-status-list ${  trackorder.isBookedDate ? "" : "hide-box" }`}  >
              <li className={`${ trackorder.isBookedDate == "N/A"   ? "" : trackorder.isBookedDate  ? "active"  : ""  }`}
              >
                <h4>{trackorder.isBooked}</h4>
                <span>{trackorder.isBookedDate}</span>
                {trackorder.bookedRemarks &&
                  Array.isArray(trackorder.bookedRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.bookedRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>

              <li
                className={`${
                trackorder.isIntransistDate == "N/A"
                    ? ""
                    : trackorder.isIntransistDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isIntransist}</h4>
                <span>{trackorder.isIntransistDate}</span>
               {trackorder.intransitRemarks &&
                  Array.isArray(trackorder.intransitRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.intransitRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>
              <li
                className={`${
                 trackorder.isDeliveredDate == "N/A"
                    ? ""
                    : trackorder.isDeliveredDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isDelivered}</h4>
                <span>{trackorder.isDeliveredDate}</span>
               {trackorder.deliveredRemarks &&
                  Array.isArray(trackorder.deliveredRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.deliveredRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>
              {trackorder.isDelivered === "Delivered" ? null : (
                <li
                  className={`${
                 
                    trackorder.isCancelledDate == "N/A"
                      ? ""
                      : trackorder.isCancelledDate
                      ? "active"
                      : ""
                  }`}
                >
                  <h4>{trackorder.isCancelled}</h4>
                  <span>{trackorder.isCancelledDate}</span>
                 {trackorder.cancelledRemarks &&
                    Array.isArray(trackorder.cancelledRemarks) && (
                      <ol className="remarks-list">
                        {trackorder.cancelledRemarks.map((remark, index) => (
                          <li key={index}>{remark}</li>
                        ))}
                      </ol>
                    )}
                </li>
              )}
            </ul>
          </Box> */}
         
            <Box>
              {trackorder.isCancelled !== null ||
              trackorder.isDelivered !== null ||
              trackorder.isIntransist !== null ||
              trackorder.isBooked !== null ? (
                <ul
                  className={`order-status-list ${
                    trackorder.isBookedDate ? "" : "hide-box"
                  }`}
                >
                  {trackorder.isBooked !== null && (
                    <li
                      className={`${
                        trackorder.isBookedDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isBooked}</h4>
                      <span>{trackorder.isBookedDate}</span>
                      {/* {trackorder.bookedRemarks && Array.isArray(trackorder.bookedRemarks) && ( */}
                      {trackorder.bookedRemarks &&
                        Array.isArray(trackorder.bookedRemarks) &&
                        trackorder.bookedRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.bookedRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                  {trackorder.isIntransist !== null && (
                    <li
                      className={`${
                        trackorder.isIntransitDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isIntransist}</h4>
                      <span>{trackorder.isIntransistDate}</span>
                      {/* {trackorder.intransitRemarks && Array.isArray(trackorder.intransitRemarks) && ( */}
                      {trackorder.intransitRemarks &&
                        Array.isArray(trackorder.intransitRemarks) &&
                        trackorder.intransitRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.intransitRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                  {trackorder.isDelivered !== null && (
                    <li
                      className={`${
                        trackorder.isDeliveredDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isDelivered}</h4>
                      <span>{trackorder.isDeliveredDate}</span>
                      {/* {trackorder.deliveredRemarks && Array.isArray(trackorder.deliveredRemarks) && ( */}
                      {trackorder.deliveredRemarks &&
                        Array.isArray(trackorder.deliveredRemarks) &&
                        trackorder.deliveredRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.deliveredRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                  {trackorder.isCancelled !== null && (
                    <li
                      className={`${
                        trackorder.isCancelledDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isCancelled}</h4>
                      <span>{trackorder.isCancelledDate}</span>
                      {/* {trackorder.cancelledRemarks && Array.isArray(trackorder.cancelledRemarks) && ( */}
                      {trackorder.cancelledRemarks &&
                        Array.isArray(trackorder.cancelledRemarks) &&
                        trackorder.cancelledRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.cancelledRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                </ul>
              ) : null}
            </Box>
          
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleTrackOrder}>
            Track
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
