import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";
import Navbar from "./Navbar";
import Support from "./Support";
import SectionOne from "./SectionOne";
import SectionTwo from "./SectionTwo";
import Footer from "./Footer";
import SliderBrands from "./Slider";
import Contact from "./Contact";
import About from "./About";
import Location from "./Location";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import { toast, ToastContainer } from "react-toastify";
import "./My.css";

import { CurrentUrl } from "../Services/UrlApi";
let Url = `${CurrentUrl}Orders/`;
const Home = () => {
  const navigate = useNavigate();
  const [userlog, setUserlog] = useState(localStorage.getItem("Logkey"));

  useEffect(() => {
    if (userlog !== null) {
      navigate("/Dashboard");
    }
  }, []);

  // useEffect(() => {
  //   const removeTokenOnUnload = () => {
  //     localStorage.removeItem('Logkey');
  //     navigate('/');
  //   };

  //   window.addEventListener('beforeunload', removeTokenOnUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', removeTokenOnUnload);
  //   };
  // }, []);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOrderkey("");
    setTrackorder({});
    setOpen(false);
  };

  const [trackorder, setTrackorder] = useState("");

  // useEffect(() => {
  //   async function fetchData() {
  //     const response = await fetch(
  //       `${Url}TrackingOrderStatus?Orderid=${orderId}`
  //     );
  //     const trackorder = await response.json();
  //     setTrackorder(trackorder);
  //   }
  //   if (orderId) {
  //     fetchData();
  //   }
  // }, [orderId]);

  //   async function fetchData() {
  //     const response = await fetch(
  //       `${Url}TrackingOrderStatus?Orderid=${orderId}&desc=${itemdescription}&remarks=${itemremarks}&invnumber=${iteminvoiceno}`
  //     );
  //     const trackorder = await response.json();
  //     setTrackorder(trackorder);
  //   }
  //   // if ((orderId, itemdescription, itemremarks, iteminvoiceno)) {
  //   //   fetchData();
  //   // }
  //   fetchData();
  // }, [orderId, itemdescription, itemremarks, iteminvoiceno]);

  const [orderkey, setOrderkey] = useState("");
  const handleorderChange = (e) => {
    setOrderkey(e.target.value);
  };

  const handleTrackOrder = async () => {
    const response = await fetch(
      `${Url}TrackingOrderStatus?Orderkey=${orderkey}`
    );
    const trackorder = await response.json();
    setTrackorder(trackorder);
    if (
      // trackorder.isCancelled === null &&
      // trackorder.isDelivered === null &&
      // trackorder.isIntransist === null &&
      trackorder.isBooked === null
    ) {
      toast('No record found');
    }
  };

  return (
    <div>
      <Navbar />
      <Banner sectionId="home" />
      <Support />
      <About sectionId="about-us" />
      <SectionOne sectionId="our-services" />
      {/* <SectionTwo /> */}
      <Contact sectionId="location" />
      {/* <SliderBrands /> */}
      <Location sectionId="contact-us" />
      <Footer />
      <Button
        className="track-order"
        variant="contained"
        onClick={handleClickOpen}
      >
        Track Your Order
      </Button>
      <ToastContainer />
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Track Your Order</DialogTitle>
        <DialogContent style={{ minWidth: "600px" }}>
          <TextField
            sx={{
              mt: 1,
            }}
            required
            fullWidth
            value={orderkey}
            onChange={handleorderChange}
            label="Enter Order Id /Invoice Number/ Mvikas Docket / LSP Docket"
            placeholder="Enter Order Id/ Invoice Number/ Mvikas Docket / LSP Docket"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GpsFixedIcon />
                </InputAdornment>
              ),
            }}
          />
          {/* <Box>
            <ul
              className={`order-status-list ${
                trackorder.isBookedDate ? "" : "hide-box"
              }`}
            >
              <li
                className={`${
                  trackorder.isBookedDate == "N/A"
                    ? ""
                    : trackorder.isBookedDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isBooked}</h4>
                <span>{trackorder.isBookedDate}</span>
                {trackorder.bookedRemarks &&
                  Array.isArray(trackorder.bookedRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.bookedRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>

              <li
                className={`${
                  trackorder.isIntransistDate == "N/A"
                    ? ""
                    : trackorder.isIntransistDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isIntransist}</h4>
                <span>{trackorder.isIntransistDate}</span>
                {trackorder.intransitRemarks &&
                  Array.isArray(trackorder.intransitRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.intransitRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>
              {trackorder.isDelivered === null ? "" : (
              <li
                className={`${
                  trackorder.isDeliveredDate == "N/A"
                    ? ""
                    : trackorder.isDeliveredDate
                    ? "active"
                    : ""
                }`}
              >
                <h4>{trackorder.isDelivered}</h4>
                <span>{trackorder.isDeliveredDate}</span>
                {trackorder.deliveredRemarks &&
                  Array.isArray(trackorder.deliveredRemarks) && (
                    <ol className="remarks-list">
                      {trackorder.deliveredRemarks.map((remark, index) => (
                        <li key={index}>{remark}</li>
                      ))}
                    </ol>
                  )}
              </li>
              )}
              {trackorder.isCancelled === null ? "" : (
                <li
                  className={`${
                    trackorder.isCancelledDate == "N/A"
                      ? ""
                      : trackorder.isCancelledDate
                      ? "active"
                      : ""
                  }`}
                >
                  <h4>{trackorder.isCancelled}</h4>
                  <span>{trackorder.isCancelledDate}</span>
                  {trackorder.cancelledRemarks &&
                    Array.isArray(trackorder.cancelledRemarks) && (
                      <ol className="remarks-list">
                        {trackorder.cancelledRemarks.map((remark, index) => (
                          <li key={index}>{remark}</li>
                        ))}
                      </ol>
                    )}
                </li>
              )}
            </ul>
          </Box> */}
         
            <Box>
              {trackorder.isCancelled !== null ||
              trackorder.isDelivered !== null ||
              trackorder.isIntransist !== null ||
              trackorder.isBooked !== null ? (
                <ul
                  className={`order-status-list ${
                    trackorder.isBookedDate ? "" : "hide-box"
                  }`}
                >
                  {trackorder.isBooked !== null && (
                    <li
                      className={`${
                        trackorder.isBookedDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isBooked}</h4>
                      <span>{trackorder.isBookedDate}</span>
                      {trackorder.bookedRemarks &&
                        Array.isArray(trackorder.bookedRemarks) &&
                        trackorder.bookedRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.bookedRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                  {trackorder.isIntransist !== null && (
                    <li
                      className={`${
                        trackorder.isIntransitDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isIntransist}</h4>
                      <span>{trackorder.isIntransistDate}</span>
                      {trackorder.intransitRemarks &&
                        Array.isArray(trackorder.intransitRemarks) &&
                        trackorder.intransitRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.intransitRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                  {trackorder.isDelivered !== null && (
                    <li
                      className={`${
                        trackorder.isDeliveredDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isDelivered}</h4>
                      <span>{trackorder.isDeliveredDate}</span>
                      {trackorder.deliveredRemarks &&
                        Array.isArray(trackorder.deliveredRemarks) &&
                        trackorder.deliveredRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.deliveredRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                  {trackorder.isCancelled !== null && (
                    <li
                      className={`${
                        trackorder.isCancelledDate == "N/A" ? "" : "active"
                      }`}
                    >
                      <h4>{trackorder.isCancelled}</h4>
                      <span>{trackorder.isCancelledDate}</span>
                      {trackorder.cancelledRemarks &&
                        Array.isArray(trackorder.cancelledRemarks) &&
                        trackorder.cancelledRemarks.length > 0 && (
                          <ol className="remarks-list">
                            {trackorder.cancelledRemarks.map(
                              (remark, index) =>
                                remark.trim() !== "" && (
                                  <li key={index}>{remark}</li>
                                )
                            )}
                          </ol>
                        )}
                    </li>
                  )}
                </ul>
              ) : null}
            </Box>
         
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleTrackOrder}>
            Track
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Home;
