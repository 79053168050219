import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import ViewLowestrate from "./ViewLowestrate";
import { useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import {
  DeliverySlot,
  GetAddressList,
  AddNewAddress,
  Pickupslot,
  GetPayLaterdata,
  GetCurrentBankDetails,
  GetCurrentAddress,
  GetcityByState,
  GetStateList,
} from "../Services/UserApi";
import { useEffect } from "react";
import { red } from "@mui/material/colors";
import axios from "axios";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { SaveOrderdata } from "../Services/OrderApi";
import InputAdornment from "@mui/material/InputAdornment";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { PayLater, GetMvikasAccountType } from "../Services/UserApi";
// import { Toast } from "react-toastify/dist/components";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentUrl } from "../Services/UrlApi";
import { green } from "@mui/material/colors";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
let Url = `${CurrentUrl}User/`;

// let Url = `${CurrentUrl}User/`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const styles = {
  title: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    textTransform: "uppercase",
    fontSize: "24px",
    fontWeight: "700",
    letterSpacing: "1px",
    padding: "5px 0",
    color: "#5d0000",
  },
  modalp: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    color: "rgb(0, 0, 0)",
    fontSize: "13px",
  },
  cardui: {
    padding: "10px",
    textAlign: "left",
    boxShadow: "rgb(255 193 7 / 50%) 0px 0px 5px 0px",
    marginBottom: "10px",
    background: "#fff6db",
  },
  CardContent: {
    padding: "0px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  fontfamily: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    texttransform: "uppercase",
    fontweight: "600",
    fontSize: "11px",
    color: "rgb(93 0 0)",
    letterSpacing: "0.5px",
    fontWeight: "600",
    textTransform: "uppercase",
  },
  rightside: {
    fontFamily: "Montserrat, Helvetica, Arial, sans-serif",
    marginLeft: "auto",
    display: "flex",
    fontWeight: "700",
    color: "#000",
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function createRow(rate, cha, doc, fov, fsc, gre, oda, hand) {
  return {
    rate,
    cha,
    doc,
    fov,
    fsc,
    gre,
    oda,
    hand,
  };
}
const rows = [createRow(1, 2, 3, 4, 0, 0, 0, 0)];

export default function Booking() {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);
  const [type_, setType_] = useState("");
  const [dimensionsdata, setDimensionsData] = useState([
    {
      length: "",
      height: "",
      breadth: "",
      boxes: "",
      volume_weight: "",
      units: "",
    },
  ]);

  const paylaterdata = {
    email: "",
    totalAmount: "",
    lpName: "",
    taxamount: "",
    rate: "",
    handlingcharge: "",
    gst: "",
  };

  const handleChange1 = (e) => {
    setSelected(e.target.value);
    //  setUser({ ...user, [e.target.utype]: e.target.value });
  };
  const [usertype, setSelected] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedpickupslot, setSelectedpickupslot] = useState({});
  const [selecteddeliveryslot, setselecteddeliveryslot] = useState({});
  const [pickupslotList, setPickupslotList] = useState([]);
  const [deliveryslotlist, setdeliveryslotlist] = useState([]);
  const [selectedpickupaddress, setSelectedPickupAddress] = useState({});
  const [selecteddeliveryaddress, setSelectedDeliveryAddress] = useState({});
  const [selectedreturnaddress, setSelectedReturnAddress] = useState({
    address1: "",
  });
  const [pickupPersonname, setPickupPersonName] = useState("");
  const [pickupPersoncontact, setPickupPersonContact] = useState("");
  const [itemname, setItemname] = useState("");
  const [paylatercomplete, setPaylatercomplete] = useState("");
  const [payLaterDisabled, setPayLaterDisabled] = useState(false);
  const [paynowdata, setPaynowdata] = useState("");
  const [invoicenumber, setInvoicenumber] = useState("");
  const [invoicefile, setInvoicefile] = useState("");

  const [remarks, setremarks] = useState("");
  const [itemdesc, setitemdesc] = useState("");
  const [eWayBillNo, seteWayBillNo] = useState("")
  const [transporterName, settransporterName] = useState("")
  const [eWayBillExpDate, seteWayBillExpDate] = useState("")




  const [pickuppersonname, setpickuppersonname] = useState("");
  const [pickuppersoncontact, setpickuppersoncontact] = useState("");
  const [deliverypersonname, setdeliverypersonname] = useState("");
  const [deliverypersoncontact, setdeliverypersoncontact] = useState("");
  const [returnremarks, setreturnremarks] = useState("");
  const [returnpersonname, setreturnpersonname] = useState("");
  const [returnpersoncontact, setreturnpersoncontact] = useState("");
  const [showProgress, setShowProgress] = useState(false);
  const [open, setOpen] = useState(false);
  const [state, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [messopen, meassageOpen] = useState(false);

  const [newaddress, setNewAddress] = useState({
    name: "",
    full_address: "",
    pincode: "",
    state: "",
    city: "",
    country: "India",
    phone: "",
    email: localStorage.getItem("user_email"),
  });

  const handlepickupcontactBlur = (e) => {
    const pickupcontact = e.target.value;
    if (pickupcontact.length < 8) {
      toast.warn("Enter minimum 8 digits");
    } else if (pickupcontact.length > 10) {
      toast.warn("Enter maximum 10 digits");
    } else {
      setpickuppersoncontact(pickupcontact);
    }
  };
  const handledeliverycontactBlur = (e) => {
    const deliverycontact = e.target.value;
    if (deliverycontact.length < 8) {
      toast.warn("Enter minimum 8 digits");
    } else if (deliverycontact.length > 10) {
      toast.warn("Enter maximum 10 digits");
    } else {
      setdeliverypersoncontact(deliverycontact);
    }
  };
  const handlereturncontactBlur = (e) => {
    const returncontact = e.target.value;
    if (returncontact.length < 8) {
      toast.warn("Enter minimum 8 digits");
    } else if (returncontact.length > 10) {
      toast.warn("Enter maximum 10 digits");
    } else {
      setreturnpersoncontact(returncontact);
    }
  };
  const messageopen = () => {
    meassageOpen(true);
  };
  const messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    meassageOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleZipCodeBlur = (e) => {
    const zipvalue = e.target.value;
    if (zipvalue.length < 6) {
      toast.warn("Enter 6 digit zipcode");
    }
  };

  // STEPPER VALIDATIONS
  const handleNext = () => {
    if (activeStep == 2) {
      if (
        selectedpickupaddress.hasOwnProperty("address1") &&
        (selectedpickupslot.hasOwnProperty("slotvalue1") ||
          (Array.isArray(pickupslotList) && pickupslotList.length > 0)) &&
        pickuppersonname &&
        pickuppersoncontact
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        if (!Array.isArray(pickupslotList) || pickupslotList.length === 0) {
          toast.warn("Pickup slot list is empty");
        } else {
          toast.warn("Please validate all fields");
        }
      }
    } else if (activeStep == 3) {
      if (
        selecteddeliveryaddress.hasOwnProperty("address1") &&
        (selecteddeliveryslot.hasOwnProperty("slotvalue2") ||
          (Array.isArray(deliveryslotlist) && deliveryslotlist.length > 0)) &&
        deliverypersonname &&
        deliverypersoncontact
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        if (!Array.isArray(deliveryslotlist) || deliveryslotlist.length === 0) {
          toast.warn("Delivery slot list is empty");
        } else {
          toast.warn("Please validate all fields");
        }
      }
    } else if (activeStep == 4) {
      if (
        selectedreturnaddress.hasOwnProperty("address1") &&
        returnpersonname &&
        returnpersoncontact
        // returnremarks
      ) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.warn("Please validate all fields");
      }
    } else if (activeStep == 5) {
      if (itemname) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        toast.warn("Please validate all fields");
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  useEffect(() => {
    loadStateList();
  }, []);

  const loadStateList = () => {
    setState([]);

    GetStateList()
      .then((result) => {
        setState(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  useEffect(() => {
    if (selectedState) {
      GetcityByState(selectedState).then((result) => {
        setCities(result);
      });
    } else {
      setCities([]);
    }
  }, [selectedState]);

  const handleStatechange = (e) => {
    setSelectedState(e.target.value);
  };
  const handlecitychange = (e) => {
    setSelectedCity(e.target.value);
  };

  useEffect(() => {
    loadAddressList();
  }, []);

  const loadAddressList = () => {
    setAddressList([]);

    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const RefreshAddress = (emailVal) => {
    GetAddressList(localStorage.getItem("user_email"))
      .then((result) => {
        setAddressList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Addaddress = async (newadd, emailVal) => {
    let addvalidation = true;
    if (
      newadd.name == null ||
      newadd.name == "" ||
      newadd.full_address == null ||
      newadd.full_address == "" ||
      newadd.pincode == null ||
      newadd.pincode == "" ||
      newadd.phone == null ||
      newadd.phone == "" ||
      newadd.state == null ||
      newadd.state == "" ||
      newadd.city == null ||
      newadd.city == ""
    ) {
      toast.warn("Please validate address fields");
      addvalidation = false;
    }
    if (addvalidation) {
      // setLoading(true);

      let respone = await AddNewAddress(newadd);
      setLoading(false);
      if ("status" in respone) {
        if (respone.status == 200) {
          setOpen(false);
          setSuccess(true);
          meassageOpen(true);
          timer.current = window.setTimeout(() => {
            setSuccess(false);
            meassageOpen(false);
            RefreshAddress(emailVal);
          }, 2000);
        }
      }
    }
  };

  const handleitemtypeDownload = () => {
    const link = document.createElement("a");
    link.href = "images/file.pdf";
    link.download = "ItemType.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const [paynow, setpaynowopen] = React.useState(false);
  const PaynowOpen = () => {
    setpaynowopen(true);
  };

  const paynowClose = () => {
    setpaynowopen(false);
  };

  const [paylater, setpaylateropen] = React.useState(false);
  const PaylaterOpen = () => {
    setpaylateropen(true);
  };
  const paylaterClose = () => {
    setpaylateropen(false);
  };

  var lpdata = localStorage.getItem("lpdata");
  lpdata = JSON.parse(lpdata);
  const [Data, setData] = useState({
    email: localStorage.getItem("user_email"),
    name: localStorage.getItem("user_name"),
    mobile: localStorage.getItem("user_mobile"),
    CFT: lpdata.cft,
    rate: lpdata.rate,
    chargeweight: lpdata.chargeableWt,
    dockcharge: lpdata.docketCharge,
    fov: lpdata.fovCharge,
    fsc: lpdata.fscAmount,
    green: lpdata.greenTaxAmount,
    oda: lpdata.oda,
    handling: lpdata.handlingCharge,
    taxamount: lpdata.taxableAmount,
    gst: lpdata.gstAmount,
    total: lpdata.totalAmount,
    lpname: lpdata.lpName,
  });

  const InvoiceImage = (e) => {
    const formData = new FormData();
    formData.append("imgfile", e.target.files[0]);
    // setFileName(e.target.files[0].name)
    // let imgResponse =
    axios
      .post(`${CurrentUrl}api/Payment/InvoiceImage`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setInvoicefile(response.data.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error.response);
      });

    // if (imgResponse.hasOwnProperty("data")) {
    //   setInvoicefile((val) => ({ ...val, imgResponse }));

    // }
  };

  const Getmypaylaterdata = async () => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(
        `${CurrentUrl}User/GetPaylater?Email=${localStorage.getItem(
          "user_email"
        )}`,
        {
          headers: {
            "access-control-allow-credentials": "true",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setPaylatercomplete(response.data);
      })
      .catch((error) => {
        return error.response;
      });
  };

  const [useSameAddress, setUseSameAddress] = useState(false);

  useEffect(() => {
    if (useSameAddress) {
    }
  }, [useSameAddress]);

  const returnClickhandler = (isChecked) => {
    if (isChecked) {
      setSelectedReturnAddress((val) => ({
        ...val,
        address1: selectedpickupaddress.address1,
      }));
      setreturnpersonname(pickuppersonname);
      setreturnpersoncontact(pickuppersoncontact);
    } else {
      setSelectedReturnAddress((val) => ({
        ...val,
        address1: "",
      }));
      setreturnpersonname("");
      setreturnpersoncontact("");
    }
    setUseSameAddress(isChecked);
  };

  useEffect(() => {}, [selectedreturnaddress]);

  useEffect(() => {
    Pickupslot(lpdata.lpId)
      .then((result) => {
        setPickupslotList(result.data);
      })
      .catch((r) => {
        console.log(r);
      });

    DeliverySlot(lpdata.lpId)
      .then((result) => {
        setdeliveryslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });

    let mylptotalamount = localStorage.getItem("lpdata");
    var mydata1 = JSON.parse(mylptotalamount);
    let mytotalamount = mydata1.totalAmount;
    GetMvikasAccountType(localStorage.getItem("user_email"), mytotalamount)
      .then((result) => {
        setType_(result.data.billing_Type);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  var lpdata = localStorage.getItem("lpdata");
  lpdata = JSON.parse(lpdata);
  const [paymentdata, setPaymentdata] = useState({
    email: localStorage.getItem("user_email"),
    name: localStorage.getItem("user_name"),
    mobile: localStorage.getItem("user_mobile"),
    rate: lpdata.rate,
    handlingcharge: lpdata.handlingCharge,
    taxamount: lpdata.taxableAmount,
    gst: lpdata.gstAmount,
    total: lpdata.totalAmount,
    lpname: lpdata.lpName,
  });

  async function handleRazorpayPayment() {
    const paymenturl = localStorage.getItem("mypaymenturl");
    const confirmedpay = localStorage.getItem("paymentconfirm");
    const response = await axios.post(paymenturl, paymentdata);

    const order_id = response.data.id;
    const options = {
      key: `rzp_test_3CyxhhqhwZJpfj`,
      amount: 0,
      name: "MVikas Logisitics",
      image: "",
      order_id: order_id,
      handler: (response) => {
        let mysearchdata = localStorage.getItem("searchdata");
        mysearchdata = JSON.parse(mysearchdata);
        setDimensionsData(mysearchdata);

        let mylpdata = localStorage.getItem("lpdata");

        mylpdata = JSON.parse(mylpdata);

        let orderdimensions = [
          {
            length: mysearchdata.Lenght,
            height: mysearchdata.height,
            breadth: mysearchdata.breadth,
            volume_weight: mysearchdata.volume_weight,
            noOfArticles: "",
          },
        ];
        let body = {
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          Cust_Email: localStorage.getItem("user_email"),
          lpid: mylpdata.lpId,
          serviceid: mylpdata.serviceId,
          frompincode: mysearchdata.ratedata.frompin,
          topincode: mysearchdata.ratedata.topin,
          shipment_weight: mysearchdata.ratedata.shipmentweight,
          chargable_weight: mylpdata.chargeableWt,
          shipment_value: mysearchdata.ratedata.shipmentvalue,
          cft: mylpdata.cft,
          divisor: mylpdata.divisor,
          isCod: mysearchdata.ratedata.cod,
          rate: mylpdata.rate,
          docketcharge: mylpdata.docketCharge,
          fsc_percent: mylpdata.fsc,
          fsc_amount: mylpdata.fscAmount,
          greentax: mylpdata.greenTax,
          greentax_amount: mylpdata.greenTaxAmount,
          min_Chargable_weight: mylpdata.minWt,
          minFreight: mylpdata.minFreight,
          minFOV: mylpdata.minFOV,
          fovPercentage: mylpdata.fovPercentage,
          fov_amount: mylpdata.fovCharge,
          minODA: mylpdata.minODA,
          odaPerKG: mylpdata.odaPerKG,
          oda_amount: mylpdata.oda,
          handlingCharge: mylpdata.handlingCharge,
          codAmount: mylpdata.codAmount,
          taxableAmount: mylpdata.taxableAmount,
          gst: mylpdata.gst,
          gst_Amount: mylpdata.gstAmount,
          totalAmount: mylpdata.totalAmount,
          ratesheetid: mylpdata.rateSheetId,
          v_Fsc_amount: lpdata.fscAmountV,
          v_gst_Amount: lpdata.gstAmountV,
          v_totalAmount: lpdata.totalAmountV,
          v_taxableAmount: lpdata.taxableAmountV,
          orderdimensions: orderdimensions,
          itemname: itemname,
          // mvikasDocketNo: remarks,
          iteminvoice: invoicenumber,
          image: "",
          imagepath: invoicefile,

          expectedDelivery:
            mylpdata.expectedDelivery === "NA"
              ? null
              : moment(mylpdata.expectedDelivery).format(
                  "YYYY-MM-DDTHH:mm:ss.SSSZ"
                ),

          tat: mylpdata.tat,
          deliveryslot:
            selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
          pickuptime:
            selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
          deliveryaddress: selecteddeliveryaddress.address1,
          pickupaddress: selectedpickupaddress.address1,
          returnaddress: selectedreturnaddress.address1,
          deliverypersonname: deliverypersonname,
          deliverypersoncontact: deliverypersoncontact,
          pickuppersonname: pickuppersonname,
          pickuppersoncontact: pickuppersoncontact,
          returnpersonname: returnpersonname,
          returnpersoncontact: returnpersoncontact,
          returnremarks: returnremarks,
          lspDocketNo: itemdesc,
          mvikasDocketNo: remarks,
          eWayBillNo: eWayBillNo,
          transporterName: transporterName,
          eWayBillExpDate: eWayBillExpDate,
        };

        setShowProgress(true);
        axios
          .post(confirmedpay, body)
          .then((response1) => {
            setShowProgress(true);
            setShowProgress(true);
            setPaynowdata(response1.data);
            setShowProgress(true);
            setShowProgress(false);
            setpaynowopen(true);
          })
          .catch((err) => console.log(err));
      },
      prefill: {
        name: "MVikas",
        email: "testuser@mail.com",
      },
      theme: {
        color: "#F37254",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }

  const [payitlaterdata, setPaylaterdata] = useState({
    email: localStorage.getItem("user_email"),
    rate: lpdata.rate,
    handlingcharge: lpdata.handlingCharge,
    taxamount: lpdata.taxableAmount,
    gst: lpdata.gstAmount,
    totalAmount: lpdata.totalAmount,
    lpname: lpdata.lpName,
  });

  function payitlater() {
    let mysearchdata = localStorage.getItem("searchdata");
    mysearchdata = JSON.parse(mysearchdata);
    setDimensionsData(mysearchdata);
    // let mygetrate = localStorage.get("getrateresponse");
    // mygetrate = JSON.parse(mygetrate);

    let mylpdata = localStorage.getItem("lpdata");
    mylpdata = JSON.parse(mylpdata);

    let orderdimensions = [
      {
        length: mysearchdata.Lenght,
        height: mysearchdata.height,
        breadth: mysearchdata.breadth,
        volume_weight: mysearchdata.volume_weight,
        noOfArticles : mysearchdata.pieces
       
      },
    ];

    let body = {
      email: localStorage.getItem("user_email"),
      cust_Email: localStorage.getItem("user_email"),
      lpid: mylpdata.lpId,
      serviceid: mylpdata.serviceId,
      frompincode: mysearchdata.ratedata.frompin,
      topincode: mysearchdata.ratedata.topin,
      shipment_weight: mysearchdata.ratedata.shipmentweight,
      chargable_weight: mylpdata.chargeableWt,
      shipment_value: mysearchdata.ratedata.shipmentvalue,
      cft: mylpdata.cft,
      divisor: mylpdata.divisor,
      isCod: mysearchdata.ratedata.cod,
      rate: mylpdata.rate,
      docketcharge: mylpdata.docketCharge,
      fsc_percent: mylpdata.fsc,
      fsc_amount: mylpdata.fscAmount,
      greentax: mylpdata.greenTax,
      greentax_amount: mylpdata.greenTaxAmount,
      min_Chargable_weight: mylpdata.minWt,
      minFreight: mylpdata.minFreight,
      minFOV: mylpdata.minFOV,
      fovPercentage: mylpdata.fovPercentage,
      fov_amount: mylpdata.fovCharge,
      minODA: mylpdata.minODA,
      odaPerKG: mylpdata.odaPerKG,
      oda_amount: mylpdata.oda,
      handlingCharge: mylpdata.handlingCharge,
      codAmount: mylpdata.codAmount,
      taxableAmount: mylpdata.taxableAmount,
      gst: mylpdata.gst,
      gst_Amount: mylpdata.gstAmount,
      totalAmount: mylpdata.totalAmount,
      ratesheetid: mylpdata.rateSheetId,
      orderdimensions: orderdimensions,
      itemname: itemname,
      iteminvoice: invoicenumber,
      image: "",
      imagepath: invoicefile,
      
      expectedDelivery:
        mylpdata.expectedDelivery === "NA"
          ? null
          : moment(mylpdata.expectedDelivery).format(
              "YYYY-MM-DDTHH:mm:ss.SSSZ"
            ),

      deliveryslot:
        selecteddeliveryslot.slotvalue2 || deliveryslotlist[0].slotvalue,
      pickuptime: selectedpickupslot.slotvalue1 || pickupslotList[0].slotvalue,
      deliveryaddress: selecteddeliveryaddress.address1,
      pickupaddress: selectedpickupaddress.address1,
      returnaddress: selectedreturnaddress.address1,
      v_Fsc_amount: lpdata.fscAmountV,
      v_gst_Amount: lpdata.gstAmountV,
      vTotalAmount: lpdata.totalAmountV,
      v_taxableAmount: lpdata.taxableAmountV,
      deliverypersonname: deliverypersonname,
      deliverypersoncontact: deliverypersoncontact,
      pickuppersonname: pickuppersonname,
      pickuppersoncontact: pickuppersoncontact,
      returnpersonname: returnpersonname,
      returnpersoncontact: returnpersoncontact,
      returnremarks: returnremarks,
      lspDocketNo: itemdesc,
      mvikasDocketNo: remarks,
      eWayBillNo: eWayBillNo,
      transporterName: transporterName,
      eWayBillExpDate: eWayBillExpDate,
    };
    var paylpdata = localStorage.getItem("lpdata");
    paylpdata = JSON.parse(paylpdata);

    setPaylaterdata(paylpdata);
    setPayLaterDisabled(true);
    setShowProgress(true);
    PayLater(body, paylaterdata)
      .then((result) => {
        Getmypaylaterdata(localStorage.getItem("user_email"));
        setpaylateropen(true);
        setShowProgress(false);
      })
      .catch((r) => {
        console.log(r);
      });
  }

  const steps = [
    {
      label: "Order Details",
      description: (
        <>
          <h6 className="mt-2 mb-4 h6-title">LP Details</h6>

          <Box>
            <div className="row">
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="LP Name"
                  value={Data["lpname"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              {/* <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Person Name"
                  value={Data["name"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Email"
                  value={Data["email"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Mobile No"
                  value={Data["mobile"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div> */}
            </div>
          </Box>

          <h6 className="mt-2 h6-title">Charges</h6>
          <TableContainer component={Paper} sx={{ boxShadow: 0, mb: 2 }}>
            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>Rate</TableCell>
                  <TableCell align="right">Chargeable Weight</TableCell>
                  <TableCell align="right">Docket Charge </TableCell>
                  <TableCell align="right">FOV Charge</TableCell>
                  <TableCell align="right">FSC Amount (20%)</TableCell>
                  <TableCell align="right">Green Tax</TableCell>
                  <TableCell align="right">ODA</TableCell>
                  <TableCell align="right">Handling Charge</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.desc}>
                    <TableCell>{Data["rate"].toFixed(2)}</TableCell>
                    <TableCell align="right">
                      {Data["chargeweight"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["dockcharge"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["fov"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["fsc"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["green"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["oda"].toFixed(2)}
                    </TableCell>
                    <TableCell align="right">
                      {Data["handling"].toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={3} colSpan={6} />
                  <TableCell>Taxable Amount</TableCell>
                  <TableCell align="right">
                    {Data["taxamount"].toFixed(2)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>GST Amount (18%)</TableCell>
                  <TableCell align="right">{Data["gst"].toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Amount</TableCell>
                  <TableCell align="right">
                    {Data["total"].toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ),
    },
    {
      label: "Customer Details",
      description: (
        <>
          <Box>
            <div className="row">
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Person Name"
                  value={Data["name"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Email"
                  value={Data["email"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
              <div className="col-sm-3 mb-4">
                <TextField
                  fullWidth
                  label="Mobile No"
                  value={Data["mobile"]}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </>
      ),
    },

    {
      label: " Pickup Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Pickup Person Name"
                  value={pickuppersonname}
                  onChange={(e) => setpickuppersonname(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Pickup Person Contact"
                  value={pickuppersoncontact}
                  onBlur={handlepickupcontactBlur}
                  onChange={(e) => {
                    const pickupcontact = e.target.value;
                    if (/^\d{0,10}$/.test(pickupcontact)) {
                      setpickuppersoncontact(pickupcontact);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="pickup-slot-label">
                    Pickup Slot
                  </InputLabel>

                  <Select
                    labelId="pickup-slot-label"
                    id="pickup-slot"
                    required
                    label="Pickup Slot"
                    value={selectedpickupslot["slotvalue1"]}
                    onChange={(e) => {
                      setSelectedpickupslot((val) => ({
                        ...val,
                        slotvalue1: e.target.value,
                      }));
                    }}
                    defaultValue={
                      pickupslotList && pickupslotList[0]
                        ? pickupslotList[0].slotvalue
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {pickupslotList.map((option, index) => (
                      <MenuItem key={index} value={option.slotvalue}>
                        {option.slotvalue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="pickup-address-label">
                    Pickup Address
                  </InputLabel>
                  <Select
                    labelId="pickup-address-label"
                    id="pickup-address"
                    required
                    label="Pickup Address"
                    value={selectedpickupaddress["address1"]}
                    onChange={(e) => {
                      setSelectedPickupAddress((val) => ({
                        ...val,
                        address1: e.target.value,
                      }));
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {/* <MenuItem value="0">
                      <p className="default-p">Please Choose Pickup Address</p>
                    </MenuItem> */}

                    {addressList.map((add, index) => (
                      <MenuItem key={add.address_Id} value={add.address_Id}>
                        <span
                          title={`${add.full_address}, ${add.state}, ${add.pincode}`}
                        >
                          {add.name}
                        </span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-2 mb-4">
                <IconButton
                  className="add-icon"
                  aria-label="add"
                  size="large"
                  onClick={handleClickOpen}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: " Delivery Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Delivery Person Name"
                  value={deliverypersonname}
                  onChange={(e) => setdeliverypersonname(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  required
                  label="Delivery Person Contact"
                  value={deliverypersoncontact}
                  onBlur={handledeliverycontactBlur}
                  onChange={(e) => {
                    const deliverycontact = e.target.value;
                    if (/^\d{0,10}$/.test(deliverycontact)) {
                      setdeliverypersoncontact(deliverycontact);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="delivery-slot-label">
                    Delivery Slot
                  </InputLabel>
                  <Select
                    labelId="delivery-slot-label"
                    required
                    id="delivery-slot"
                    label="Delivery Slot"
                    value={selecteddeliveryslot["slotvalue2"]}
                    onChange={(e) => {
                      setselecteddeliveryslot((val) => ({
                        ...val,
                        slotvalue2: e.target.value,
                      }));
                    }}
                    defaultValue={
                      deliveryslotlist && deliveryslotlist[0]
                        ? deliveryslotlist[0].slotvalue
                        : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {deliveryslotlist.map((option, index) => (
                      <MenuItem key={index} value={option.slotvalue}>
                        {option.slotvalue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="delievery-address-label">
                    Delivery Address
                  </InputLabel>
                  <Select
                    labelId="delievery-address-label"
                    required
                    id="delievery-address"
                    label="Delivery Address"
                    value={selecteddeliveryaddress["address1"]}
                    onChange={(e) => {
                      setSelectedDeliveryAddress((val) => ({
                        ...val,
                        address1: e.target.value,
                      }));
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    <MenuItem value="0">
                      <p className="default-p">
                        Please Choose Delivery Address
                      </p>
                    </MenuItem>

                    {addressList.map((add, index) => (
                      <MenuItem key={add.address_Id} value={add.address_Id}>
                        <span title={add.full_address}>{add.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-sm-2 mb-4">
                <IconButton
                  className="add-icon"
                  aria-label="add"
                  size="large"
                  onClick={handleClickOpen}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: " Return Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Return Person Name"
                  required
                  value={returnpersonname}
                  onChange={(e) => {
                    if (useSameAddress) {
                      setpickuppersonname(e.target.value);
                    } else {
                      setreturnpersonname(e.target.value);
                    }
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Return Person Contact"
                  required
                  value={returnpersoncontact}
                  onBlur={handlereturncontactBlur}
                  onChange={(e) => {
                    // const returncontact = e.target.value;
                    // if (/^\d{0,10}$/.test(returncontact)) {
                    //   setreturnpersoncontact(returncontact);
                    // }
                    if (useSameAddress) {
                      setpickuppersoncontact(e.target.value);
                    } else {
                      setreturnpersoncontact(e.target.value);
                    }
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Remark"
                  value={returnremarks}
                  onChange={(e) => setreturnremarks(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <FormControl fullWidth>
                  <InputLabel required id="return-address-label">
                    Return Address
                  </InputLabel>
                  <Select
                    labelId="return-address-label"
                    id="return-address"
                    required
                    label="Return Address"
                    value={selectedreturnaddress.address1}
                    onChange={(e) => {
                      if (useSameAddress) {
                        setSelectedPickupAddress((val) => ({
                          ...val,
                          address1: e.target.value,
                        }));
                      } else {
                        setSelectedReturnAddress((val) => ({
                          ...val,
                          address1: e.target.value,
                        }));
                      }
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {addressList.map((add, index) => (
                      <MenuItem key={add.address_Id} value={add.address_Id}>
                        <span title={add.full_address}>{add.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useSameAddress}
                      onChange={(e) => returnClickhandler(e.target.checked)}
                      name="useSameAddress"
                    />
                  }
                  label="Same as Pickup Address"
                />
              </div>

              <div className="col-sm-2 mb-4">
                <IconButton
                  className="add-icon"
                  aria-label="add"
                  size="large"
                  onClick={handleClickOpen}
                >
                  <AddRoundedIcon />
                </IconButton>
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: "Details",
      description: (
        <>
          <Box sx={{ mt: 2 }}>
            <div className="row">
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Item Type"
                  value={itemname}
                  required
                  onChange={(e) => setItemname(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Info">
                          <IconButton onClick={handleitemtypeDownload}>
                            <InfoOutlinedIcon sx={{ fontSize: 20 }} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Invoice No."
                  value={invoicenumber}
                  onChange={(e) => setInvoicenumber(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  type="file"
                  value={invoicefile.data}
                  onChange={InvoiceImage}
                  label="Upload Invoice"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  value={itemdesc}
                  onChange={(e) => setitemdesc(e.target.value)}
                  label="LSP Docket number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>

              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="MVikas Docket Number"
                  value={remarks}
                  onChange={(e) => setremarks(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="E-way Bill Number"
                  value={eWayBillNo}
                  onChange={(e) => seteWayBillNo(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  type="date"
                  label="E-way Bill Expiry Date"
                  value={eWayBillExpDate}
                  onChange={(e) => seteWayBillExpDate(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="col-sm-4 mb-4">
                <TextField
                  fullWidth
                  label="Transporter Name"
                  value={transporterName}
                  onChange={(e) => settransporterName(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Box>
        </>
      ),
    },
    {
      label: "Payment Options",
      description: (
        <>
          <Stack sx={{ my: 2 }} spacing={2} direction="row">
            {/* <Button variant="contained" size="large">
              To pay
            </Button> */}
            <Button
              variant="outlined"
              size="large"
              onClick={handleRazorpayPayment}
            >
              Pay Now
            </Button>
            <Button
              disabled={
                payLaterDisabled || type_ === "Prepaid" || type_ === null
              }
              variant="contained"
              size="large"
              onClick={payitlater}
            >
              Pay Later
            </Button>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="main-title">Order Summary</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <span>Home</span>
                  </li>
                  <li className="breadcrumb-item active">Order Summary</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <ToastContainer />
            <Box className="payment-ui">
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      <Typography>{step.description}</Typography>
                      <Box sx={{ mb: 2 }}>
                        <div>
                          <Button
                            className={
                              index === steps.length - 1 ? "btn-hide" : ""
                            }
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Continue
                          </Button>
                          <Button
                            href="#/viewrate"
                            className={index === 0 ? "dis-none" : "btn-none"}
                          >
                            Back
                          </Button>

                          <Button
                            className={index === 0 ? "btn-none" : "dis-block"}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                          >
                            Back
                          </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </div>

          {/* //PAYMENT COMPLETION DIALOG BOX */}
          <Dialog
            open={paynow}
            // open={paylater}
            TransitionComponent={Transition}
            keepMounted
            onClose={paynowClose}
            aria-describedby="alert-dialog-slide-description"
            align="center"
          >
            <DialogContent>
              <DoneAllIcon sx={{ fontSize: 60 }} color="success" />
              <DialogTitle>{"Your payment is successfully done!"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                Your order summary is:
                <TableContainer sx={{ mt: 2 }} component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Order Date</TableCell>
                        <TableCell>Order ID</TableCell>
                        <TableCell>Payment ID</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell>{paynowdata.orderdate}</TableCell>
                          <TableCell>{paynowdata.orderid}</TableCell>
                          <TableCell>{paynowdata.paymentid}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2 }}>
              <Button href="#/Dashboard" className="ml-auto">
                OK
              </Button>
              {/* <Button variant="contained">Download Receipt</Button> */}
            </DialogActions>
          </Dialog>

          {/* //PAY LATER DIALOG  BOX       */}
          <Dialog
            open={paylater}
            // open= {setpaylateropen(true)}
            TransitionComponent={Transition}
            keepMounted
            onClose={paylaterClose}
            aria-describedby="alert-dialog-slide-description"
            align="center"
          >
            <DialogContent style={{ maxWidth: "300px" }}>
              <img src="./images/wallet.svg" alt="" />
              <DialogTitle style={styles.title}>{"Pay Later!"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                <p style={styles.modalp}>Payment has been successfully done.</p>
                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}>Credit Limit</span>{" "}
                    <span style={styles.rightside}>
                      <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      {paylatercomplete.creditLimit}
                    </span>
                  </CardContent>
                </Card>

                {/* <Card style={styles.cardui}>
                <CardContent style={styles.CardContent}>
                  <span style={styles.fontfamily}>Already Used Credit</span>{" "}
                  <span style={styles.rightside}>
                    <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                    {paylatercomplete.alreadyUsed}
                  </span>
                </CardContent>
              </Card> */}

                {/* <Card style={styles.cardui}>
                <CardContent style={styles.CardContent}>
                  <span style={styles.fontfamily}>Available Balance</span>{" "}
                  <span style={styles.rightside}>
                    <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                    {paylatercomplete.availableBalance}
                  </span>
                </CardContent>
              </Card> */}

                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}>Transaction Amount </span>{" "}
                    <span style={styles.rightside}>
                      <CurrencyRupeeIcon sx={{ fontSize: 15 }} />
                      {paylatercomplete.totalAmount}
                    </span>
                  </CardContent>
                </Card>

                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}>Order ID </span>{" "}
                    <span style={styles.rightside}>
                      {/* <CurrencyRupeeIcon sx={{ fontSize: 15 }} /> */}
                      {paylatercomplete.orderId}
                    </span>
                  </CardContent>
                </Card>

                <Card style={styles.cardui}>
                  <CardContent style={styles.CardContent}>
                    <span style={styles.fontfamily}> Payment ID </span>{" "}
                    <span style={styles.rightside}>
                      {/* <CurrencyRupeeIcon sx={{ fontSize: 15 }} /> */}
                      {paylatercomplete.paymentId}
                    </span>
                  </CardContent>
                </Card>
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ px: 2, pb: 2 }}>
              {/* <Button href="#" className="mr-auto">
              Back
            </Button> */}
              <Button href="#/Dashboard" variant="contained">
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </section>
      </div>

      {showProgress && (
        <div className="circular-progress-ui">
          <CircularProgress />
        </div>
      )}

      {/* //ADD ADDRESS DIALOG BOX */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        className="address-dialog"
      >
        <DialogTitle>Add New Address</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Address Name"
                onChange={(e) => {
                  setNewAddress((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Full Address"
                onChange={(e) => {
                  setNewAddress((prevState) => ({
                    ...prevState,
                    full_address: e.target.value,
                  }));
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="state-select-label">State</InputLabel>
                <Select
                  labelId="state-select-label"
                  id="state-select"
                  label="State"
                  // value={selectedState}
                  onChange={handleStatechange}
                >
                  {state.map((state, index) => (
                    <MenuItem key={state} value={state}>
                      {" "}
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="state-select-label">City</InputLabel>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  label="City"
                  // value={selectedCity}
                  onChange={handlecitychange}
                >
                  {cities.map((city) => (
                    <MenuItem key={city} value={city}>
                      {city}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                label="Pincode"
                type="text"
                // onChange={(e) => {
                //   newaddress["pincode"] = e.target.value;
                // }}
                // onChange={(e) => {
                //   setNewAddress((prevState) => ({
                //     ...prevState,
                //     pincode: e.target.value,
                //   }));
                // }}
                onBlur={handleZipCodeBlur}
                onChange={(e) => {
                  const zipvalue = e.target.value;
                  if (/^\d{0,6}$/.test(zipvalue)) {
                    setNewAddress((val) => ({
                      ...val,
                      pincode: zipvalue,
                    }));
                  }
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                required
                fullWidth
                type="text"
                label="Mobile No"
                onChange={(e) => {
                  setNewAddress((prevState) => ({
                    ...prevState,
                    phone: e.target.value,
                  }));
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                inputProps={{ maxLength: 10, pattern: "[0-9]*" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid md={6} xs={12} sx={{ p: 2 }}>
              <TextField
                fullWidth
                required
                label="Country"
                value="India"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={loading}
              onClick={() => {
                Addaddress(
                  newaddress,
                  (newaddress.state = selectedState),
                  (newaddress.city = selectedCity),
                  localStorage.getItem("user_email")
                );
              }}
            >
              Submit
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: red[50],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>

          {/* <Button
                          onClick={() => {
              
                            AddNewAddress(newaddress);
                          }}
                        >
                          Submit
                        </Button> */}
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={messopen}
        autoHideDuration={2000}
        onOpen={messageopen}
        onClose={messageClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert severity="success" sx={{ width: "250px" }}>
          Changes saved successfully
        </Alert>
      </Snackbar>
    </>
  );
}
