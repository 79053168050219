import React, { useEffect, useState } from "react";
import {
  SaveServicenm,
  GetAllService,
  DeleteServicenm,
  EditServicenm,
  CheckServicenm,
  Getserviceshavingzone,
  Getservicepincodeswithoda,
  Updateoda,
} from "../Services/ServiceApi";
import { GetServiceType } from "../Services/VendorApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./Loading";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const CreateService = () => {
  const [servicenm, Setservicenm] = useState("");
  const [errorMessage, SeterroeMessage] = useState("");
  const [editId, setEditid] = useState("");
  const [allservice, SetAllService] = useState([]);
  const [Disavled, setDisavled] = useState(false);
  const [btnText, setbtntext] = useState("Save & Submit");
  const [loading, setLoading] = useState(true);
  const [servicetype, setservicetype] = useState([]);
  const [selectedServiceType, setselectedServiceType] = useState([]);

  useEffect(() => {
    loadservicetype();
  }, []);

  const loadservicetype = () => {
    setservicetype([]);

    GetServiceType(localStorage.getItem("user_email"))
      .then((result) => {
        setservicetype(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const inputEvent = (e) => {
    const value = e.target.value;
    if (value !== "") {
      CheckServicenm(value).then((res) => {
        if (res.status === 200) {
          SeterroeMessage("");
          setDisavled(false);
        } else {
          SeterroeMessage("Service name already exist");
          setDisavled(true);
        }
      });
    }
    Setservicenm(value);
  };
  const head = ["name"];
  let tableData;
  let emptyMess;

  useEffect(() => {
    BindallService();
  }, []);

  const BindallService = () => {
    GetAllService().then((res) => {
      setLoading(false);
      SetAllService(res);
    });
  };

  const saveData = (e) => {
    document.getElementById("EditInput").style.border = "1px solid #ced4da";
    e.preventDefault();

    if (servicenm === "") SeterroeMessage("Please Enter Service Name");
    else {
      setLoading(true);
      if (editId === "") {
        SaveServicenm(servicenm).then((res) => {
          BindallService();
          setLoading(false);

          Setservicenm("");
          toast.success("Record saved successfully");
        });
        SeterroeMessage("");
      } else {
        EditServicenm(editId, servicenm).then((res) => {
          if (res.status === 200) {
            setLoading(false);
            BindallService();
            setEditid("");
            Setservicenm("");
            setbtntext("Save & Submit");
            toast.success("Record updated successfully");
          } else {
            toast.warning("Techinal error");
          }
        });
      }
    }
  };

  const deleteEvent = (id) => {
    if (window.confirm("Do you want to delete?")) {
      DeleteServicenm(id).then((res) => {
        if (res.status === 200) {
          toast.success("Record deleted successfully");
          BindallService();
        } else {
          toast.warn("Service is already mapped with zone you cann't delete");
        }
      });
    }
  };

  const editEvent = (id, name) => {
    Setservicenm(name);
    setEditid(id);
    document.getElementById("EditInput").style.border = "2px solid green";
    setbtntext("Update");
  };

  if (allservice != null && head != null) {
    tableData = allservice.map((row, i) => (
      <tr>
        <td>{i + 1}</td>
        {head.map((head, i) => (
          <td>{row[head]}</td>
        ))}
        <td>
          <IconButton aria-label="delete" className="DeletHover">
            <DeleteIcon
              variant="contained"
              style={{ color: "red", cursor: "pointer" }}
              value={row.id}
              onClick={() => deleteEvent(row.id)}
            />
          </IconButton>
          <span
            className="fas fa-edit"
            style={{ color: "green", cursor: "pointer", margin: "0px 10px" }}
            value={row.id}
            onClick={() => editEvent(row.id, row[head])}
          ></span>
        </td>
      </tr>
    ));

    emptyMess = null;
  } else {
    emptyMess = "No Data Found!!";
  }
  if (loading) return <Loading />;
  else {
    return (
      <>
        <div className="content-wrapper">
          <div className="container px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center  wrap-flex">
              <h3>Service</h3>

              <ReactHTMLTableToExcel
                id="test-table-xls-button-ODA"
                className="download-table-xls-button btn btn-shine btn-success d-none"
                table="table-to-xls-ODA"
                filename="Available Pincodes"
                sheet="RateList"
                buttonText={<i className="fas fa-download"> Sample Format</i>}
              />
            </div>
            {/* INPUT INPUT INPUT */}

            <div className="row  mb-4 mt-3" id="inputDiv">
              <div className="col-md-8 d-flex ">
                <input
                  value={servicenm}
                  onChange={inputEvent}
                  type="text"
                  placeholder="Enter Service Name"
                  className="form-control"
                  id="EditInput"
                />
                <select
                  className="form-control ml-2"
                  value={selectedServiceType}
                  onChange={(event) =>
                    setselectedServiceType(event.target.value)
                  }
                >
                  <option value="">Please select a service type</option>
                  {servicetype.map((service) => (
                    <option
                      key={service.serviceName}
                      value={service.serviceName}
                    >
                      {service.serviceName}
                    </option>
                  ))}
                </select>
                <input
                  type="submit"
                  value={btnText}
                  onClick={saveData}
                  className="btn btn-success ml-2"
                  id="InBtn"
                  style={{}}
                  disabled={Disavled}
                />{" "}
              </div>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </div>
            {/* INPUT INPUT INPUT END */}
            {tableData.length !== 0 && tableData != null ? (
              <div class="table table-responsive">
                <table className="table table-striped" id="mytable">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Service Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{tableData}</tbody>
                </table>
              </div>
            ) : (
              <div className="text-center">
                <h4>No Records Found !!</h4>
              </div>
            )}
          </div>
          <span style={{}}>{emptyMess}</span>
        </div>

        <ToastContainer />
      </>
    );
  }
};
export default CreateService;
