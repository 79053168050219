import * as React from "react";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Menu from "@mui/material/Menu";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { getStateList } from "../Services/ZoneApi";
import { getPincode } from "../Services/VendorApi";
import { useLocation } from "react-router";

function CustomToolbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport
        printOptions={{
          hideToolbar: true,
        }}
      />
      {/* <div>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<FileDownloadOutlinedIcon />}
      >
        Export
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>Download CSV</MenuItem>
        <MenuItem onClick={handleClose}>Download PDF</MenuItem>
      </Menu>
      </div> */}
    </GridToolbarContainer>
  );
}

function NoRowsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fas fa-map-pin"></i>
      <p>No Pincode data available</p>
    </Stack>
  );
}

function NoResultsOverlay() {
  return (
    <Stack
      className="nodata"
      height="100%"
      alignItems="center"
      justifyContent="center"
    >
      <i className="fas fa-map-pin"></i>
      <p>No results in DataGrid</p>
    </Stack>
  );
}

const columns = [
  // { field: "id", headerName: "ID" },
  { field: "statename", headerName: "State Name", width: 200 },
  { field: "cityName", headerName: "City Name", width: 300 },
  { field: "pincode", headerName: "Pincode", width: 200 },
  { field: "created_date", headerName: "Created Date", width: 200 },
  { field: "status", headerName: "Status", width: 200 },
];

export default function PinCode() {
  const [pincodedata, setpincodedata] = useState([]);
  const [search, setSearch] = useState("");
  const [fromDate, setfromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [pinstatus, setpinstatus] = useState("");

  const myParam = useLocation().search;
  const statusdropdown = new URLSearchParams(myParam).get("status");

  useEffect(() => {
    loadpincodes();
  }, []);

  const loadpincodes = () => {
    setpincodedata([]);

    var params = "";
    if (statusdropdown) {
      setpinstatus(statusdropdown);
    } 

    if (pinstatus != null && pinstatus != undefined && pinstatus != "") {
      params += `status=${pinstatus}&`;
    }

    if (statusdropdown != null && statusdropdown != undefined && statusdropdown != "") {
      params += `status=${statusdropdown}&`;
    }


    if (search != null && search != undefined && search != "") {
      params += `search=${search}&`;
    }

    
    if (fromDate != null && fromDate != undefined && fromDate != "") {
      params += `fromDate=${fromDate}&`;
    }
    if (toDate != null && toDate != undefined && toDate != "") {
      params += `todate=${toDate}`;
    }

    getPincode(localStorage.getItem("user_email"), params)
      .then((result) => {
        console.table(result);
        setpincodedata(result);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">PinCode List</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">PinCode List</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <Card sx={{ p: "1rem" }} className="custum-shadow search-box">
            <div className="col">
              <TextField
                required
                fullWidth
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-required"
                label="Search"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="col">
              <FormControl fullWidth>
                <InputLabel
                  id="Status"
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  Status
                </InputLabel>
                <Select
                  labelId="Status"
                  onChange={(e) => setpinstatus(e.target.value)}
                  id="Status"
                  fullWidth
                  label="Status"
                >
                  <MenuItem value="">
                    <em>Status</em>
                  </MenuItem>
                  <MenuItem value={"Serviceable"}>Serviceable </MenuItem>
                  <MenuItem value={"NonServiceable"}>Non Serviceable</MenuItem>
                  <MenuItem value={"ODA 1"}>ODA 1</MenuItem>
                  <MenuItem value={"ODA 2"}>ODA 2</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  value={fromDate}
                  onChange={(e) => {
                    setfromDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div className="col datepicker">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.toString());
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>

            <div className="">
              <IconButton
                onClick={() => {
                  loadpincodes();
                }}
                size="small"
                className="search-icon"
              >
                <ManageSearchIcon />
              </IconButton>
            </div>
          </Card>

          <Card className="custum-shadow mt-3">
            <div style={{ height: "400px" }}>
              <DataGrid
                sx={{ p: "1rem" }}
                className="table-ui"
                disableColumnMenu
                disableDensitySelector
                rows={pincodedata}
                getRowId={(row) => row.id ?? row.pincode}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{
                  Toolbar: CustomToolbar,
                  //  NoRowsOverlay, NoResultsOverlay
                }}
              />
            </div>
          </Card>
        </div>
      </section>
    </div>
  );
}
