import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import { green } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DriveFileRenameOutlineTwoToneIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { grey } from "@mui/material/colors";
import Link from "@mui/material/Link";
import AccountBalanceTwoToneIcon from "@mui/icons-material/AccountBalanceTwoTone";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import {
  GetCompanyCity,
  GetStateList,
  GetBusinessCity,
} from "../Services/UserApi";
import {
  UpdateCustomerDetails,
  GetUserBasicDetails,
  ChangePassword,
  AddBankDetails,
} from "../Services/AdminApi";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { CurrentUrl } from "../Services/UrlApi";
import {
  VendorBankList,
  VendorSelectedBankDetails,
  AddVendorBank,
  DeleteVendorbank,
  Deletepickupslotofvendor,
  Updatepickupslotofvendor,
  GetSelectedPickupslotofVendor,
  GetPickupslotlistofVendor,
  Addpickupslotofvendor,
  DeleteruleofVendor,
  UpdateruleofVendor,
  GetSelectedruleofvendor,
  GetRulelistofVendor,
  Addnewruleofvendor,
  Adddeliveryslotofvendor,
  GetdeliveryslotlistofVendor,
  GetSelecteddeliveryslotofVendor,
  Updatedeliveryslotofvendor,
  Deletedeliveryslotofvendor,
} from "../Services/VendorApi";
import { red } from "@mui/material/colors";

let Url = `${CurrentUrl}Admin/`;
let VUrl = `${CurrentUrl}api/Vendor/`;

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

// const rows = [
//   {
//     id: 1,
//     Message:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nisi odio",
//   },
//   {
//     id: 2,
//     Message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   { id: 3, Message: "Lorem ipsum dolor sit amet", Action: "Jaime" },
//   { id: 4, Message: "Lorem ipsum dolor sit amet consectetur", Action: "Arya" },
//   {
//     id: 5,
//     Message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//   },
//   { id: 6, Message: "Lorem ipsum dolor sit amet", Action: null },
//   { id: 7, Message: "Donec nisi odio", Action: "Ferrara" },
//   { id: 8, Message: "Consectetur adipiscing elit. Donec nisi odio" },
//   { id: 9, Message: "Lorem ipsum dolor sit amet" },
// ];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Setting() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const [Data, setData] = useState({});
  const [profilepic, setProfilepic] = useState(
    localStorage.getItem("profilepic") || ""
  );

  const [state, setState] = useState([]);
  const [selectedcompanycity, setSelectedcompanycity] = useState("");
  const [companycities, setCompanycities] = useState([]);
  const [selectedcompanystate, setSelectedcompanystate] = useState("");
  const [businesscities, setBusinesscities] = useState([]);
  const [selectedbusinessstate, setSelectedbusinessstate] = useState("");
  const [selectedbusinesscity, setSelectedbusinesscity] = useState("");

  const [passmessageOpen, setpassMessageOpen] = useState(false);
  const [incorrectPassword, setIncorrectPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [editbankpopopen, seteditbankpopopen] = useState(false);
  const [vendorbank, setvendorbank] = useState([]);

  const [vendorselectedbankdetails, setvendorselectedbankdetails] = useState(
    {}
  );

  const [editopen, setEditRulespop] = useState(false);
  const [ruleslist, setruleslist] = useState([]);
  const [newrule, setnewrule] = useState("");
  const [currentrule, setcurrentrule] = useState([]);
  const [selectedRuleId, setSelectedRuleId] = useState("");

  const [editpickopen, setEditpickuppop] = useState(false);
  const [pickupslotlist, setpickupslotlist] = useState([]);
  const [pickupslot, setnewpickupslot] = useState("");
  const [currentpickupslot, setcurrentpickupslot] = useState([]);
  const [selectedpickupslot, setselectedpickupslot] = useState("");

  const [editdeliveryslotopen, setEditdeliverypopopen] = useState(false);
  const [deliveryslotlist, setdeliveryslotlist] = useState([]);
  const [deliveryslot, setnewdeliveryslot] = useState("");
  const [currentdeliveryslot, setcurrentdeliveryslot] = useState([]);
  const [selecteddeliveryslot, setselecteddeliveryslot] = useState("");

  // const [deletedefaultbank, setdeletedefaultbank] = useState(false);

  const handleeditbankClose = () => {
    seteditbankpopopen(false);
  };
  const editbankSubmit = () => {
    setOpen(false);
  };

  const editbankmodalClose = () => {
    seteditbankpopopen(false);
  };

  const openeditBankpop = () => {
    seteditbankpopopen(true);
  };
  const closeeditbankpop = () => {
    seteditbankpopopen(false);
  };

  //DELIVERY SLOT SECTION OF VENDOR STARTED

  const deliveryslotcolumn = [
    // { field: "id", headerName: "SR No.", width: 100 },
    { field: "slotvalue", headerName: "Slot Value", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => Opendeliveryslotpopwithdetails(params.row.id)}
            >
              <Avatar sx={{ bgcolor: grey[300] }}>
                <DriveFileRenameOutlineTwoToneIcon
                  fontSize="small"
                  sx={{ color: grey[900] }}
                />
              </Avatar>
            </Button>

            <Button onClick={() => Deleteselecteddelieveryslot(params.row.id)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handldeliverymodalClose = () => {
    setEditdeliverypopopen(false);
  };
  const editdeliverySubmit = () => {
    setOpen(false);
  };

  const editdeliverymodalClose = () => {
    setEditdeliverypopopen(false);
  };

  const Opendeliveryslotpopwithdetails = async (id) => {
    let respone = await GetSelecteddeliveryslotofVendor(id).then((r) => {
      return r;
    });

    if (respone != null) {
      setcurrentdeliveryslot(respone.data[0]);
      setselecteddeliveryslot(id);
      setEditdeliverypopopen(true);
    }
  };

  useEffect(() => {
    GetdeliveryslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setdeliveryslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  let deliveryslotbody = {
    slotvalue: deliveryslot,
    lp_Email: localStorage.getItem("user_email"),
  };

  useEffect(() => {
    GetRulelistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setruleslist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const RefreshDelieveryslotlist = () => {
    GetdeliveryslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setdeliveryslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Addingnewdeliveryslot = async (newpickupslot, emailVal) => {
    let response = await Adddeliveryslotofvendor(deliveryslotbody);

    if ("status" in response) {
      if (response.status == 200) {
        setOpen(false);
        setSuccess(true);
        meassageOpen(true);
        timer.current = window.setTimeout(() => {
          setSuccess(false);
          meassageOpen(false);
          RefreshDelieveryslotlist(emailVal);
        }, 2000);
      }
    }
  };

  const CallEditdeliveryslotOperation = (id) => {
    let editselecteddeliveryslot = {
      id: id,
      slotvalue: currentdeliveryslot.slotvalue,
      email: localStorage.getItem("user_email"),
    };

    Updatedeliveryslotofvendor(
      editselecteddeliveryslot,
      localStorage.getItem("user_email")
    );
  };

  const Refreshafterdeletingdeliveryslot = () => {
    GetdeliveryslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setdeliveryslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Deleteselecteddelieveryslot = async (id) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${VUrl}DeleteDeliveryslot?id=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        Refreshafterdeletingdeliveryslot(id);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const Updatedeliveryslotofvendor = async (
    editselecteddeliveryslot,
    emailVal
  ) => {
    const token = localStorage.getItem("Logkey");
    await fetch(`${VUrl}UpdateDeliveryslot`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(editselecteddeliveryslot),
    })
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            handlvalditmodalClose();
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              setEditdeliverypopopen(false);
              Refreshafterupdatedelieveryslot(emailVal);
            }, 2000);
          }
        }
      })
      .catch((e) => {
        console.log("update deliever slot error", e);
        return e;
      });
  };

  const Refreshafterupdatedelieveryslot = () => {
    GetdeliveryslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setdeliveryslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  //DELIVERY SLOT SECTION ENDS

  //BANK SECTION OF VENDOR STARTS
  useEffect(() => {
    VendorBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setvendorbank(result.data);
        var defaultdata = result.data.filter((a) => a.isDefault == true);
        setvendorselectedbankdetails(defaultdata[0].accountNo);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const DeleteVendorbank = async (accountNo, emailVal) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${VUrl}DeleteBankDetails?AccountNo=${accountNo}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          return res.json().then((data) => {
            if (data.code === 200) {
              setOpen(false);
              setSuccess(true);
              meassageOpen(true);
              timer.current = window.setTimeout(() => {
                setSuccess(false);
                meassageOpen(false);
                Refreshafterdelete(emailVal);
              }, 2000);
            } else if (
              data.code === 400 &&
              data.message === "Can't delete ISDefault bank!!"
            ) {
              toast.warning(
                "Before deleting the default bank, set another bank as default"
              );
              // setdeletedefaultbank(true);
            } else {
              throw new Error("DeleteVendorbank API failed.");
            }
          });
        } else {
          throw new Error("DeleteVendorbank API failed.");
        }
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const Refreshafterdelete = () => {
    VendorBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setvendorbank(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handleSwitchChange = (data) => {
    setvendorselectedbankdetails(data.accountNo);
    axios
      .post(
        `${VUrl}setIsDefault?Email=${localStorage.getItem(
          "user_email"
        )}&Accountnumber=${data.accountNo}`,
        JSON.stringify(data),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              Refreshaftersettingdefault(localStorage.getItem("user_email"));
            }, 2000);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Refreshaftersettingdefault = () => {
    VendorBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setvendorbank(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  let editbank = {
    accountNo: vendorselectedbankdetails.accountNo,
    ifscCode: vendorselectedbankdetails.ifscCode,
    bankName: vendorselectedbankdetails.bankName,
    beneficiaryName: vendorselectedbankdetails.beneficiaryName,
    email: localStorage.getItem("user_email"),
  };

  const Viewvendorbankdetail = async (accountNo) => {
    let result = await VendorSelectedBankDetails(accountNo).then((r) => {
      return r;
    });

    if (result != null) {
      setvendorselectedbankdetails(result.data[0]);
      // seteditbankpopopen(true);
    }
  };

  const UpdateVendorBank = async (editbank, emailVal) => {
    const token = localStorage.getItem("Logkey");
    await fetch(`${VUrl}UpdateBankDetails`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(editbank),
    })
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              seteditbankpopopen(false);
              Refreshafterupdate(emailVal);
            }, 2000);
          }
        }
      })
      .catch((e) => {
        console.log("update bank error", e);
        return e;
      });
  };

  const Refreshafterupdate = () => {
    VendorBankList(localStorage.getItem("user_email"))
      .then((result) => {
        setvendorbank(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  //BANK SECTION OF VENDOR ENDS

  //  Rules section of vendor

  let rulebody = {
    rule: newrule,
    lp_Email: localStorage.getItem("user_email"),
  };

  useEffect(() => {
    GetRulelistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setruleslist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const RefreshRulelist = () => {
    GetRulelistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setruleslist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Addingnewrule = async (newrule, emailVal) => {
    let response = await Addnewruleofvendor(rulebody);

    if ("status" in response) {
      if (response.status == 200) {
        setOpen(false);
        setSuccess(true);
        meassageOpen(true);
        timer.current = window.setTimeout(() => {
          setSuccess(false);
          meassageOpen(false);
          RefreshRulelist(emailVal);
        }, 2000);
      }
    }
  };

  const rulescolumns = [
    // { field: "id", headerName: "SR No.", width: 100 },
    { field: "rule", headerName: "Message", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button onClick={() => Openrulepopwithdetails(params.row.id)}>
              <Avatar sx={{ bgcolor: grey[300] }}>
                <DriveFileRenameOutlineTwoToneIcon
                  fontSize="small"
                  sx={{ color: grey[900] }}
                />
              </Avatar>
            </Button>

            <Button onClick={() => Deleteselectedrule(params.row.id)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handlvalditmodalClose = () => {
    setEditRulespop(false);
  };
  const handleSubmit = () => {
    setOpen(false);
  };

  const edithandleClose = () => {
    setEditRulespop(false);
  };

  const Openrulepopwithdetails = async (id) => {
    let respone = await GetSelectedruleofvendor(id).then((r) => {
      return r;
    });

    if (respone != null) {
      setcurrentrule(respone.data[0]);
      setSelectedRuleId(id);
      setEditRulespop(true);
    }
  };

  const CallEditRuleOperation = (id) => {
    let editselectedrule = {
      id: id,
      rule: currentrule.rule,
      email: localStorage.getItem("user_email"),
    };

    UpdateruleofVendor(editselectedrule, localStorage.getItem("user_email"));
  };

  const Deleteselectedrule = async (id) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${VUrl}DeleteRules?id=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        Refreshafterdeletingrule(id);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const Refreshafterdeletingrule = () => {
    GetRulelistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setruleslist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const UpdateruleofVendor = async (editselectedrule, emailVal) => {
    const token = localStorage.getItem("Logkey");
    await fetch(`${VUrl}UpdateRules`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(editselectedrule),
    })
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            handlvalditmodalClose();
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              setEditRulespop(false);
              Refreshafterupdaterule(emailVal);
            }, 2000);
          }
        }
      })
      .catch((e) => {
        console.log("update bank error", e);
        return e;
      });
  };

  const Refreshafterupdaterule = () => {
    GetRulelistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setruleslist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  //   //RULES SECTION OF VENDOR END
  // ---------------------------------------------------------------------------------------

  //PICKUP SLOT SECTION OF VENDOR STARTED

  const pickupcolumn = [
    // { field: "id", headerName: "SR No.", width: 100 },
    { field: "slotvalue", headerName: "Slot Value", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button onClick={() => Openpickupslotpopwithdetails(params.row.id)}>
              <Avatar sx={{ bgcolor: grey[300] }}>
                <DriveFileRenameOutlineTwoToneIcon
                  fontSize="small"
                  sx={{ color: grey[900] }}
                />
              </Avatar>
            </Button>

            <Button onClick={() => Deleteselectedslot(params.row.id)}>
              <Avatar sx={{ width: 35, height: 35, bgcolor: red[100] }}>
                <DeleteTwoToneIcon fontSize="small" sx={{ color: red[900] }} />
              </Avatar>
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handlpickupmodalClose = () => {
    setEditpickuppop(false);
  };
  const editpickupSubmit = () => {
    setOpen(false);
  };

  const editpickupmodalClose = () => {
    setEditpickuppop(false);
  };

  const Openpickupslotpopwithdetails = async (id) => {
    let respone = await GetSelectedPickupslotofVendor(id).then((r) => {
      return r;
    });

    if (respone != null) {
      setcurrentpickupslot(respone.data[0]);
      setselectedpickupslot(id);
      setEditpickuppop(true);
    }
  };

  useEffect(() => {
    GetPickupslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setpickupslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  let pickupslotbody = {
    slotvalue: pickupslot,
    lp_Email: localStorage.getItem("user_email"),
  };

  useEffect(() => {
    GetRulelistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setruleslist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  }, []);

  const RefreshPickupListlist = () => {
    GetPickupslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setpickupslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Addingnewpickupslot = async (newpickupslot, emailVal) => {
    let response = await Addpickupslotofvendor(pickupslotbody);

    if ("status" in response) {
      if (response.status == 200) {
        setOpen(false);
        setSuccess(true);
        meassageOpen(true);
        timer.current = window.setTimeout(() => {
          setSuccess(false);
          meassageOpen(false);
          RefreshPickupListlist(emailVal);
        }, 2000);
      }
    }
  };

  const CallEditPickupslotOperation = (id) => {
    let editselectedpickupslot = {
      id: id,
      slotvalue: currentpickupslot.slotvalue,
      email: localStorage.getItem("user_email"),
    };

    Updatepickupslotofvendor(
      editselectedpickupslot,
      localStorage.getItem("user_email")
    );
  };

  const Refreshafterdeletingpickupslot = () => {
    GetPickupslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setpickupslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const Deleteselectedslot = async (id) => {
    const token = localStorage.getItem("Logkey");
    return await fetch(`${VUrl}DeletePickupslot?id=${id}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "text/plain; charset=utf-8",
        "Access-Control-Allow-Origin": `*`,
      },
    })
      .then((res) => {
        Refreshafterdeletingpickupslot(id);
        return res;
      })
      .catch((e) => {
        console.log(e);
        return e;
      });
  };

  const Updatepickupslotofvendor = async (editselectedpickupslot, emailVal) => {
    const token = localStorage.getItem("Logkey");
    await fetch(`${VUrl}UpdatePickupslot`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": `*`,
      },
      body: JSON.stringify(editselectedpickupslot),
    })
      .then((res) => {
        if ("status" in res) {
          if (res.status == 200) {
            handlvalditmodalClose();
            setOpen(false);
            setSuccess(true);
            meassageOpen(true);
            timer.current = window.setTimeout(() => {
              setSuccess(false);
              meassageOpen(false);
              setEditpickuppop(false);
              Refreshafterupdatepickupslot(emailVal);
            }, 2000);
          }
        }
      })
      .catch((e) => {
        console.log("update bank error", e);
        return e;
      });
  };

  const Refreshafterupdatepickupslot = () => {
    GetPickupslotlistofVendor(localStorage.getItem("user_email"))
      .then((result) => {
        setpickupslotlist(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  var passData = {
    email: localStorage.getItem("user_email"),
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  var banking = {
    customerid: "",
    accountNo: "",
    ifscCode: "",
    bankName: "",
    beneficiaryName: "",
    email: localStorage.getItem("user_email"),
  };

  // const myParam = useLocation().search;
  // const email = new URLSearchParams(myParam).get("email");

  const ProfileImage = async (e) => {
    const formData = new FormData();
    formData.append("imgfile", e.target.files[0]);

    return await axios
      .post(`${Url}UploadImagebyAdmin`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        setProfilepic(response.data.data);
        localStorage.setItem("profilepic", response.data.data);
        return response.data.data;
      })

      .catch((error) => {
        console.log(error.response);
      });
  };

  useEffect(() => {}, [profilepic]);

  const updateCustomerDetails = async (detail) => {
    var validate = true;
    if (
      (detail.personNames == null ||
        detail.personNames == "" ||
        detail.cAddressLine1 == null ||
        detail.cAddressLine1 == "",
      detail.cZipCode == null || detail.cZipCode == "",
      detail.cCompanyDescriptions == null || detail.cCompanyDescriptions == "",
      detail.bZipCode == null || detail.bZipCode == "")
    ) {
      toast.warn("Please validate all  fields");
      validate = false;
    }
    if (validate) {
      await UpdateCustomerDetails(detail);
      meassageOpen(true);
    }
  };

  useEffect(() => {
    GetUserBasicDetails(localStorage.getItem("user_email"));
  }, []);

  const GetUserBasicDetails = async (email) => {
    const token = localStorage.getItem("Logkey");
    return await axios
      .get(`${Url}GetUserBasicDetailsByEmail?Email=${email}`, {
        headers: {
          "access-control-allow-credentials": "true",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((r) => {
        var formData = {
          id: "",
          email: "",
          lpName: "",
          personNames: "",
          mobileNumber: "",
          gst: "",
          pan: "",
          monthlyShipping: "",
          dealItem: "",
          averageShipmentWeight: "",
          cAddressLine1: "",
          cAddressLine2: "",
          cCity: "",
          cState: "",
          cZipCode: "",
          cCountry: "",
          cCompanyDescriptions: "",
          cRemarks: "",
          bAddressLine1: "",
          bAddressLine2: "",
          bCity: "",
          bState: "",
          bZipCode: "",
          bCountry: "",
          bRemarks: "",
          image: "",
          companydesc: "",
          imagePath: profilepic,
        };
        Object.keys(r.data).forEach((e) => {
          if (r.data[e] != null && formData[e] == "") {
            formData[e] = r.data[e];
          }
          if (e == "cState") {
            setSelectedcompanystate(r.data[e]);
          }
          if (e == "cCity") {
            setSelectedcompanycity(r.data[e]);
          }
          if (e == "bState") {
            setSelectedbusinessstate(r.data[e]);
          }
          if (e == "bCity") {
            setSelectedbusinesscity(r.data[e]);
          }
        });
        setData(formData);

        setTimeout(() => {
          setData(formData);
        }, 100);
      })
      .catch((error) => {
        return error.response;
      });
  };

  const [messopen, meassageOpen] = useState(false);
  const messageopen = () => {
    meassageOpen(true);
  };
  const messageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    GetUserBasicDetails(localStorage.getItem("user_email"));
    meassageOpen(false);
  };

  const updateBankClickOpen = () => {
    setOpen(true);
  };
  const updateBankClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadStateList();
  }, []);

  const loadStateList = () => {
    setState([]);

    GetStateList()
      .then((result) => {
        setState(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const handlecompanyStatechange = (e) => {
    setSelectedcompanystate(e.target.value);
  };
  const handlecompanycitychange = (e) => {
    setSelectedcompanycity(e.target.value);
  };
  const handlebusinessStatechange = (e) => {
    setSelectedbusinessstate(e.target.value);
  };
  const handlebusinesscitychange = (e) => {
    setSelectedbusinesscity(e.target.value);
  };

  useEffect(() => {
    if (selectedcompanystate) {
      GetCompanyCity(selectedcompanystate).then((result) => {
        setCompanycities(result);
      });
    } else {
      setCompanycities([]);
    }
  }, [selectedcompanystate]);

  useEffect(() => {
    if (selectedbusinessstate) {
      GetBusinessCity(selectedbusinessstate).then((result) => {
        setBusinesscities(result);
      });
    } else {
      setBusinesscities([]);
    }
  }, [selectedbusinessstate]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const BankClickOpen = () => {
    setOpen(true);
  };
  const BankClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [addbankopen, setOpenBank] = React.useState(false);
  const AddbankClose = () => {
    setOpenBank(false);
  };

  const AddbankOpen = () => {
    setOpenBank(true);
  };

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const Vendorbankadd = async (bank, emailVal) => {
    setLoading(true);
    let respone = await AddVendorBank(bank);
    setLoading(false);
    if ("status" in respone) {
      if (respone.status == 200) {
        setOpenBank(false);
        setSuccess(true);
        meassageOpen(true);
        timer.current = window.setTimeout(() => {
          setSuccess(false);
          meassageOpen(false);
          RefreshBankData(emailVal);
        }, 2000);
      }
    }
  };

  const RefreshBankData = (emailVal) => {
    VendorBankList(emailVal)
      .then((result) => {
        setvendorbank(result.data);
      })
      .catch((r) => {
        console.log(r);
      });
  };

  const role = localStorage.getItem("user_role").split(",");
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="main-title">Vendor details</h1>
            </div>
            {/* /.col */}
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <span>Home</span>
                </li>
                <li className="breadcrumb-item active">Settings</li>
              </ol>
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      <section className="content">
        <ToastContainer />
        <div className="container-fluid">
          <Card className="custum-shadow">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  TabIndicatorProps={{ style: { background: "#7b3131" } }}
                  textColor="Mahogany"
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="Profile" {...a11yProps(0)} />
                  <Tab label="Change Password" {...a11yProps(1)} />
                  <Tab label="Rules" {...a11yProps(2)} />
                  <Tab label="Pickup Slot" {...a11yProps(3)} />
                  <Tab label="Delievery Slot" {...a11yProps(4)} />
                  <Tab label="Bank Details" {...a11yProps(5)} />
                </Tabs>
              </Box>
              {/* //CHANGE BASIC DETAILS PAGE */}
              <TabPanel value={value} index={0} className="tabPanel-ui">
                <h4 className="mb-4">Basic Details</h4>
                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        disabled
                        fullWidth
                        required
                        label="Email"
                        onChange={(e) => {
                          setData((val) => ({ ...val, email: e.target.value }));
                        }}
                        value={Data["email"]}
                        defaultValue="test@gmial.com"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Person Name/Trade Name"
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            personNames: e.target.value,
                          }));
                        }}
                        value={Data["personNames"]}
                        // defaultValue="Mudita Express Cargo Pvt. Ltd."
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    {/* <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Person Name"
                        // label="LP Name"
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            personNames: e.target.value,
                          }));
                        }}
                        value={Data["personNames"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="GST"
                        onChange={(e) => {
                          setData((val) => ({ ...val, gst: e.target.value }));
                        }}
                        value={Data["gst"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Mobile No."
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            mobileNumber: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 10, pattern: "[0-9]" }}
                        value={Data["mobileNumber"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="PAN"
                        onChange={(e) => {
                          setData((val) => ({ ...val, pan: e.target.value }));
                        }}
                        value={Data["pan"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        // required
                        type={"file"}
                        value={profilepic.data}
                        onChange={ProfileImage}
                        label="Image"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    {/* <div className="col-lg-4 col-sm-12 mb-5">
                      <TextField
                        fullWidth
                        label="Markup"
                        // value={markup}
                        // onChange={(event) => setmarkup(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}
                  </div>

                  <div className="row">
                    <Typography
                      variant="overline"
                      display="block"
                      className="mb-3"
                    >
                      Company Address
                    </Typography>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Address Line 1"
                        value={Data["cAddressLine1"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cAddressLine1: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="Address Line 2"
                        value={Data["cAddressLine2"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cAddressLine2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Zip Code"
                        value={Data["cZipCode"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cZipCode: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel required id="country-select-label">
                          State
                        </InputLabel>
                        <Select
                          labelId="state-select-label"
                          id="state-select"
                          label="State"
                          value={selectedcompanystate}
                          onChange={handlecompanyStatechange}
                        >
                          {state.map((state, index) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel required id="state-select-label">
                          City
                        </InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city-select"
                          label="City"
                          value={selectedcompanycity}
                          onChange={handlecompanycitychange}
                        >
                          {companycities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="City"
                        value={Data["cCity"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            city: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="State"
                        value={Data["cState"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            state: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}

                    {/* <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">
                          Country
                        </InputLabel>
                        <Select
                          labelId="country-select-label"
                          id="country-select"
                          label="Country"
                          value={Data["cCountry"]}
                          onChange={(e) => {
                            setData((val) => ({
                              ...val,
                              country: e.target.value,
                            }));
                          }}
                          Selectla
                        >
                          <MenuItem value="AF">Afghanistan</MenuItem>
                          <MenuItem value="AB">Albania</MenuItem>
                          <MenuItem value="AG">Algeria</MenuItem>
                        </Select>
                      </FormControl>
                    </div> */}

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Country"
                        value="India"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        label="Company Description"
                        value={Data["cCompanyDescriptions"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            cCompanyDescriptions: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <Typography
                      variant="overline"
                      display="block"
                      className="mb-3"
                    >
                      Bussiness Address
                    </Typography>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="Address Line 1"
                        value={Data["bAddressLine1"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bAddressLine1: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="Address Line 2"
                        value={Data["bAddressLine2"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bAddressLine2: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    {/* <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="City"
                        value={Data["bCity"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bCity: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="State"
                        value={Data["bState"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bState: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div> */}

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="Zip Code"
                        value={Data["bZipCode"]}
                        onChange={(e) => {
                          setData((val) => ({
                            ...val,
                            bZipCode: e.target.value,
                          }));
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ maxLength: 6, pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="country-select-label">State</InputLabel>
                        <Select
                          labelId="state-select-label"
                          id="state-select"
                          label="State"
                          value={selectedbusinessstate}
                          onChange={handlebusinessStatechange}
                        >
                          {state.map((state, index) => (
                            <MenuItem key={state} value={state}>
                              {" "}
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-sm-4 mb-4">
                      <FormControl fullWidth>
                        <InputLabel id="state-select-label">City</InputLabel>
                        <Select
                          labelId="city-select-label"
                          id="city-select"
                          label="City"
                          value={selectedbusinesscity}
                          onChange={handlebusinesscitychange}
                        >
                          {businesscities.map((city) => (
                            <MenuItem key={city} value={city}>
                              {city}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-sm-4 mb-4">
                      <TextField
                        fullWidth
                        label="Country"
                        value="India"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      updateCustomerDetails(
                        Data,
                        (Data.imagePath = profilepic),
                        (Data.cState = selectedcompanystate),
                        (Data.cCity = selectedcompanycity),
                        (Data.bState = selectedbusinessstate),
                        (Data.bCity = selectedbusinesscity)
                      );
                    }}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Save Changes
                  </Button>
                </Box>
              </TabPanel>
              {/* //CHANGE PASSWORD PAGE */}
              <TabPanel value={value} index={1}>
                <ToastContainer />
                <h4 className="mb-2">Change Password</h4>
                <Typography
                  variant="subtitle1"
                  className="mb-2"
                  style={{ opacity: "0.7" }}
                >
                  Please use letters, numbers and accepted symbols only, with no
                  spaces, minimum 6, maximum 32 characters.
                </Typography>
                <Typography
                  variant="subtitle1"
                  className="mb-5"
                  style={{ opacity: "0.7" }}
                >
                  Your password should not contain your username, name, email
                  address or year of birth. You can increase the strength of
                  your password by using a mixture of letters, numbers and
                  symbols. Please remember that passwords are case sensitive.
                </Typography>

                <Box component="form" autoComplete="off" noValidate>
                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Current Password"
                        onChange={(e) => {
                          passData["currentPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="New Password"
                        onChange={(e) => {
                          passData["newPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-5 col-sm-12 mb-4">
                      <TextField
                        fullWidth
                        required
                        label="Confirm New Password"
                        onChange={(e) => {
                          passData["confirmNewPassword"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      if (
                        !passData.currentPassword ||
                        !passData.newPassword ||
                        !passData.confirmNewPassword
                      ) {
                        toast.warn("Please fill out all fields");
                      } else {
                        ChangePassword(passData)
                          .then((r) => {
                            if (r.code === 200) {
                              setSuccessMessage("Changes saved successfully");
                              setpassMessageOpen(true);
                            } else if (r.code === 400) {
                              setIncorrectPassword(true);
                            }
                          })
                          .catch((e) => {
                            console.log("error", e);
                          });
                      }
                    }}
                    variant="contained"
                    size="large"
                    className="custum-btn"
                  >
                    Update
                  </Button>
                  {incorrectPassword && (
                    <Snackbar
                      open={incorrectPassword}
                      onClose={() => setIncorrectPassword(false)}
                      message="Incorrect current password"
                    />
                  )}

                  <Snackbar
                    open={passmessageOpen}
                    autoHideDuration={2000}
                    onClose={() => setpassMessageOpen(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Alert severity="success" sx={{ width: "250px" }}>
                      {successMessage}
                    </Alert>
                  </Snackbar>
                </Box>
              </TabPanel>
              {/* RULES PAGE  */}
              <TabPanel value={value} index={2}>
                <div className="d-flex align-items-center mb-4">
                  <h4>Rules</h4>
                  <Button
                    variant="contained"
                    className="ml-auto custum-btn"
                    onClick={handleClickOpen}
                  >
                    Add New Rule
                  </Button>
                </div>
                <Box>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={ruleslist}
                      columns={rulescolumns}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableColumnMenu
                    />
                  </div>
                </Box>
                {/* ADD NEW RULE DIALOG BOX */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title">{"Rules"}</DialogTitle>
                  <DialogContent sx={{ px: 2 }}>
                    <DialogContentText sx={{ mt: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        multiline
                        fullWidth
                        onChange={(e) => setnewrule(e.target.value)}
                        newrule
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: 2, pb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        Addingnewrule(
                          newrule,
                          localStorage.getItem("user_email")
                        );
                      }}
                      color="success"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* EDIT RULE DIALOG BOX */}
                <Dialog
                  open={editopen}
                  onSubmit={handleSubmit}
                  onClose={handlvalditmodalClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Edit Rules"}
                  </DialogTitle>
                  <DialogContent sx={{ px: 2 }}>
                    <DialogContentText sx={{ mt: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        multiline
                        value={currentrule["rule"]}
                        onChange={(e) => {
                          setcurrentrule((val) => ({
                            ...val,
                            rule: e.target.value,
                          }));
                        }}
                        fullWidth
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: 2, pb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        CallEditRuleOperation(selectedRuleId);
                      }}
                      color="success"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handlvalditmodalClose}
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </TabPanel>
              {/* PICKUP SLOT PAGE  */}
              <TabPanel value={value} index={3}>
                <div className="d-flex align-items-center mb-4">
                  <h4>Pickup Slot</h4>
                  <Button
                    variant="contained"
                    className="ml-auto custum-btn"
                    onClick={handleClickOpen}
                  >
                    Add Pickup Slot
                  </Button>
                </div>
                <Box>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={pickupslotlist}
                      columns={pickupcolumn}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableColumnMenu
                    />
                  </div>
                </Box>
                {/* ADD NEW PICKUP SLOT PAGE */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Pickup Slot"}
                  </DialogTitle>
                  <DialogContent sx={{ px: 2 }}>
                    <DialogContentText sx={{ mt: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        onChange={(e) => setnewpickupslot(e.target.value)}
                        multiline
                        fullWidth
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: 2, pb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        Addingnewpickupslot(
                          pickupslot,
                          localStorage.getItem("user_email")
                        );
                      }}
                      color="success"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* EDIT PICKUP SLOT PAGE */}
                <Dialog
                  open={editpickopen}
                  onSubmit={editpickupSubmit}
                  onClose={handlpickupmodalClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Edit Pickup Slot"}
                  </DialogTitle>
                  <DialogContent sx={{ px: 2 }}>
                    <DialogContentText sx={{ mt: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        value={currentpickupslot["slotvalue"]}
                        onChange={(e) => {
                          setcurrentpickupslot((val) => ({
                            ...val,
                            slotvalue: e.target.value,
                          }));
                        }}
                        multiline
                        fullWidth
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: 2, pb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        CallEditPickupslotOperation(selectedpickupslot);
                      }}
                      color="success"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={editpickupmodalClose}
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </TabPanel>
              {/* DELIEVERY SLOT PAGE  */}
              <TabPanel value={value} index={4}>
                <div className="d-flex align-items-center mb-4">
                  <h4>Delievery Slot</h4>
                  <Button
                    variant="contained"
                    className="ml-auto custum-btn"
                    onClick={handleClickOpen}
                  >
                    Add Delievery Slot
                  </Button>
                </div>
                <Box>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={deliveryslotlist}
                      columns={deliveryslotcolumn}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      disableColumnMenu
                    />
                  </div>
                </Box>
                {/* ADD NEW DELIEVERY SLOT PAGE */}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Delievery Slot"}
                  </DialogTitle>
                  <DialogContent sx={{ px: 2 }}>
                    <DialogContentText sx={{ mt: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        onChange={(e) => setnewdeliveryslot(e.target.value)}
                        multiline
                        fullWidth
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: 2, pb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        Addingnewdeliveryslot(
                          deliveryslot,
                          localStorage.getItem("user_email")
                        );
                      }}
                      color="success"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                {/* EDIT DELIEVERY SLOT PAGE */}
                <Dialog
                  open={editdeliveryslotopen}
                  onSubmit={editdeliverySubmit}
                  onClose={handldeliverymodalClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth="sm"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Edit Delievery Slot"}
                  </DialogTitle>
                  <DialogContent sx={{ px: 2 }}>
                    <DialogContentText sx={{ mt: 1 }}>
                      <TextField
                        id="outlined-multiline-flexible"
                        label="Message"
                        value={currentdeliveryslot["slotvalue"]}
                        onChange={(e) => {
                          setcurrentdeliveryslot((val) => ({
                            ...val,
                            slotvalue: e.target.value,
                          }));
                        }}
                        multiline
                        fullWidth
                        rows={4}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions sx={{ px: 2, pb: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        CallEditdeliveryslotOperation(selecteddeliveryslot);
                      }}
                      color="success"
                    >
                      Submit
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={editdeliverymodalClose}
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </TabPanel>
              {/* BANK DETAILS PAGE */}
              <TabPanel value={value} index={5}>
                <div className="d-flex align-items-center mb-4">
                  <h4 className="mb-0">Bank Details</h4>
                  <Button
                    variant="contained"
                    size="large"
                    className="ml-auto custum-btn"
                    onClick={AddbankOpen}
                  >
                    Add Bank
                  </Button>
                </div>
                {/* //BANK CARD */}
                <Box>
                  <Grid container gap={2}>
                    {vendorbank.map((item, i) => {
                      return (
                        <Grid md={4} xs={12}>
                          <Card className="bank-card-ui">
                            <CardContent>
                              <Typography
                                sx={{ fontSize: 13 }}
                                color="text.secondary"
                              >
                                Bank Name
                              </Typography>
                              <Typography sx={{ fontSize: 15 }}>
                                {" "}
                                {item.bankName}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 13 }}
                                color="text.secondary"
                              >
                                IFSC
                              </Typography>
                              <Typography sx={{ fontSize: 15 }}>
                                {item.ifscCode}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 13 }}
                                color="text.secondary"
                              >
                                Beneficiary name
                              </Typography>
                              <Typography sx={{ fontSize: 15 }}>
                                {item.beneficiaryName}
                              </Typography>
                              <Typography
                                sx={{ fontSize: 13 }}
                                color="text.secondary"
                              >
                                Account No.
                              </Typography>
                              <Typography sx={{ fontSize: 15 }}>
                                {item.accountNo}
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <FormControlLabel
                                control={
                                  // DEFAULT BANK SWITCH
                                  <Switch
                                    checked={item.isDefault === true}
                                    size="small"
                                    onChange={() => handleSwitchChange(item)}
                                  />
                                }
                                label="Default"
                              />
                              <ButtonGroup>
                                <IconButton aria-label="delete">
                                  <DriveFileRenameOutlineTwoToneIcon
                                    fontSize="small"
                                    onClick={() => {
                                      openeditBankpop();
                                      Viewvendorbankdetail(item.accountNo);
                                    }}
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    DeleteVendorbank(item.accountNo);
                                  }}
                                  aria-label="delete"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </ButtonGroup>
                            </CardActions>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>

                {/* <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  textAlign={"center"}
                  padding={5}
                >
                  <AccountBalanceTwoToneIcon sx={{ fontSize: 60 }} />
                  <Typography variant="h6" gutterBottom>
                    You don't have link any bank account yet.
                  </Typography>
                  <Typography variant="body1" gutterBottom mb={3}>
                    Please add at least one bank account.
                  </Typography>
                  <Button
                    variant="contained"
                    size="large"
                    className="custum-btn"
                    onClick={BankClickOpen}
                  >
                    Add Bank
                  </Button>
                </Box> */}

                {/* ADD VENDOR BANK DIALOG BOX */}
                <Dialog
                  open={addbankopen}
                  onClose={AddbankClose}
                  fullWidth
                  maxWidth="xs"
                >
                  <DialogTitle>Add Bank</DialogTitle>
                  <DialogContent>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="Account No."
                        onChange={(e) => {
                          banking["accountNo"] = e.target.value;
                        }}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        inputProps={{ pattern: "[0-9]" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="IFSC Code"
                        onChange={(e) => {
                          banking["ifscCode"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="Bank Name"
                        onChange={(e) => {
                          banking["bankName"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="Beneficiary Name"
                        onChange={(e) => {
                          banking["beneficiaryName"] = e.target.value;
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "space-between" }}>
                    <Button
                      onClick={() => {
                        if (
                          !banking.accountNo ||
                          !banking.ifscCode ||
                          !banking.bankName ||
                          !banking.beneficiaryName
                        ) {
                          toast.warn("Please fill out all fields");
                        } else {
                          Vendorbankadd(
                            banking,
                            localStorage.getItem("user_email")
                          );
                        }
                      }}
                    >
                      Submit
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: red[50],
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    <Button onClick={AddbankClose}>Cancel</Button>
                  </DialogActions>
                </Dialog>
                {/* EDIT BANK DIALOG BOX */}
                <Dialog
                  open={editbankpopopen}
                  onSubmit={editbankSubmit}
                  onClose={handleeditbankClose}
                  fullWidth
                  maxWidth="xs"
                >
                  <DialogTitle>Update Bank</DialogTitle>
                  <DialogContent>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="Account No."
                        value={vendorselectedbankdetails["accountNo"]}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="IFSC Code"
                        value={vendorselectedbankdetails.ifscCode}
                        onChange={(e) => {
                          setvendorselectedbankdetails((val) => ({
                            ...val,
                            ifscCode: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="Bank Name"
                        value={vendorselectedbankdetails.bankName}
                        onChange={(e) => {
                          setvendorselectedbankdetails((val) => ({
                            ...val,
                            bankName: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    <Box mt={3}>
                      <TextField
                        fullWidth
                        label="Beneficiary Name"
                        value={vendorselectedbankdetails.beneficiaryName}
                        onChange={(e) => {
                          setvendorselectedbankdetails((val) => ({
                            ...val,
                            beneficiaryName: e.target.value,
                          }));
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "space-between" }}>
                    <Box sx={{ m: 1, position: "relative" }}>
                      <Button
                        onClick={() => {
                          UpdateVendorBank(editbank);
                        }}
                        variant="contained"
                        sx={buttonSx}
                        disabled={loading}
                      >
                        Submit
                      </Button>
                      {loading && (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: red[50],
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Box>
                    <Button onClick={editbankmodalClose}>Cancel</Button>
                  </DialogActions>
                </Dialog>
              </TabPanel>
            </Box>
          </Card>

          <Snackbar
            open={messopen}
            autoHideDuration={2000}
            onOpen={messageopen}
            onClose={messageClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert severity="success" sx={{ width: "250px" }}>
              Changes saved successfully
            </Alert>
          </Snackbar>
        </div>
      </section>
    </div>
  );
}
