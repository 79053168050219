import { list } from "fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Getratebyservice } from "../Services/RateApi";
import { Getserviceshavingrate } from "../Services/ServiceApi";
import Loading from "./Loading";
import DynamicTable from "./DynamicTable";

const ViewUploadedRate = () => {
  const [servicenm, setServicenm] = useState("");
  const [inputdata, setInputdata] = useState("");
  const [data, setData] = useState("");
  const [rates, setRates] = useState("");
  const [loading, setLoading] = useState(true);
  const [columns, setColumns] = useState("");
  const [tabldata, setTabledata] = useState("");
  let handlingchargesHead = ["from", "to", "unit", "rate"];
  const [handlingchg, setHandlingchg] = useState([]);
  useEffect(() => {
    Getserviceshavingrate().then((res) => {
      setServicenm(res);
      setLoading(false);
    });
  }, []);
  const [additionalchg, setAdditionalchg] = useState("");
  const inputEvent = (e) => {
    setLoading(true);
    setInputdata(e.target.value);
    Getratebyservice(e.target.value).then((res) => {
      setData(res.data);
      setRates(res.data.rates);
      let list = [];
      let listlen = 1;

      for (var i in res.data.rates) {
        listlen++;
        list.push(res.data.rates[i].zoneidto);
        if (listlen > Math.sqrt(res.data.rates.length)) break;
      }
      setHandlingchg(res.data.handlingcharges);
      setAdditionalchg(
        <>
          <div className="col-md-6 shadow-lg spanStyle">
            <h5
              className="py-2 mb-0 px-2 text-white mt-4"
              style={{ backgroundColor: "#3f474e" }}
            >
              Additional Charges
            </h5>
            <div className="row p-3">
              <div className="col-6 border-end-style">
                <span className="">Min Chargable Wt. : </span>
                <span className=""></span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_Weight}
                </span>
              </div>{" "}
              <div className="col-6 border-end-style text-end">
                <span className="">Max Allowed Wt. : </span>
                <span className=""></span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.max_Weight}
                </span>
              </div>
              <div className="col-6  ">
                <span className="">Min ODA1 : </span>
                <span className=""></span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_ODA}
                </span>
              </div>
              <div className="col-6  text-end">
                <span className="">Min ODA2: </span>
                <span className=""></span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_ODA2}
                </span>
              </div>
              <div className="col-6 ">
                <span>ODA1 Per/Kg : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.actual_ODA}
                </span>{" "}
              </div>
              <div className="col-6 text-end">
                <span>ODA2 Per/Kg : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.actual_ODA2}
                </span>{" "}
              </div>
              <div className="col-6 border-end-style">
                <span className="">Min Freight : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.min_Freight}
                </span>
              </div>
              <div className="col-6 text-end ">
                <span>COD : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.cod}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span className="">Min FOV : </span>
                <span className="">
                  &#8377;{res.data.additionalcharges.actual_FOV}
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="">Docket Charge : </span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.docket_Charge}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span>FOV Percentage : </span>
                <span className="">
                  {res.data.additionalcharges.actual_FOV}%
                </span>
              </div>
              <div className="col-6 text-end">
                <span className="">Min Green Tax : </span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.minGreenTax}
                </span>
              </div>
              <div className="col-6 border-end-style">
                {" "}
                <span className="">FSC Percentage : </span>
                <span className="">{res.data.additionalcharges.fsc}%</span>
              </div>
              <div className="col-6 text-end">
                {" "}
                <span className="">Green Tax Per/Kg : </span>
                <span className="">
                  &#8377;
                  {res.data.additionalcharges.greenTaxperkg}
                </span>
              </div>
              <div className="col-6 border-end-style">
                <span>GST : </span>
                <span className="">{res.data.additionalcharges.gst}%</span>
              </div>
            </div>
          </div>
        </>
      );

      Bindtablecolumn(list);
      Bindtabledata(list, res.data.rates);
      setLoading(false);
    });
  };
  const Bindtablecolumn = (list) => {
    setColumns(
      list.map((head, i) => {
        return <th key={i}>{head}</th>;
      })
    );
  };
  const Bindtabledata = (list, ratesdata) => {
    setTabledata(
      list.map((head) => {
        return (
          <tr>
            <td>{head}</td>
            {list.map((val) => {
              return (
                <>
                  {ratesdata.map((item, i) => {
                    if (item.zoneidto === val && item.zoneidfrom === head) {
                      return <td key={i}>{item.lP_Rates}</td>;
                    }
                  })}
                </>
              );
            })}
          </tr>
        );
      })
    );
  };
  if (loading) return <Loading />;
  else {
    return (
      <div>
        <div className="content-wrapper">
          <div className="container  px-2 px-lg-5">
            <div className="d-flex justify-content-between align-items-center flex-wrap mb-3 mt-3">
              <h3>View Uploaded Rate</h3>
              <div>
                <Link to="/uploadrate" className="text-white btn btn-info">
                  Upload Rate
                </Link>
              </div>
            </div>
            <div
              className="row"
              // style={{
              //   borderBottom: "2px solid #dee2e6",
              //   paddingBottom: "7px",
              // }}
            >
              <div className="col-md-6 mb-4">
                <span>
                  Select Service Name<span style={{ color: "red" }}>*</span>
                </span>
                <select
                  className="form-control"
                  onChange={inputEvent}
                  name="inputdata"
                  value={inputdata}
                >
                  <option selected hidden>
                    Select
                  </option>
                  {!(servicenm.length > 0)
                    ? ""
                    : servicenm.map((val, i) => {
                        return (
                          <option key={i} value={val.id}>
                            {val.name}
                          </option>
                        );
                      })}
                </select>
              </div>
            </div>
            {columns === "" ? (
              ""
            ) : (
              <>
                <h5
                  className="py-2 mb-0 px-2  text-white"
                  style={{ backgroundColor: "#3f474e" }}
                >
                  Uploaded Rates
                </h5>
                <div className="table-responsive">
                  <table className="table  text-nowrap">
                    <thead>
                      <tr>
                        <th>FROM / TO</th>
                        {columns}
                      </tr>
                    </thead>
                    <tbody>{tabldata}</tbody>
                  </table>
                </div>
              </>
            )}
            <div
              className="row justify-content-between"
              style={{
                paddingBottom: "7px",
              }}
            >
              {additionalchg}
              {handlingchg.length === 0 ? (
                ""
              ) : (
                <div className="col-md-6 shadow-lg">
                  <h5
                    className="py-2 mb-0 px-2 text-white mt-4"
                    style={{ backgroundColor: "#3f474e" }}
                  >
                    Handling Charges
                  </h5>
                  <DynamicTable
                    headers={handlingchargesHead}
                    data={handlingchg}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ViewUploadedRate;
