import axios from "axios";
import { CurrentUrl } from "./UrlApi";

let Url = `${CurrentUrl}Rate/`;

export const UploadRate = async (Data, ratesheetid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(`${Url}uploadrate/${ratesheetid}`, JSON.stringify(Data), {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getratebyservice = async (serviceid) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getratebyservice/${serviceid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const Getlpdatabylpid = async (id) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .get(`${Url}Getlpdetails/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const GetRates = async (
  ratedata,
  grosswt,
  totalwt,
  units,
  cust_Email
) => {
  return await axios
    .post(
      `${Url}Getrates`,
      JSON.stringify({
        frompin: ratedata.frompin,
        topin: ratedata.topin,
        // numberofpiece: ratedata.pieces,
        chargableweight: ratedata.shipmentweight,
        shipvalue: ratedata.shipmentvalue,
        cod: ratedata.cod,
        grosswt: grosswt,
        dtotalwt: totalwt,
        units: units,
        cust_Email: localStorage.getItem("user_email"),
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
export const getratepincodewise = async (Data) => {
  const token = localStorage.getItem("Logkey");
  return await axios
    .post(
      `${Url}getratepincodewise`,
      JSON.stringify({
        fromPinCode: parseInt(Data.fromPinCode),
        toPincode: Data.toPincode,
        shipmentValue: parseFloat(Data.shipmentValue),
        shipmentWt: parseFloat(Data.shipmentWt),
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
// `${Url}getratepincodewise?FromPinCode=${FromPinCode}&ToPincode=${ToPincode}&ShipmentWt=${ShipmentWt}&ShipmentValue=${ShipmentValue}`,
